export const register = [
  {
    value: 0,
    label: '대표공인중개사',
  },
  {
    value: 1,
    label: '소속공인중개사',
  },
  // {
  //   value: 2,
  //   label: '광고관리자',
  // },
  // {
  //   value: 3,
  //   label: '중개인',
  // },
];

export const all = [
  {
    value: 0,
    label: '대표공인중개사',
  },
  {
    value: 1,
    label: '소속공인중개사',
  },
  {
    value: 2,
    label: '중개보조원',
  },
];

export const master = [
  {
    value: 0,
    label: '대표공인중개사',
  },
  {
    value: 2,
    label: '중개보조원',
  },
];

export const sub = [
  // {
  //   value: 0,
  //   label: '대표공인중개사',
  // },
  {
    value: 1,
    label: '소속공인중개사',
  },
  // {
  //   value: 2,
  //   label: '중개보조원',
  // },
];

export default {
  register,
  all,
  master,
  sub,
};

import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 's3Pack__group',
})<{ contentWidth: string; }>`
  flex: none;
  width: ${({ contentWidth }) => contentWidth};
`;

export const Content = styled.div<{
  hasTitle: boolean;
  hasMsg: boolean;
  topSpace: number;
  leftSpace: number;
  isHorizontal: boolean;
}>`
  flex: none;
  display: flex;
  width: 100%;
  padding-top: ${({ hasTitle }) => hasTitle ? '10px' : '0'};
  padding-bottom: ${({ hasTitle, hasMsg }) => hasTitle || hasMsg ? '10px' : '0'};
  
  > div {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${({ isHorizontal }) => isHorizontal ? 'column' : 'row'};
    justify-content: flex-start;
    margin-top: ${({ topSpace }) => -topSpace}px;
    margin-left: ${({ leftSpace }) => -leftSpace}px;
    
    > label {
      margin-top: ${({ topSpace }) => topSpace}px;
      margin-left: ${({ leftSpace }) => leftSpace}px;
    }
  }
`;

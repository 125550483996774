import styled from 'styled-components';

import type { TFontName, TFontWeight } from '../types';

import checked from './images/ic_24_checkbox_checked.svg';
import off from './images/ic_24_checkbox_enabled.svg';
import disabled from './images/ic_24_checkbox_disabled.svg';
import checkedDisabled from './images/ic_24_checkbox_selected_disabled.svg';

export const Label = styled.label<{
  paddingTop: string;
  paddingBottom: string;
}>`
  flex: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
`;

export const Checkbox = styled.input`
  flex: none;
  width: 24px;
  height: 24px;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: url(${off}) center center no-repeat;
  background-size: cover;
  appearance: none;
  cursor: pointer;

  &:checked {
    background-image: url(${checked});
  }

  &:disabled {
    background-image: url(${disabled});
    cursor: default;
  }

  &:checked:disabled {
    background-image: url(${checkedDisabled});
  }
`;

type TFontInfo = [TFontName, TFontWeight];

export const TextContent = styled.p<{
  textPosition: 'left' | 'right';
  fontInfo?: TFontInfo;
  isDisabled?: boolean;
}>`
  ${({ textPosition }) => textPosition === 'left' ? 'padding-right: 4px' : 'padding-left: 4px'};
  color: ${({ isDisabled, theme }) => isDisabled ? theme.color.gray[500] : theme.color.gray[900]};
  ${({ fontInfo, theme }) => fontInfo ? theme.font[fontInfo[0]][fontInfo[1]] : theme.font.body3.regular};
  user-select: none;
  cursor: ${({ isDisabled }) => isDisabled ? 'default' : 'pointer'};
`;

import styled from 'styled-components';

export const Content = styled.div`
  flex: none;
  margin: auto;
  background-color: ${({ theme }) => theme.color.white};
  position: relative;
  transition: width 200ms ease-in-out;
`;

export const Overlay = styled.div`
  flex: none;
  display: flex;
  width: 100%;
  max-height: 100%;
  padding: ${({ isMobile }) => isMobile ? 0 : '100px 20px'};
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  -ms-overflow-style: none; // IE에서 스크롤바 감춤

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;

  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  ${Content} {
    width: ${({ minWidth, isMobile }) => isMobile ? 100 : minWidth}${({ unit }) => unit};
  }
`;

export const CloseBtn = styled.button`
  width: 50px;
  height: 50px;
  font-size: 0;
  border: 0;
  background-color: #ebebeb;
  position: absolute;
  top: 0;
  right: 0;

  > svg {
    position: relative;
  }
`;

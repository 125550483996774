/**
 * @description 허위매물 신고 확인 상태값이 신고접수, 검증진행, 허위매물인 경우 true를 리턴.
 * @param isReportMask boolean
 * @param roomReportStep 'REPORT_ROOM' | 'PROGRESS_ROOM' | 'FAKE_ROOM' | string
 */
function isReport(isReportMask: boolean, roomReportStep: 'REPORT_ROOM' | 'PROGRESS_ROOM' | 'FAKE_ROOM' | string) {
  return isReportMask && ['REPORT_ROOM', 'PROGRESS_ROOM', 'FAKE_ROOM'].includes(roomReportStep);
}

export default isReport;

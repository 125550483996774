import React from 'react';
import { createPortal } from 'react-dom';
import { func, number, bool, any } from 'prop-types';

import Scrollbar from '~/components/scrollbar2';

import useModal from './useModal';

import { Overlay, Content, CloseBtn } from './styled';

function ModalComponent(props) {
  const _elem = useModal(props);

  const unit = props.isMobile ? '%' : 'px';

  return createPortal(
    <>
      <Scrollbar containerId="modal-overlay" screenMode="dark" />
      <Overlay
        id="modal-overlay"
        minWidth={props.minWidth}
        unit={unit}
        isMobile={props.isMobile}
        onClick={props.escToOverlay ? props.handle : () => {}}
      >
        <Content onClick={e => e.stopPropagation()}>
          {!props.hideClose && !props.isMobile && <CloseBtn onClick={() => props.handle()}>
            <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L19 19M19 1L1 19" stroke="#969696" />
            </svg>
          </CloseBtn>}

          {props.children}
        </Content>
      </Overlay>
    </>,
    _elem.current,
  );
}

ModalComponent.propTypes = {
  handle: func,
  minWidth: number,
  isMobile: bool,
  hideClose: bool,
  escToClose: bool,
  escToOverlay: bool,
  children: any,
};

ModalComponent.defaultProps = {
  minWidth: 400,
  isMobile: false,
  hideClose: false,
  escToClose: true,
  escToOverlay: false,
};

export default ModalComponent;

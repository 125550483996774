export default {
  imgCache: new Date().valueOf(),

  isAuth: false,                    // 로그인여부
  isLoading: true,                  // 유저정보 받아오는중
  isMaster: false,                  // 대표 여부
  userProfile: '',                  // 프로필 이미지 URL

  /** 공인중개사무소 정보 */
  agentId: '',                      // 부동산 고유값
  agentName: '',                    // 부동산 명
  agentAddress: '',                 // 부동산 주소
  agentTel: '',                     // 부동산 전화번호
  agentFacename: '',                // 대표 이름
  agentPhone: '',                   // 대표 휴대폰 번호
  agentTels: null,                  // 대표 연락처 번호 배열
  agentEmail: '',                   // 대표 이메일
  agentBusinessId: '',              // 사업자 등록번호
  agentBusinessIdImg: '',           // 사업자 등록증 이미지 키 값
  agentRegId: '',                   // 중개사 등록번호
  agentRegIdImg: '',                // 중개사 등록증 이미지 키 값
  agentSubUsers: [],                // 하위계정 정보
  agentSignStatus: '',              // 대표계정 서비스 상태(NONE: 신청불가, APPLICABLE: 신청 가능, PROCEED: 승인 진행중, APPROVAL: 승인 완료)
  agentGreetings: '',               // 인사말
  vested: false,                    // 다방에 속한 중개사인가 (다방: true / 영업대행: false)
  nsdiAgentSeq: '',                 // nsdi 번호

  /** 개인 정보 */
  bannedCount: 0,                   // 제제 카운트
  userName: '',                     // 유저 이름
  userEmail: '',                    // 유저 이메일
  userRoleType: '',                 // 유저 권한/자격
  userDuty: '',                     // 유저 직책
  userTel: '',                      // 유저 전화번호
  userPhone: '',                    // 유저 휴대폰 번호
  safePhone: '',                    // 유저 안심번호
  userIdx: 0,                       // 유저 번호
  isShowSetContactMethod: false,    // 문의하기 최초 팝업 여부
  contactMethods: [],               // 문의하기 방법 리스트 (0: 간편문의, 1: 전화문의, 2: 문자문의, 3: 톡상담 문의)
  contactMethodTypes: [],           // 문의하기 방법 리스트 (CALL: 전화문의, SMS: 문자문의, CHAT_TALK: 톡상담 문의)
  unreadMessengerCount: 0,          // 미답변 문의 개수
  /** 상품 정보 */
  // generalItemCount: 0,              // 일반 상품 갯수
  // premiumItemCount: 0,              // 프리미엄 상품 갯수
  // classQuick: '',                   // 플러스 상품 클래스
  // classGeneral: '',                 // 일방 상품 클래스
  // classOfficetel: '',               // 오피스텔 상품 클래스
  // classApt: '',                     // 아파트 상품 클래스
  // usePackage: false,                // 패키지 이용중인지 여부
  useRecommend: false,                 // 추천상품 이용중인지 여부
  usePremium: false,                   // 프리미엄 이용중인지 여부
  useVip: false,                       // 프리미엄 or 패키지 이용 여부
  useItem: false,                      // 상품 갯수 boolean

  isOwnerTerms: false,              // 공실센터 약관동의
  isAnalysis: false,                // 권리 분석 약관동의
  isMessenger: false,               // 매물 확인 메신저(간편문의)
  isSafeAuth: false,                // 본인인증 여부
  isCeoSafeAuth: false,             // 대표자 인증 여부
  isShowNsdiUpdateNoti: false,      // 대표자 인증 팝업 플래그
  isSubscribe: false,               // 이벤트 및 혜택 알림 수신동의 (이메일, SMS)
  isDisplayLH: false,               // LH 마이홈 노출 설정
  isServeUser: false,               // 써브 유저 플래그
  isServeItemUsedApt: false,        // 써브 아이템 사용 플래그 (아파트)
  isServeItemUsedOfficetel: false,  // 써브 아이템 사용 플래그 (오피스텔)
  isShowSignMenu: false,            // 전자계약 사용 계정 여부
  isSignUser: false,                // 전자계약 약관 동의 여부

  /** 확인매물 정보 */
  useConfirmCount: 0,               // 현재 이용중인 확인매물 수 (대시보드)

  /** 공실 정보 */
  addressSigungu: '',               // 공실 매물을 받을수있는 범위의 주소
  useOwnerCount: 0,                 // 현재 이용중인 공실 수 (대시보드)
  usePickCount: 0,                  // 현재 이용중인 중개요청 수 (대시보드)
  maxOwnerCount: 0,                 // 최대 이용가능한 공실 수 (대시보드)

  chat: {
    responseLevel: '',              // 채팅 응답률(GOOD : 최고에요, NORMAL : 좋아요, BAD : 느려요), 필수, 문자형
  },

  /** 담당자 정보 */
  receptionist: {
    name: '',                       // 담당자 이름
    phone: '',                      // 담당자 휴대폰 번호
    message: '',                    // 메시지??
  },                     // 별점

  /** 키소(허위매물)) 정보 */
  reportCountStr: '',               // 미처리 신고접수건
  reportCount: 0,                   // 미처리 신고접수건
  warningCountStr: '',              // 경고횟수 문자열
  warningCount: 0,                  // 경고횟수
  hasPenalty: false,                // 다방 매물 등록 페널티 여부
  hasNaverPenalty: false,           // 네이버 매물 등록 페널티 여부
  hasReport: false,                 // 허위매물 신고 자율처리 미처리건 존재 여부 (계정)
  hasWarning: false,                // KISO 유효 경고 존재여부

  /** 네이버 허위매물 정보 (계정) */
  hasNaverReport: false,
  naverReportCount: 0,
  naverReportCountStr: '',
  /** 네이버 동일 주소 거래완료 정보 (계정) */
  hasNaverSameAddressTradeComplete: false,
  naverSameAddressTradeCompleteCount: 0,
  naverSameAddressTradeCompleteCountStr: '',

  /** 다방 허위매물 정보 (중개사 기준) */
  hasWaitReportByBusinessId: false,
  waitReportUserListByBusinessId: null,
  /** 네이버 허위매물 정보 (중개사 기준) */
  hasWaitNaverReportByBusinessId: false,
  waitNaverReportUserListByBusinessId: null,
  /** 네이버 동일 주소 거래완료 정보 (중개사 기준) */
  hasWaitNaverSameAddressTradeCompleteByBusinessId: false,
  waitNaverSameAddressTradeCompleteUserListByBusinessId: null,

  /** 네이버 상품 구매이력 */
  hasNaverItem: false,
};

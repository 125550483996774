import { css } from 'styled-components';

export const btnStd = css`
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 150ms ease-out;
  border: 0;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;

  > svg {
    flex: none;
  }

  &:disabled {
    cursor: default;
  }
`;

export default btnStd;

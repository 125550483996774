export default {
  getPlatform() {
    if (typeof Android !== 'undefined') {
      return 'android';
    } else if (typeof IOS !== 'undefined') {
      return 'ios';
    } else {
      return 'web';
    }
  },
  ios(data) {
    if (this.getPlatform() === 'ios') {
      const callback = call => {
        call.callHandler('iOS Echo', data);
      };

      if (window.WKWebViewJavascriptBridge) { return callback(window.WKWebViewJavascriptBridge); }
      if (window.WKWVJBCallbacks) { return window.WKWVJBCallbacks.push(callback); }
      window.WKWVJBCallbacks = [callback];
      window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(data);
    }
  },
  android(callback) {
    const { Android } = window;
    if (this.getPlatform() === 'android') {
      if (typeof Android !== 'undefined') {
        callback(Android);
      } else {
        alert('Bridge가 설정되지 않았습니다.');
      }
    }
  },
  web(callback) {
    if (this.getPlatform() === 'web') {
      callback();
    }
  },
};

import React, { ReactNode, CSSProperties } from 'react';

import Scrollbar from '.';

import { Container, ScrollArea } from './styled';

interface Props {
  id: string;
  containerHeight?: string;
  children: ReactNode | ReactNode[];
  style?: CSSProperties;
}

function ContainerComponent({
  id,
  containerHeight,
  children,
  style,
}: Props) {
  if (!id) {
    alert('스크롤 컨테이너를 사용하기위해선 id 값이 필수입니다!!');
    return null;
  }

  return (
    <Container
      containerHeight={containerHeight}
      style={style}
    >
      <Scrollbar containerId={id} />
      <ScrollArea id={id}>
        {children}
      </ScrollArea>
    </Container>
  );
}

export default ContainerComponent;

import React, { forwardRef } from 'react';

import type { LinkProps } from '../types';

import { LineLink, Text } from './styled';

export const LineLinkUIComponent = forwardRef<HTMLAnchorElement, LinkProps>(({
  contentWidth = 'auto',
  leftIcon,
  rightIcon,
  btnColor,
  btnSize,
  fontWeight,
  children,
  ...props
}, ref) => {
  return (
    <LineLink
      ref={ref}
      type="button"
      contentWidth={contentWidth}
      btnColor={btnColor}
      btnSize={btnSize}
      fontWeight={fontWeight}
      {...props}
    >
      {leftIcon}

      <Text
        hasLeftIcon={!!leftIcon}
        hasRightIcon={!!rightIcon}
      >
        {children}
      </Text>

      {rightIcon}
    </LineLink>
  );
});

LineLinkUIComponent.displayName = 'LineLink';

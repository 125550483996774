import styled from 'styled-components';

export const MenuButton = styled.button`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  border: 0;
  color: ${({ theme }) => theme.color.gray[900]};
  ${({ theme }) => theme.font.body3.regular};

  > svg {
    margin-left: 4px;
  }
`;

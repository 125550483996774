import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { produce } from 'immer';

import type { S3ChangeEvent } from '~/atoms2';

import { makeContactMethod } from '~/utils/make-data';

import useApiCall from '~/hooks/useApiCall';
import { useToast } from '~/components/toast';

interface State {
  contactMethodTypes: Common.TContactMethodType[];
  chatContactMessage: string;
}

interface Props {
  contactMethodTypes: Common.TContactMethodType[];
  chatContactMessage: string;
  closePopup: (needReload: boolean) => void;
}

const { confirm } = window;

function useInquirySetting({
  contactMethodTypes,
  chatContactMessage,
  closePopup,
}: Props) {
  const toast = useToast();
  const dispatch = useDispatch();

  const { post } = useApiCall();

  const [isSending, setIsSending] = useState(false);
  const [state, setState] = useState<State>({
    contactMethodTypes: contactMethodTypes ?? [],
    chatContactMessage: chatContactMessage ?? '',
  });

  function changeCheckbox(type: Common.TContactMethodType) {
    setState(produce(draft => {
      const idx = draft.contactMethodTypes.findIndex(t => t === type);
      if (idx > -1) {
        draft.contactMethodTypes.splice(idx, 1);

      } else {
        draft.contactMethodTypes.push(type);
      }

      if (type === 'CHAT_TALK') {
        draft.chatContactMessage = chatContactMessage ?? '';
      }
    }));
  }

  function changeTalkMessage({ value }: S3ChangeEvent) {
    setState(produce(draft => {
      draft.chatContactMessage = value;
    }));
  }

  async function clickSubmit() {
    if (isSending) return;
    if (!isActiveSubmitBtn) return;

    if (!confirm('문의설정 정보를 저장하시겠습니까?')) return;
    setIsSending(true);

    try {
      await post('/api/v2/user/contact-method/edit2', state);

      const { numberMethods } = makeContactMethod(state.contactMethodTypes);
      /* @ts-ignore */
      dispatch.user.update({
        isShowSetContactMethod: false,
        contactMethods: numberMethods,
        contactMethodTypes: state.contactMethodTypes,
        chatContactMessage: state.chatContactMessage,
      });

      toast.success('문의설정 정보가 저장되었습니다.');
      closePopup(true);

    } catch (err) {
      console.log(err);
      toast.error(err?.msg ?? '서버에러가 발생하였습니다.');

    } finally {
      setIsSending(false);
    }
  }

  const isActiveSubmitBtn = useMemo(() => {
    if (state.contactMethodTypes.length === 0) return false;
    return !(state.contactMethodTypes.includes('CHAT_TALK') && state.chatContactMessage === '');

  },[state.contactMethodTypes, state.chatContactMessage]);

  return {
    isSending, state,
    changeCheckbox, changeTalkMessage,
    isActiveSubmitBtn, clickSubmit,
  };
}

export default useInquirySetting;

export default {
  // 공실 리스트
  items: [],
  // 페이지
  page: 1,
  // 제한 개수
  limit: 24,
  // 총 매물 개수
  total: 0,
  // 탭
  tab: 'all',
  // 공실 현황
  listCounts: {
    pickCount: 0,
    allCount: 0,
    preemptCount: 0,
    completeCount: 0,
  },
  // 탭
  tabs: [],
  // 매물 유형
  roomType: 0,
  // 거래 유형
  sellingType: 0,
  // 공실 리스트 로딩
  isLoading: true,
};

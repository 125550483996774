export default {
  // 매물 리스트 로딩
  isLoading: true,
  isFirstLoading: true,
  // 매물 리스트
  items: [],
  // 제한 개수
  limit: 24,
  // 총 매물 개수
  total: 0,
  // 플러스 노출 / 매물 이동 : on/off
  isShowSelector: false, // move(string), quick(string), false(boolean)
  // 플러스 노출 전처 선택 flag
  checkAll: false,
  // 선택된 매물
  selectRooms: [],
  // 탭 매물 개수
  counts: {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
  },
  product: {},

  // url location search
  search: {
    // 페이지
    page: 1,
    // 매물 유형
    structureFilter: '',
    // 방 수
    bedsNumFilter: '',
    // 거래 유형
    priceFilter: '',
    // 상품 유형
    quickFilter: '',
    // 추가 필터
    optionFilters: [],
    // 검색 키워드
    keyword: '',
    // 선택 계정
    user: '',
    // 탭
    roomSet: '',
  },
};

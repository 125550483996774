
const HEX_REGEX = /^#?[a-fA-F0-9]+$/;
const HEX_SHORTHAND_LENGTH = 3;
const HEX_LENGTH = 6;

function hex2rgba(hex: string, alpha: number = 1) {
  if (!HEX_REGEX.test(hex)) {
    throw Error('hex2rgba: first argument has invalid hexadecimal characters');
  }

  if (hex.length !== HEX_SHORTHAND_LENGTH + 1 && hex.length !== HEX_LENGTH + 1) {
    throw Error('hex2rgba: first argument has invalid hexadecimal length');
  }

  if (hex[0] === '#') {
    hex = hex.slice(1);
  }

  /**
   * #fff 와 같이 축약된 hex값 6자리로 변경 
   */
  let tempHex: string[] = hex.split('');
  if (hex.length === HEX_SHORTHAND_LENGTH) {
    tempHex = hex.split('');
    tempHex.splice(2, 0, hex[2]);
    tempHex.splice(1, 0, hex[1]);
    tempHex.splice(0, 0, hex[0]);
  }

  hex = tempHex.join('');

  const pattern = /[a-fA-F0-9]{1,2}/ig;
  const rgba = [0, 0, 0, alpha];

  for (
    let i = 0, match;
    i < 4 && (match = pattern.exec(hex)) !== null;
    i++
  ) {
    rgba[i] = parseInt(match.toString(), 16);
  }

  return `rgba(${rgba.join(',')})`;
}

export default hex2rgba;
import React from 'react';
import { useSelector } from 'react-redux';

import useReportProcessing, { REASON_TYPE, BUILDING_USE_TYPE } from './useReportProcessing';

import Modal from '~/components/modal';
import Loading from '~/components/loading/small';
import SvgIcon from '~/components/svg-icon';
import { Group, LineBtn, SolidBtn, Select } from '~/atoms2';

import { Header, Body, BtnWrap, LoadingWrap, CountChip, Top, TextWrap, Box, SelectWrap, Notice, InfoList, TextBtn } from './styled';

interface Props {
  inflowType: 'DABANG' | 'NAVER';
  callback: () => void;
  handler?: () => void;
}

export function ReportProcessingComponent({
  inflowType,
  callback,
  handler
}: Props) {
  const { reportCountStr, naverReportCountStr } = useSelector(({ user }: any) => ({ reportCountStr: user.reportCountStr, naverReportCountStr: user.naverReportCountStr }));
  const {
    isLoading, isSending,
    state,
    answer,
    changeAnswer,
    submit,
  } = useReportProcessing({ inflowType, callback, handler });

  const isDabang = inflowType === 'DABANG';

  function close() {
    handler && handler();
    callback && callback();
  }

  return (
    <Modal
      minWidth={520}
      handle={handler && handler}
      hideClose
      escToClose={false}
    >
      <Header>
        <h1>중개사 자율처리</h1>
        <CountChip>총 <span>{isDabang ? reportCountStr : naverReportCountStr}</span>개 미처리</CountChip>
      </Header>

      <Body>
        {isLoading ? (
          <LoadingWrap>
            <Loading />
          </LoadingWrap>
        ) : (
          <>
            <Top>
              <div className="text">
                <p>해당 매물은</p>
                <p className="red">허위매물로 신고되었습니다.</p>
              </div>
            </Top>
  
            <TextWrap>
              <p>부동산매물클린관리센터 정책에 따라 중개사는 2일 이내(영업일 기준) 해당 매물의 조치 결과를 전송해야 하며, 미처리 시 자동으로 경고가 부과 후 매물 노출은 종료됩니다. 신고 접수된 매물은 수정이 불가하며 종료, 삭제 여부와 관계없이 검증이 진행됩니다.</p>
            </TextWrap>
  
            <Box>
              <li>
                <div className="th">매물번호</div>
                <div className="td">{isDabang ? state?.roomsSeq ?? '-' : state?.naverRoomNum ?? '-'}</div>
              </li>
              <li>
                <div className="th">신고접수</div>
                <div className="td">{isDabang ? '다방' : '네이버'}</div>
              </li>
              <li>
                <div className="th">신고시간</div>
                <div className="td">{state?.savedTime || '-'}</div>
              </li>
              <li>
                <div className="th">신고항목</div>
                <div className="td column">
                  <p>{REASON_TYPE[state?.reasonType ?? '-']}</p>
                  <TextBtn onClick={() => alert(state.reasonDescription)}>
                    상세보기
                  </TextBtn>
                </div>
              </li>
              <li>
                <div className="th">
                  건축물용도
                </div>
                <div className="td">{state?.buildingUseType === 'INPUT' ? state?.buildingUseStr ?? '-' : BUILDING_USE_TYPE[state?.buildingUseType ?? '-']}</div>
              </li>
              <li>
                <div className="th">거래유형</div>
                <div className="td">
                  {!state.leaseType ? (
                    <>-</>
                  ) : state.leaseType === 'LEASE' ? (
                    <>
                      전세 {state.deposit}
                    </>
                  ) : state.leaseType === 'SALE' ? (
                    <>
                      매매 {state.deposit}
                    </>
                  ) : (
                    <>
                      월세 {state.deposit}{state.price && `/${state.price}`}
                    </>
                  )}
                </div>
              </li>
              <li>
                <div className="th">매물주소</div>
                <div className="td">{state.address || '-'}</div>
              </li>
              <li>
                <div className="th">연락처</div>
                <div className="td">{state.formatAgentContact || '-'}</div>
              </li>
            </Box>

            <SelectWrap>
              <Group
                isRequired
                title="중개사 자율처리 상태값을 선택해주세요."
                contentWidth="100%"
              >
                <Select
                  value={answer}
                  onChangeNative={changeAnswer}
                >
                  <option value="" disabled={true}>선택해주세요.</option>
                  <option value="NOMAL">정상매물</option>
                  <option value="FALSE">광고종료 (허위매물)</option>
                  {state.reasonType === 'CMPL' && <option value="CMPL">거래완료</option>}
                </Select>
              </Group>
            </SelectWrap>

            {answer === 'NOMAL' ? (
              <InfoList>
                <p>
                  <SvgIcon name="ic_16_info_red_solid" />
                  <span>자율 처리 전 반드시 확인 해 주세요.</span>
                </p>
                <li>매물 정보(가격, 주소, 면적, 사진 등)가 정확하고 거래가 가능한 경우만 '정상매물'로 처리 바랍니다.</li>
                <li>부동산매물클린관리센터에서 확인 시 정상매물 입증이 가능한 경우에만 정상 처리 부탁 드립니다.</li>
                <li>정상매물 처리 후 확인 과정에서 허위로 판명되면 즉시 이용이 제한(7일 또는 14일)됩니다.</li>
                <li>신고 내용이 부정확하더라도 실제 거래가 불가하면 광고종료로 처리해 주시기 바랍니다.</li>
              </InfoList>
            ) : answer === 'FALSE' ? (
              <InfoList>
                <p>
                  <SvgIcon name="ic_16_info_red_solid" />
                  <span>자율 처리 전 반드시 확인 해 주세요.</span>
                </p>
                <li>정상 매물이 아닐 경우, 즉시 노출 종료 해주시기 바랍니다.</li>
                <li>광고 종료(허위매물)시, 경고 1회가 부과되며, 경고 횟수 3회 누적 시 이용이 제한(7일)됩니다.</li>
              </InfoList>
            ) : answer === 'CMPL' ? (
              <InfoList>
                <p>
                  <SvgIcon name="ic_16_info_red_solid" />
                  <span>자율 처리 전 반드시 확인 해 주세요.</span>
                </p>
                <li>신고처리가 거래완료가 아닌 경우 경고 1회가 부과되며, 거래완료인 경우에는 경고가 부과되지 않습니다.</li>
              </InfoList>
            ) : (
              <Notice>
                <p>부동산매물클린관리센터 검증 시, 계정에 등록된 연락처가 잘못되어</p>
                <p>불이익을 받는 일이 없도록 주의하시기 바랍니다.</p>
              </Notice>
            )}

            <BtnWrap>
              <LineBtn
                btnSize="xl"
                btnColor="gray"
                contentWidth="120px"
                disabled={isLoading || isSending}
                onClick={close}
              >
                닫기
              </LineBtn>

              <SolidBtn
                btnSize="xl"
                btnColor="blue"
                disabled={answer === '' || isLoading || isSending}
                contentWidth="120px"
                onClick={submit}
              >
                전송
              </SolidBtn>
            </BtnWrap>
          </>
        )}
      </Body>
    </Modal>
  );
}

import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useApiCall from '~/hooks/useApiCall';

function useFooter() {
  const [terms, setTerms] = useState([]);

  const { userEmail, isLoading } = useSelector(({ user }) => ({
    userEmail: user.userEmail,
    isLoading: user.isLoading,
  }));

  const { get } = useApiCall();

  const getTerms = useCallback(async () => {
    const apiUrl = userEmail ? '/api/v2/terms/login/footer' : '/api/v2/terms/logout/footer';

    try {
      const res = await get(apiUrl);
      setTerms(res ?? []);

    } catch(err) {
      console.log(err);
    }
  }, [userEmail]);

  useEffect(() => {
    if (!isLoading) {
      getTerms();
    }
  }, [userEmail, isLoading]);

  return { terms, userEmail };
}

export default useFooter;

import { createModel } from '@rematch/core';

const common = createModel({
  state: {
    isFloatingButtonOpen: false,
  },
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default common;

import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import type { TFontColor, TFontSize, TFontWeight } from '../../types';
import { getBtnSize } from '../../utils';

function getColor(color: TFontColor) {
  switch (color) {
    case 'blue':
      return css`
        color: ${({ theme }) => theme.color.blue['500']};
        border: 1px solid ${({ theme }) => theme.color.blue['500']};
        
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: ${({ theme }) => theme.color.blue['50']}; 
          }
        }
      `;

    case 'red':
      return css`
        color: ${({ theme }) => theme.color.red['500']};
        border: 1px solid ${({ theme }) => theme.color.red['500']};

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: ${({ theme }) => theme.color.red['50']};
          }
        }
      `;

    case 'gray':
      return css`
        color: ${({ theme }) => theme.color.gray['900']};
        border: 1px solid ${({ theme }) => theme.color.gray['400']};

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: ${({ theme }) => theme.color.gray['100']};
          }
        }
      `;
  }

  return '';
}

export const lineLinkStd = css<{
  contentWidth: string;
  btnColor: TFontColor;
  btnSize: TFontSize;
  fontWeight?: TFontWeight;
}>`
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ contentWidth }) => contentWidth};
  padding: 0 16px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.white};
  transition: all 150ms ease-out;
  cursor: pointer;

  ${({ btnSize, fontWeight }) => getBtnSize(btnSize, fontWeight)};
  ${({ btnColor }) => getColor(btnColor)};

  > svg {
    flex: none;
  }
  
  &:disabled {
    color: ${({ theme }) => theme.color.gray['500']};
    border-color: ${({ theme }) => theme.color.gray['400']};
    background-color: ${({ theme }) => theme.color.gray['100']};
    cursor: default;
    
    > svg {
      circle[fill],
      ellipse[fill],
      line[fill],
      path[fill],
      polygon[fill],
      polyline[fill],
      rect[fill] {
        fill: ${({ theme }) => theme.color.gray[500]} !important;
      }

      circle[stroke],
      ellipse[stroke],
      line[stroke],
      path[stroke],
      polygon[stroke],
      polyline[stroke],
      rect[stroke] {
        stroke: ${({ theme }) => theme.color.gray[500]} !important;
      }
    }
  }
`;

export const LineLink = styled(({
  contentWidth,
  btnColor,
  btnSize,
  fontWeight,
  ...props
}) => <Link {...props} />)`
  ${lineLinkStd};
`;

export const Text = styled.span<{
  hasLeftIcon: boolean;
  hasRightIcon: boolean;
}>`
  margin-left: ${({ hasLeftIcon }) => hasLeftIcon ? '4px' : '0'};
  margin-right: ${({ hasRightIcon }) => hasRightIcon ? '4px' : '0'};
`;

import React, { forwardRef } from 'react';

import type { LinkProps } from '../types';

import { SolidLink, Text } from './styled';

export const SolidLinkUIComponent = forwardRef<HTMLAnchorElement, LinkProps>(({
  contentWidth = 'auto',
  leftIcon,
  rightIcon,
  btnColor,
  btnSize,
  fontWeight,
  children,
  ...props
}, ref) => {
  return (
    <SolidLink
      ref={ref}
      type="button"
      contentWidth={contentWidth}
      btnColor={btnColor}
      btnSize={btnSize}
      fontWeight={fontWeight}
      {...props}
    >
      {leftIcon}

      <Text
        hasLeftIcon={!!leftIcon}
        hasRightIcon={!!rightIcon}
      >
        {children}
      </Text>

      {rightIcon}
    </SolidLink>
  );
});

SolidLinkUIComponent.displayName = 'SolidLink';

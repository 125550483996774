import styled from 'styled-components';

export const Wrap = styled.div`
  padding: 40px 40px 50px;
  border-top: 1px solid ${({ theme }) => theme.veryLightGreyLine.getColor()};
`;

export const Title = styled.h1`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.grey22.getColor()};
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.grey22.getColor()};
  font-size: 14px;
  line-height: 22px;
  white-space: pre-wrap;
`;

export const BtnWrap = styled.div`
  display: flex;
  width: 100%;
  height: 56px;

  > button,
  > a {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.white.getColor()};
    font-size: 16px;
    font-weight: 700;
    

    &:first-child {
      background-color: ${({ theme }) => theme.grey8f.getColor()};
    }
    &:last-child {
      background-color: ${({ theme }) => theme.proMainColor.getColor()};
    }
  }
`;

export const CloseBtn = styled.button`
  border: 0;
`;

export const ShowDetail = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
`;

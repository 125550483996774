import { useState, useEffect, useMemo } from 'react';
import Cookie from 'js-cookie';

import isEmpty from '~/utils/valid/isEmpty';
import useApiCall from '~/hooks/useApiCall';
import { useToast } from '~/components/toast';

function useNotice(resetType) {
  const [list, setList] = useState([]);
  const [show, setShow] = useState({
    id: 1,
    title: '',
    content: '',
    expires: 1,
    url: '',
  });
  const { get } = useApiCall();
  const toast = useToast();

  useEffect(() => {
    getNotice();
  }, []);

  async function getNotice() {
    try {
      const res = await get('/api/v2/notice/popup', { type: 1 });

      const newRes = res.filter(item => !Cookie.get(`notice_${item.seq}`));
      
      if (newRes.length > 0) {
        setList(newRes);
        setShow(newRes[0]);
      } else {
        resetType({ isCloseNotice: true });
      }

    } catch(err) {
      const msg = err?.msg ?? '서버에러가 발생하였습니다.';
      toast.error(msg);
    }
  }

  function clickClose() {
    const remainList = list.filter(i => i.seq !== show.seq);
    setList(remainList);

    if (remainList.length > 0) {
      setShow(remainList[0]);
    } else {
      resetType({ isCloseNotice: true });
    }
  }

  function clickCloseWithExpire() {
    const expires = isEmpty(show?.expires) ? 365 : show.expires;
    Cookie.set(`notice_${show.seq}`, show.seq, { expires });
    clickClose();
  }

  const isShow = useMemo(() => list.length > 0, [list]);

  return { isShow, show, clickClose, clickCloseWithExpire };
}

export default useNotice;
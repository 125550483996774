export default {
  // 일반
  GENERAL_ALL_IN_ONE: {
    type: 0,
    label: '올인원 일반',
    value: 'general_allinone',
  },
  GENERAL_ONE_TWO_THREE_ROOM: {
    type: 6,
    label: '원/투/쓰리룸 일반',
    value: 'general_one_two_three_room',
  },
  GENERAL_OFFICETEL: {
    type: 7,
    label: '오피스텔 일반',
    value: 'general_officetel',
  },
  GENERAL_APT: {
    type: 8,
    label: '아파트 일반',
    value: 'general_apt',
  },

  // 프리미엄
  SUBWAY: {
    type: 1,
    label: '지하철 프리미엄',
    value: 'subway',
  },
  REGION: {
    type: 2,
    label: '동 프리미엄',
    value: 'region',
  },
  UNIV: {
    type: 3,
    label: '캠퍼스 프리미엄',
    value: 'univ',
  },

  // 단지
  APT: {
    type: 4,
    label: '아파트 단지',
    value: 'apt',
  },
  OFFICETEL: {
    type: 5,
    label: '오피스텔 단지',
    value: 'officetel',
  },

  // 플러스
  PLUS_30_DAY: {
    type: 10,
    label: '플러스정기권',
    value: 'quick_30day',
  },
  PLUS_1_DAY: {
    type: 11,
    label: '플러스1일권',
    value: 'quick_1day',
  },

  // 확인매물
  CONFIRM_1TIME: {
    type: 50,
    label: '확인매물 등록권',
    value: 'confirm_1time',
  },

  // 다방싸인
  SIGN_BASIC: {
    type: 60,
    label: '다방싸인 BASIC',
    value: 'contract_basic',
  },
  SIGN_PREMIUM: {
    type: 61,
    label: '다방싸인 PREMIUM',
    value: 'contract_advanced',
  },

  // 현장촬영
  SPOT_SHOOTING_SEASON: {
    type: 70,
    label: '현장촬영 정기권',
    value: 'confirm_regist_agency_30day',
  },
  SPOT_SHOOTING_ONE_TIME: {
    type: 71,
    label: '현장촬영 1회권',
    value: 'confirm_regist_agency_1time',
  },
  SPOT_SHOOTING_SEASON_FROM_MASTER: {
    type: 72,
    label: '[M] 현장촬영 정기권',
    value: 'confirm_regist_agency_include_self_regist_30day',
  },

  // 패키지
  PACKAGE_ONE_TWO_THREE_TEN: {
    type: 20,
    label: '원/투/쓰리룸 패키지',
    value: 'package_one_two_three_room',
  },
  PACKAGE_OFFICETEL_FIVE: {
    type: 21,
    label: '오피스텔 패키지',
    value: 'package_officetel_five',
  },
  PACKAGE_APT: {
    type: 22,
    label: '아파트 패키지',
    value: 'package_apt',
  },
  PACKAGE_ONE_TWO_THREE_FIVE: {
    type: 23,
    label: '원/투/쓰리룸 패키지(5건)',
    value: 'package_one_two_three_half',
  },
  PACKAGE_OFFICETEL_TEN: {
    type: 24,
    label: '오피스텔 패키지(10건)',
    value: 'package_officetel_ten',
  },
  PACKAGE_MASTER: {
    type: 25,
    label: '마스터 패키지',
    value: 'package_master',
  },
  PACKAGE_ALL_IN_ONE_TEN: {
    type: 26,
    label: '올인원 패키지(10건)',
    value: 'package_allinone_ten',
  },
  PACKAGE_ALL_IN_ONE_FIVE: {
    type: 27,
    label: '올인원 패키지(5건)',
    value: 'package_allinone_five',
  },

  // 네이버
  NAVER_GENERAL_CONFIRM: {
    type: 80,
    label: 'N쿠폰(일반)',
    value: 'naver_general_confirm',
  },
  NAVER_SITE_CONFIRM: {
    type: 81,
    label: 'N쿠폰(현장)',
    value: 'naver_site_confirm',
  },
  DEUNGBON_AUTO: {
    type: 82,
    label: '등기부등본 자동발급',
    value: 'deungbon_auto',
  },
  NAVER_GENERAL_CONFIRM_APT: {
    type: 83,
    label: 'N쿠폰(일반-아파트전용)',
    value: 'naver_general_confirm_apt',
  },

  // 추천상품
  REGION_RECOMMEND: {
    type: 90,
    label: '동 추천',
    value: 'region_recommend',
  },

  // 아이콘 포커스
  ICON_FOCUS: {
    type: 91,
    label: '아이콘 포커스',
    value: 'icon_focus',
  }
};

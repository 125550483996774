import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import layout from '~/components/styled/layout';

export const Header = styled.header`
  ${layout};

  flex: none;
  height: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
  background-color: ${({ theme }) => theme.color.white};
  position: relative;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > a {
      flex: none;
    }
  }
`;

export const RigthMenuBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export const Link = styled(RouterLink)`
  ${({ theme }) => theme.font.body3.regular};
  color: ${({ theme }) => theme.color.gray[600]};
  margin-left: 16px;
  text-decoration: underline;
  white-space: nowrap;

  &:last-child {
    margin-left: 32px;
  }

  > p {
    ${({ theme }) => theme.font.body3.regular};
    color: ${({ theme }) => theme.color.gray[600]};
    text-align: center;
  }
`;

import {
  getYear,
  getMonth,
  getDaysInMonth,
  getDay,
  getDate,
  eachDayOfInterval,
  parseISO,
  isSameDay,
  isBefore,
} from 'date-fns';

import { isBetween } from './date';

export interface DateItem {
  isToday: boolean;
  isPast: boolean;
  isOutOfRange: boolean;
  formatDate: '' | number;
  date: Date | null;
}

const MAX_DATE = '3000-12-31';
const MIN_DATE = '1900-01-01';
const datePattern = /^(\d{4})[\w\-./](\d{2})[\w\-./](\d{2})$/g;

interface DateOfMonthProps {
  year: number,
  month: number,
  maxDate?: string;
  minDate?: string;
}

interface ReturnData {
  days: DateItem[];
  calendarDays: DateItem[];
}


export function getDateOfMonth({
  year, month,
  maxDate = MAX_DATE,
  minDate = MIN_DATE,
}: DateOfMonthProps): ReturnData {
  if (new RegExp(datePattern).test(minDate)) {
    minDate = minDate.replace(datePattern, '$1-$2-$3');
  } else {
    console.error('invalid minDate', minDate);
  }

  if (new RegExp(datePattern).test(maxDate)) {
    maxDate = maxDate.replace(datePattern, '$1-$2-$3');
  } else {
    console.error('invalid maxDate', maxDate);
  }

  if (month < 0 || month > 11) {
    throw Error('month는 0~11 사이의 값만 사용가능합니다. [0 = 1월]');
  }

  const totalDaysInMonth = getDaysInMonth(new Date(year, month));
  const firstDay = getDay(new Date(year, month, 1));

  const temp: DateItem[] = Array.from({ length: firstDay }, () => ({
    isToday: false,
    isPast: true,
    isOutOfRange: true,
    formatDate: '',
    date: null,
  }));

  const days: DateItem[] = eachDayOfInterval({
    start: new Date(year, month, 1),
    end: new Date(year, month, totalDaysInMonth),
  }).map(d => {
    const now = new Date();
    const min = parseISO(minDate);
    const max = parseISO(maxDate);

    return {
      isToday: isSameDay(d, now),
      isPast: isBefore(d, now),
      isOutOfRange: !isBetween(d, min, max),
      formatDate: getDate(d),
      date: d,
    };
  });

  return {
    days,
    calendarDays: [...temp, ...days],
  };
}

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { register, sub } from '~/enum/role';
import useApiCall from '~/hooks/useApiCall';
import { useToast } from '~/components/toast';

function useRoleType(resetType) {
  const [roleType, setRoleType] = useState('');
  const [isLoading, setLoading] = useState(false);

  const isMaster = useSelector(store => store.user.isMaster);
  const dispatch = useDispatch();

  const { post } = useApiCall();
  const toast = useToast();

  function changeType(e) {
    const { value } = e.currentTarget;
    setRoleType(value);
  }

  async function submit() {
    setLoading(true);

    try {
      await post('/api/v2/agent/set-role2', { roleType });

      toast.success('회원정보가 정상적으로 등록되었습니다.');

      await dispatch.user.getInfo();

      resetType();

    } catch(err) {
      setLoading(false);

      if (err?.code === 419) {
        alert(err?.msg ?? '서버에러가 발생하였습니다.');
      } else {
        toast.error(err?.msg ?? '서버에러가 발생하였습니다.');
      }
    }
  }

  const typeList = isMaster ? register : sub;

  return { typeList, roleType, isLoading, changeType, submit };
}

export default useRoleType;
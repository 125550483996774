import React from 'react';

import Modal from '~/components/modal';

import useImageNotice from './useImageNotice';

import { ImageLink, Image, BtnWrap, CloseBtn, ShowDetail } from './styled';

function ImageNoticePopup({ resetType }) {
  const { isShow, show, clickClose, clickCloseWithExpire, clickImage } = useImageNotice(resetType);

  if (!isShow) {
    return null;
  }

  return (
    <Modal minWidth={480} handle={clickClose} escToClose={false}>
      <ImageLink
        href={show?.url}
        onClick={clickImage}
      >
        <Image
          imgUrl={show.img}
          src={show.img}
          alt=""
          draggable={false}
        />
      </ImageLink>

      <BtnWrap>
        <CloseBtn onClick={clickCloseWithExpire}>
          오늘 하루 보지 않기
        </CloseBtn>
        {show?.url && (
          <ShowDetail
            href={show?.url}
            onClick={clickImage}
          >
          이벤트 보러가기
          </ShowDetail>
        )}
      </BtnWrap>
    </Modal>
  );
}

export default ImageNoticePopup;

class color {
  constructor() {
    this._r = 0;
    this._g = 0;
    this._b = 0;
  }

  setRGB(r, g, b) {
    this._r = r;
    this._g = g;
    this._b = b;
    return this;
  }

  getColor(a = 1) {
    if (a === 1) {
      return `rgb(${this._r}, ${this._g}, ${this._b})`;
    }

    return `rgba(${this._r}, ${this._g}, ${this._b}, ${a})`;
  }
}

export default color;

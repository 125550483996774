import styled, { keyframes } from 'styled-components';
import layout from '~/components/styled/layout';

export const Wrap = styled.div`
  ${layout};
  display: flex;
  align-items: center;
  height: 100%;
  
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const SmallWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const PageWrap = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const SvgWrap = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 auto 20px;
  position: relative;
`;

const movedash = keyframes`
  to {
    stroke-dashoffset: 250%;
  }
`;

export const Svg = styled.svg`
  polygon {
    fill: none;
    stroke-width: 8px;
    stroke: ${({ theme }) => theme.color.blue[500]};
    stroke-dasharray: 40;
    stroke-dashoffset: 50%;
    animation: ${movedash} 1.5s forwards 0s infinite;
  }
`;

export const Logo = styled.svg`
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Text = styled.p`
  margin-top: 2px;
  color: ${({ theme }) => theme.color.gray[900]};
  ${({ theme }) => theme.font.body3.regular}
`;

import styled from 'styled-components';

export const Header = styled.div`
  padding: 24px 32px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};

  > h1 {
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body2.bold};
  }
`;

export const Content = styled.div`
  padding: 32px 32px 0;
`;

export const Desc = styled.div`
  > h1 {
    ${({ theme }) => theme.font.body3.medium};    
    color: ${({ theme }) => theme.color.blue[500]};
  }

  > div {
    margin-top: 12px;

    > p {
      display: grid;
      grid-template-columns: 8px minmax(0, 1fr);
      ${({ theme }) => theme.font.caption1.regular};
      color: ${({ theme }) => theme.color.gray[700]};

      &:before {
        content: '-';
      }
    }
  }
`;

export const ListGroup = styled.ul`
  margin-top: 32px;
  border-top: 2px solid ${({ theme }) => theme.color.gray[900]};

  > li {
    padding: 20px 20px 20px 0px;

    & + li {
      border-top: 1px solid ${({ theme }) => theme.color.gray[200]};
    }
  }
`;

export const CheckGroup = styled.div`
  display: grid;
  grid-template-columns: 102px minmax(0, 1fr) 24px;
  align-items: center;

  > h1 {
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body3.bold};
  }

  > p {
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body3.regular};
    padding-right: 20px;
    word-break: keep-all;
  }
`;

export const TalkSettingWrap = styled.div`
  margin-top: 12px;
  padding-left: 102px;

  > h1 {
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body3.bold};
  }

  > p {
    margin: 4px 0 8px;
    color: ${({ theme }) => theme.color.gray[700]};
    ${({ theme }) => theme.font.caption1.regular};
  }
`;

export const RepresentNumber = styled.p`
  ${({ theme }) => theme.font.body3.regular};
  color: ${({ theme }) => theme.color.gray[900]};

  > span {
    color: ${({ theme }) => theme.color.red[500]};
  }
`;

export const BtnWrap = styled.div`
  padding: 32px 0 40px;
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.color.gray[200]};

  > button + button {
    margin-left: 8px;
  }
`;

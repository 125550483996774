import { createModel } from '@rematch/core';
import { push } from 'connected-react-router';
import qs from '~/utils/query-string';

import { GONGSIL_LIST_TAB } from '~/enum/tab';

import state from './state';

const gongsil = createModel({
  state: state,
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: dispatch => ({
    updateUrl(newSearch, { router }) {
      const { pathname, query } = router.location;
      const search = { ...query, ...newSearch };

      this.update({ search });

      dispatch(push({
        pathname,
        search: qs.stringify({
          ...search,
        }),
      }));
    },

    /**
     * @description gongsil-listCounts 조회
     * 최초 1번만 실행 [대시보드, 헤더]에서 넘어오는 경우 listCounts.pickCount 값이 있으면 기본 탭을 지정매물 탭으로 고정
     */
    async getCounts(tab, { call: { get }, toast: { error } }) {
      let tempTab = tab;
      let tabs = [...GONGSIL_LIST_TAB];

      try {
        const result  = await get('/api/v2/gongsil-center/gongsil-count');
        tempTab = !tempTab && result.pickCount > 0 ? 'pick' : tempTab || 'all';

        if (result.pickCount <= 0) {
          tabs.splice(0, 1);
        }

        this.update({
          listCounts: result,
          tab: tempTab,
          tabs,
        });

      } catch(err) {
        console.log(err);
        error(err?.msg ?? '서버에러가 발생하였습니다.');
      }

      return tempTab;
    },

    async getGongsil(params, { router, call: { get }, gongsil: { limit }, toast: { error } }) {
      const tab = params?.tab ?? '';
      
      if (!tab) {
        return false;
      }

      const { query } = router.location;

      this.update({ isLoading: true });

      try {
        const apiUrl = tab ? `${tab}/list` : 'all/list';
        const newParams = {
          page: 1,
          ...query,
          ...params,
          limit,
        };

        const result  = await get(`/api/v2/gongsil-center/${apiUrl}`, { ...newParams, limit });

        await this.update({
          items: result.items,
          page: result.page,
          limit: result.limit,
          total: result.total,
          tab,
        });

      } catch(err) {
        console.log(err);
        error(err?.msg ?? '서버에러가 발생하였습니다.');
      }

      this.update({ isLoading: false });
    },
  }),
});

export default gongsil;

interface ListItem {
  type: Common.TContactMethodType;
  value: Common.TContactMethod;
}

const list: ListItem[] = [
  {
    type: 'CALL',
    value: 1,
  },
  {
    type: 'SMS',
    value: 2,
  },
  {
    type: 'CHAT_TALK',
    value: 3,
  },
];

export function makeContactMethod(methods: Common.TContactMethodType[] | Common.TContactMethod[]) {
  const result = [...methods].reduce((r, i) => {
    if (typeof i === 'number') {
      const item = list.find(d => d.value === i);

      if (item) {
        r.push(item);
      }

    } else if (typeof i === 'string') {
      const item = list.find(d => d.type === i);

      if (item) {
        r.push(item);
      }
    }

    return r;
  }, [] as ListItem[]).sort((a, b) => a.value - b.value);

  return {
    numberMethods: result.map(i => i.value),
    typeMethods: result.map(i => i.type),
  };
}

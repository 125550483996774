import { createModel } from '@rematch/core';
import { produce } from 'immer';
import { push } from 'connected-react-router';

import qs from '~/utils/query-string';
import isReport from '~/utils/valid/is-report';

import state from './state';

const roomList = createModel({
  state: state,
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },

  effects: dispatch => ({
    // 단일 매물 업데이트
    updateItem({ idx, nextItem }, { roomList: { items } }) {
      const newItems = produce(items, draft => {
        draft[idx] = nextItem;
      });

      this.update({ items: newItems });
    },

    // 매물 리스트 업데이트
    updateItems({ newItems }) {
      this.update({ items: newItems });
    },

    updateUrl(newSearch, { router, roomList: { search: roomSearch } }) {
      const { pathname, query } = router.location;
      const search = { ...query, ...roomSearch, ...newSearch };
      delete search.roomSet;

      dispatch(push({
        pathname,
        search: qs.stringify({
          ...search,
        }, { arrayFormat: 'comma', skipEmptyString: true }),
      }));
    },

    // 매물 리스트 조회
    async getRooms(search, { call, roomList: { search: roomSearch, limit }, toast: { error } }) {
      const { get } = call;
      const params = { ...roomSearch, ...search };

      try {
        this.update({ isLoading: true });

        const result = await get('/api/v2/room/list', {
          ...params,
          limit
        });

        await this.update({
          items: result.rooms,
          total: result.total,
          page: result.page,
          limit: result.limit,
          counts: result.counts,
          isShowSelector: false,
          checkAll: false,
          selectRooms: [],
          isLoading: false,
          isFirstLoading: false,
        });
      } catch (err) {
        console.log(err);
        error(err?.msg ?? '서버에러가 발생하였습니다.');
        this.update({ isLoading: false });
      }

    },

    // 플러스 노출 전체 선택
    selectQuickRoomAll({ name, checked }, { roomList }) {
      if (checked) {
        let temp = [];

        if (name === 'quick') {
          temp = roomList.items.reduce((r, i) => {
            if (i.status === 1 && !i.isQuick && !isReport(i.isReportMask, i.roomReportStep)) {
              r.push({ ...i });
            }
            return r;
          }, []);

        } else if (name === 'move') {
          temp = roomList.items.reduce((r, i) => {
            if (isReport(i.isReportMask, i.roomReportStep)) return r;
            if (i.isQuick) return r;
            if (i.isOwner) return r;

            r.push({ ...i });
            return r;
          }, []);
        }

        this.update({
          checkAll: true,
          selectRooms: temp,
        });

      } else {
        this.update({
          checkAll: false,
          selectRooms: [],
        });
      }
    },

    // 플러스 노출 선택
    selectQuickRoom(item, { roomList: { selectRooms, items } }) {
      let tempRooms = [...selectRooms]; // 배열 복사
      const checkAbleItems = items.filter(item =>
        !item.isQuick && !item.isOwner && !isReport(item.isReportMask, item.roomReportStep)
      ); // 체크 가능한 매물
      const idx = selectRooms.findIndex(room => room.id === item.id);
      const hasIdx = idx !== -1;

      if (hasIdx) {
        tempRooms.splice(idx, 1);
      } else {
        tempRooms = [...selectRooms, item];
      }

      this.update({
        checkAll: checkAbleItems.length === tempRooms.length ? true : false,
        selectRooms: tempRooms,
      });
    },
  }),
});

export default roomList;

import React from 'react';

import useSignPagination from '~/hooks/useSignPagination';

import scrollToTop from '~/utils/scrollToTop';
import SvgIcon from '~/components/svg-icon';

import { PaginWrap, PageList, PrevBtn, NextBtn, NumBtn } from './styled';

interface Props {
  page: number;
  total: number;
  limit: number;
  isLoading: boolean;
  maxButtons?: number;
  marginTop?: string;
  marginBottom?: string;
  isScrollToTop?: boolean;
  onChange(page: number): void;
}

function PaginateComponent({
  page, total, limit,
  isLoading,
  isScrollToTop = false,
  maxButtons,
  marginTop, marginBottom,
  onChange,
}: Props) {
  const { activePage, visiblePieces, goToPage } = useSignPagination({
    initialPage: page,
    numberOfPages: Math.ceil(total / limit),
    maxButtons,
  });

  return (
    <PaginWrap marginTop={marginTop} marginBottom={marginBottom}>
      <PageList>
        {visiblePieces.map(page => {
          if (total <= 0) return null;
          const { type, pageNumber, isDisabled } = page;
          const isActive = activePage === pageNumber;

          const onClickPage = () => {
            if (isLoading || isActive) return;
            onChange(pageNumber);
            goToPage(pageNumber);
            isScrollToTop && scrollToTop();
          };

          if (type === 'first') {
            return (
              <li key="first">
                <PrevBtn onClick={onClickPage} disabled={isDisabled}>
                  <SvgIcon
                    name={isDisabled ? 'ic_24_chevron_double_left_gray400' : 'ic_24_chevron_double_left_gray900'}
                  />
                </PrevBtn>
              </li>
            );
          } else if (type === 'previous') {
            return (
              <li key="previous">
                <PrevBtn onClick={onClickPage} disabled={isDisabled}>
                  <SvgIcon
                    name={isDisabled ? 'ic_24_chevron_left_gray400' : 'ic_24_chevron_left_gray900'}
                  />
                </PrevBtn>
              </li>
            );
          } else if (type === 'next') {
            return (
              <li key="next">
                <NextBtn onClick={onClickPage} disabled={isDisabled}>
                  <SvgIcon
                    name={isDisabled ? 'ic_24_chevron_right_gray400' : 'ic_24_chevron_right_gray900'}
                  />
                </NextBtn>
              </li>
            );
          } else if (type === 'last') {
            return (
              <li key="last">
                <NextBtn onClick={onClickPage} disabled={isDisabled}>
                  <SvgIcon
                    name={isDisabled ? 'ic_24_chevron_double_right_gray400' : 'ic_24_chevron_double_right_gray900'}
                  />
                </NextBtn>
              </li>
            );
          } else {
            return (
              <li key={pageNumber} className="pageNum">
                <NumBtn isActive={isActive} onClick={onClickPage} disabled={isDisabled}>
                  {pageNumber}
                </NumBtn>
              </li>
            );
          }
        })}
      </PageList>
    </PaginWrap>
  );
}

export default PaginateComponent;

import React from 'react';

import Modal from '~/components/modal';

import useRoleType from './useRoleType';

import { Header } from '../styled';
import { Wrap, FormContent, Select, Desc, BtnWrap, SubmitBtn } from './styled';

function RoleTypePopup({ resetType }) {
  const { typeList, roleType, isLoading, changeType, submit } = useRoleType(resetType);

  return (
    <Modal minWidth={480} hideClose={true} escToClose={false}>
      <Header>
        <h1>회원정보</h1>
      </Header>
      <Wrap>
        <FormContent>
          <h2>권한/자격 선택</h2>
          <div>
            <Select name="roleType" value={roleType} onChange={changeType}>
              <option value="">선택</option>
              {typeList.map(t => <option key={t.value} value={t.value}>{t.label}</option>)}
            </Select>
          </div>
        </FormContent>

        <Desc>
          <p>•</p>
          <p>권한 및 자격을 잘못 선택할 경우 등록한 담당자에게 책임이 있음을 알려드립니다.</p>
        </Desc>
      </Wrap>

      <BtnWrap>
        <SubmitBtn onClick={submit} disabled={roleType === '' || isLoading}>
          등록하기
        </SubmitBtn>
      </BtnWrap>
    </Modal>
  );
}

export default RoleTypePopup;

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import MenuButton from '../menu-button';

import { Wrap } from './styled';

function IntroduceComponent({ close }: { close: () => void }) {
  const { isAuth } = useSelector(({ user }: any) => user);

  const history = useHistory();

  const clickLink = useCallback((to: string) => {
    if (history.location.pathname === to) return;

    history.push(to);
    close();
  }, []);

  return (
    <Wrap cellWidth="240px">
      <li>
        <p className="title">다방프로</p>

        <MenuButton
          text="다방프로소개"
          isDisappear={isAuth}
          onClick={clickLink.bind(null, '/')}
        />
        <MenuButton
          text="다방싸인 비대면 계약"
          isDisappear={false}
          onClick={clickLink.bind(null, '/intro/sign')}
        />
        <MenuButton
          text="공실센터"
          isDisappear={false}
          onClick={clickLink.bind(null, '/intro/gongsil')}
        />
        <MenuButton
          text="다방"
          isDisappear={false}
          onClick={clickLink.bind(null, '/intro/dabang')}
        />
        <MenuButton
          text="업무관리"
          isDisappear={false}
          iconName="ic_chip_new"
          onClick={clickLink.bind(null, '/intro/agent-tool')}
        />
      </li>

      <li>
        <p className="title">광고상품</p>

        <MenuButton
          text="광고 가이드"
          isDisappear={false}
          onClick={clickLink.bind(null, '/intro/product/guide')}
        />

        <MenuButton
          text="동 추천 상품"
          isDisappear={false}
          iconName="ic_chip_new"
          onClick={clickLink.bind(null, '/intro/product/region-recommend')}
        />

        <MenuButton
          text="N쿠폰 상품"
          isDisappear={false}
          iconName="ic_chip_new"
          onClick={clickLink.bind(null, '/intro/product/naver')}
        />

        <MenuButton
          text="아이콘 포커스 상품"
          isDisappear={false}
          iconName="ic_chip_new"
          onClick={clickLink.bind(null, '/intro/product/icon-focus')}
        />
      </li>

      <li>
        <p className="title" />

        <MenuButton
          text="패키지 상품"
          isDisappear={false}
          onClick={clickLink.bind(null, '/intro/product/package')}
        />

        <MenuButton
          text="일반 상품"
          isDisappear={false}
          onClick={clickLink.bind(null, '/intro/product/general')}
        />

        <MenuButton
          text="플러스 상품"
          isDisappear={false}
          onClick={clickLink.bind(null, '/intro/product/plus')}
        />
      </li>

      <li>
        <p className="title" />

        <MenuButton
          text="프리미엄 상품"
          isDisappear={false}
          onClick={clickLink.bind(null, '/intro/product/premium')}
        />

        <MenuButton
          text="단지 상품"
          isDisappear={false}
          onClick={clickLink.bind(null, '/intro/product/complex')}
        />

        <MenuButton
          text="다방싸인 상품"
          isDisappear={false}
          onClick={clickLink.bind(null, '/intro/product/sign')}
        />
      </li>

      <li>
        <p className="title">고객센터</p>

        <MenuButton
          text="공지사항"
          isDisappear={false}
          onClick={clickLink.bind(null, '/service/notice')}
        />
        <MenuButton
          text="자주 묻는 질문"
          isDisappear={false}
          onClick={clickLink.bind(null, '/service/faq')}
        />
        <MenuButton
          text="주요 안내"
          isDisappear={false}
          onClick={clickLink.bind(null, '/service')}
        />
      </li>
    </Wrap>
  );
}

export default IntroduceComponent;

import React, { forwardRef, ChangeEvent, useMemo } from 'react';

import ICON_FOCUS_TYPE_LIST from '~/enum/form/icon-focus-type-list';

import type { RadioProps } from './types';

import Icon from 'web~/components/product/icon-focus/icon';

import { IconFocusLabel, Radio } from './styled';

export const IconFocusRadioUIComponent = forwardRef<HTMLInputElement, RadioProps>(({
  onChange,
  onChangeNative,
  children,
  disabled,
  style,
  checked,
  value,
  ...props
}, ref) => {
  const icon = useMemo(() => ICON_FOCUS_TYPE_LIST.find(item => item.type === value), [])!;

  function change(e: ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;
    onChangeNative?.(e);
    onChange?.({ name, value });
  }

  return (
    <IconFocusLabel $isChecked={checked ?? false} style={style}>
      <Icon iconFocusType={value as IconFocus.TIconFocusType} />
      <p>{icon.name}</p>

      <Radio
        type="radio"
        ref={ref}
        value={value}
        checked={checked}
        onChange={change}
        disabled={disabled}
        {...props}
      />
    </IconFocusLabel>
  );
});

IconFocusRadioUIComponent.displayName = 'IconFocusRadio';

import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import isEmpty from '~/utils/valid/isEmpty';

import InquirySettingPopup from 'web~/components/popup/inquiry-setting';
import RoleTypePopup from '../roleType';
import NoticePopup from '../notice';
import VerifyTel from '../verifyTel';
import NoItem from '../noItem';
import ImageNoticePopup from '../imageNotice';

function PopupHub() {
  const location = useLocation();

  const [type, setType] = useState(''); // business, roleType, notice, noItem, inquirySetting, signNotice
  const [prevLocation, setLocation] = useState(location);

  const [isCloseNotice, setCloseNotice] = useState(false);
  const [isCloseVerifyTel, setCloseVerifyTel] = useState(false);
  const [isCloseNoItem, setCloseNoItem] = useState(false);
  const [isCloseImageNotice, setCloseImageNotice] = useState(false);

  const {
    isAuth, isMaster, isCeoSafeAuth,
    useItem, useVip, usePremium, isServeUser,
    userRoleType, isShowSetContactMethod,
    contactMethodTypes, chatContactMessage,
  } = useSelector(({ user }) => user);

  const noItem = useMemo(() => {
    return !useItem && !useVip && !usePremium && !isServeUser;
  }, [useItem, useVip, usePremium, isServeUser]);

  useEffect(() => {
    if (prevLocation.pathname !== location.pathname) {
      setLocation(location);

      // pathname이 변경될때 팝업닫기 셋팅 초기화
      setCloseNotice(false);
      setCloseVerifyTel(false);
      setCloseNoItem(false);
      setCloseImageNotice(false);

      setType('');
    }
  }, [location.pathname]);

  useEffect(() => {
    setModalType();
  }, [
    type, isAuth,
    isMaster,
    userRoleType, isShowSetContactMethod,
    isCloseNotice, isCloseVerifyTel, isCloseNoItem, isCeoSafeAuth, isCloseImageNotice,
    location.pathname,
  ]);

  async function setModalType() {
    if (type === '' && isAuth) {
      if (!isCloseNotice && !isCloseImageNotice && location.pathname === '/dashboard') {
        setType('notice');

      } else if (isEmpty(userRoleType)) {
        setType('roleType');

      } else if (isShowSetContactMethod && isCeoSafeAuth) {
        setType('inquirySetting');

      } else if (!isCloseImageNotice && location.pathname === '/dashboard') {
        setType('imageNotice');

      } else if (!isCloseVerifyTel && isCloseNotice && isCloseImageNotice && location.pathname === '/dashboard') {
        setType('verifyTel');

      } else if (isMaster && !isCloseNoItem && isCloseNotice && isCloseVerifyTel && isCloseImageNotice && noItem && location.pathname === '/dashboard') {
        setType('noItem');
      }
    }
  }

  function resetType({
    isCloseNotice,
    isCloseVerifyTel,
    isCloseNoItem,
    isCloseImageNotice,
  } = {}) {
    if (isCloseNotice) {
      setCloseNotice(true);

    } else if (isCloseVerifyTel) {
      setCloseVerifyTel(true);

    } else if (isCloseNoItem) {
      setCloseNoItem(true);

    } else if (isCloseImageNotice) {
      setCloseImageNotice(true);
    }

    setType('');
  }

  if (type === 'notice') {
    return <NoticePopup resetType={resetType} />;

  } else if (type === 'roleType') {
    return <RoleTypePopup resetType={resetType} />;

  } else if (type === 'verifyTel') {
    return <VerifyTel resetType={resetType} />;

  } else if (type === 'noItem') {
    return <NoItem resetType={resetType} />;

  } else if (type === 'imageNotice') {
    return <ImageNoticePopup resetType={resetType} />;

  } else if (type === 'inquirySetting') {
    return (
      <InquirySettingPopup
        contactMethodTypes={contactMethodTypes}
        chatContactMessage={chatContactMessage}
        hideCloseBtn
        closePopup={resetType}
      />
    );
  }

  return null;
}

export default PopupHub;

import { css } from 'styled-components';

import type { TFontSize, TFontWeight } from './types';

export function getBtnSize(size: TFontSize, fontWeight: TFontWeight = 'bold') {
  switch (size) {
    case 'sm':
      return css`
        height: 28px;
        ${({ theme }) => theme.font.caption1[fontWeight]};
      `;

    case 'md':
      return css`
        height: 36px;
        ${({ theme }) => theme.font.body3[fontWeight]};
      `;

    case 'lg':
      return css`
        height: 44px;
        ${({ theme }) => theme.font.body3[fontWeight]};
      `;

    case 'xl':
      return css`
        height: 56px;
        ${({ theme }) => theme.font.body3[fontWeight]};
      `;
  }

  return '';
}

import styled from 'styled-components';

export const Header = styled.header`
  padding: 58px 40px 38px;

  > h1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${({ theme }) => theme.grey22.getColor()};
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    
    > svg {
      margin-right: 8px;
    }
  }
`;

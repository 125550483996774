import React, { ReactNode, useState, useCallback ,useMemo } from 'react';

import generateUUID from '~/utils/generateUUID';

import useReportList, { ReportList, PenaltyList } from './useReportList';

import { LineBtn, TextBtn } from '~/atoms2';
import Modal from '~/components/modal';
import Scrollbar from '~/components/scrollbar2/container';
import Loading from '~/components/loading/small';
import SvgIcon from '~/components/svg-icon';
import Pagination from 'web~/components/sign/pagination';

import {
  PopupButton,
  Header, TabWrap, Tab, Body, BtnWrap,
  TextWrap, Text,
  InfoList,
  List, ListScrollBody, ListHeader, ListRow, ListText,
  Box,
} from './styled';

interface Props {
  initTab?: 'PROCESSING_DETAILS' | 'PENALTY';
  children: ReactNode | ReactNode[];
}

interface ContainerProps {
  initTab?: 'PROCESSING_DETAILS' | 'PENALTY';
  close: () => void;
}

export function ReportListComponent({ initTab = 'PROCESSING_DETAILS', children }: Props) {
  const [isOpen, setOpen] = useState(false);
  const handler = useCallback(() => setOpen(prev => !prev), []);

  return (
    <>
      <PopupButton onClick={handler}>
        {children}
      </PopupButton>

      {isOpen && (
        <Modal
          minWidth={840}
          handle={handler}
          hideClose
          escToClose={false}
        >
          <ReportListContainerComponent initTab={initTab} close={handler} />
        </Modal>
      )}
    </>
  );
}

export function ReportListContainerComponent({ initTab = 'PROCESSING_DETAILS', close }: ContainerProps) {
  const {
    state: {
      isLoading,
      tab, page, total, limit,
      reportList, penaltyList,
    },
    changeTab,
    changePage,
  } = useReportList(initTab);
  const isProcessing = useMemo(() => tab === 'PROCESSING_DETAILS', [tab]);
  const isEmpty = useMemo(() => reportList.length === 0 && penaltyList.length === 0, [reportList, penaltyList]);
  const height = useMemo(() => !isProcessing && penaltyList.length * 65 > 384 ? 384 : penaltyList.length * 65,[isProcessing, penaltyList]);

  return (
    <>
      <Header>
        <h1>허위매물 신고 내역</h1>
      </Header>

      <Body>
        <TabWrap>
          <Tab
            isActive={isProcessing}
            onClick={changeTab.bind(null, 'PROCESSING_DETAILS')}
          >
            신고 처리내역
          </Tab>
          <Tab
            isActive={!isProcessing}
            onClick={changeTab.bind(null, 'PENALTY')}
          >
            페널티 현황
          </Tab>
        </TabWrap>

        <InfoList>
          {isProcessing ? (
            <>
              <p>다방을 통해 등록한 매물의 모든 경고, 페널티 내역을 확인할 수 있습니다.</p>
  
              <li>광고종료(허위매물)처리시, 경고 1회가 부과되며, 경고 3회 누적 시 7일간 매물 등록이 제한 됩니다.</li>
              <li>정상매물 처리 후 전화검증을 통해 허위매물로 확인되는 경우, 즉시 7일간 매물 등록 제한되며, 현장검증을 통해 허위매물로 확인되는 경우, 즉시 14일간 매물 등록이 제한 됩니다.</li>
              <li>허위매물 관련한 문의사항은 부동산매물클린관리센터(1600-7186)로 부탁 드립니다.</li>
              <li>이의 신청은 자율처리 후 2주 이내에 접수해야하며, clean@thedabang.com 메일로 양식과 함께 회신 주시면 됩니다.</li>
            </>
          ) : (
            <>
              <p>부동산매물클린관리센터에서 받은 모든 경고, 페널티 내역을 확인할 수 있습니다.</p>

              <li>부동산매물클린관리센터 참여사의 허위매물은 통합 관리되며, 경고&페널티는 사업자 기준으로 부과 됩니다.</li>
              <li>광고종료(허위매물)처리시, 경고 1회가 부과되며, 경고 3회 누적 시 7일간 매물 등록이 제한 됩니다.</li>
              <li>정상매물 처리 후 전화검증을 통해 허위매물로 확인되는 경우, 즉시 7일간 매물 등록 제한되며, 현장검증을 통해 허위매물로 확인되는 경우, 즉시 14일간 매물 등록이 제한 됩니다.</li>
              <li>허위매물 관련한 문의사항은 부동산매물클린관리센터(1600-7186)로 부탁 드립니다.</li>
              <li>이의 신청은 자율처리 후 2주 이내에 접수해야하며, clean@thedabang.com 메일로 양식과 함께 회신 주시면 됩니다.</li>
            </>
          )}

          <TextBtn
            btnColor="blue"
            btnSize="sm"
            style={{ marginLeft: '7px' }}
            onClick={() => window.location.href='https://static.dabangapp.com/kiso/%E1%84%8B%E1%85%B5%E1%84%8B%E1%85%B4%E1%84%89%E1%85%B5%E1%86%AB%E1%84%8E%E1%85%A5%E1%86%BC%E1%84%89%E1%85%A5_%E1%84%8B%E1%85%A3%E1%86%BC%E1%84%89%E1%85%B5%E1%86%A8.docx'}
          >
            이의신청서 다운받기
          </TextBtn>
        </InfoList>

        {(!isEmpty && !isLoading) && isProcessing && (
          <TextWrap style={{ marginTop: '24px' }}>
            <Text className="right">총 <span>{total}</span>개</Text>
          </TextWrap>
        )}

        <List>
          {isLoading ? (
            <Box>
              <Loading />
            </Box>
          ) : isEmpty ? (
            <Box>
              <SvgIcon name="ic_48_exclamation_mark_gray900" />
              <p>내역이 없습니다.</p>
            </Box>
          ) : isProcessing ? (
            <>
              <ListHeader>
                <ListText className="dabang-seq">매물번호</ListText>
                <ListText className="dabang-inflowType">신고접수</ListText>
                <ListText className="dabang-result">처리결과</ListText>
                <ListText className="dabnag-warn">경고</ListText>
                <ListText className="dabang-penalty">페널티</ListText>
              </ListHeader>

              {reportList && reportList.length > 0 && reportList.map((report: ReportList)  => {
                return (
                  <ListRow key={generateUUID()}>
                    <ListText className="dabang-seq">{report.roomsSeq}</ListText>
                    <ListText className="dabang-inflowType">{report.inflowType === 'DABANG' ? '다방' : '네이버'}</ListText>
                    <ListText className={`dabang-result ${report.reportStep === 'FAKE_ROOM' ? 'red' : 'blue'}`}>{report.reportStep === 'FAKE_ROOM' ? '허위매물' : '정상매물'}</ListText>
                    <ListText className="dabnag-warn">{report?.warning || '-'}</ListText>
                    <ListText className="dabang-penalty">{report?.penalty || '-'}</ListText>
                  </ListRow>
                );
              })}
            </>
          ) : (
            <>
              <ListHeader>
                <ListText className="kiso-seq">매물번호</ListText>
                <ListText className="kiso-cp">참여사</ListText>
                <ListText className="kiso-time">경고일시</ListText>
                <ListText className="kiso-penalty">페널티</ListText>
              </ListHeader>

              <ListScrollBody height={height}>
                <Scrollbar id="penalty-list">
                  {penaltyList && penaltyList.length > 0 && penaltyList.map((report: PenaltyList)  => {
                    return (
                      <ListRow key={generateUUID()}>
                        <ListText className="kiso-seq">{report.cpArticleNo || '-'}</ListText>
                        <ListText className="kiso-cp">
                          <span>{report.cpName || '-'}</span>
                        </ListText>
                        <ListText className="kiso-time">{report?.warningDate ?? '-'}</ListText>
                        <ListText className="kiso-penalty">{report?.penalty ?? '-'}</ListText>
                      </ListRow>
                    );
                  })}
                </Scrollbar>
              </ListScrollBody>
            </>
          )}
        </List>

        {total > limit && (
          <Pagination
            isLoading={isLoading}
            isScrollToTop
            page={page}
            limit={limit}
            total={total}
            maxButtons={5}
            onChange={page => changePage(page)}
            marginTop="32px"
          />
        )}

        {!isLoading && (
          <BtnWrap>
            <LineBtn
              btnSize="xl"
              btnColor="gray"
              contentWidth="120px"
              onClick={close}
            >
              닫기
            </LineBtn>
          </BtnWrap>
        )}
      </Body>
    </>
  );
}

import styled from 'styled-components';

export const TextWrap = styled.div<{
  isError: boolean;
}>`
  padding-top: 8px;
  
  > p {
    color: ${({ isError, theme }) => isError ? theme.color.red[500] : theme.color.gray[800]};
    ${({ theme }) => theme.font.caption1.regular};
  }
`;

import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
  
  > h1  {
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body2.bold};
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 40px;
`;

export const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  button + button {
    margin-left: 8px;
  }
`;

export const Text = styled.p`
  &.body3 {
    color: ${({ theme }) => theme.color.gray[700]};
    ${({ theme }) => theme.font.body3.regular};
  }

  &.body3-bold {
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body3.bold};
  }

  &.caption1 {
    color: ${({ theme }) => theme.color.gray[700]};
    ${({ theme }) => theme.font.caption1.regular};
  }

  &.red {
    color: ${({ theme }) => theme.color.red[500]};
    ${({ theme }) => theme.font.body3.regular};
  }
`;

export const TextWrap = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 8px 8px 8px 40px;
  background-color: ${({ theme }) => theme.color.gray[50]};
  position: relative;

  > svg {
    position: absolute;
    top: 8px;
    left: 8px;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;

  > li {
    flex: none;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      display: block;
      width: 4px;
      height:4px;
      margin-bottom: 2px;
      margin-right: 8px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.red[500]};
    }
  }
`;

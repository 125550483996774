import React, { useState, ReactNode } from 'react';

import Modal from '~/components/modal';
import SvgIcon from '~/components/svg-icon';
import { LineBtn } from '~/atoms2';

import { PopupButton, Header, Body, TextWrap, Text, BtnWrap } from './styled';

interface Props {
  children: ReactNode | ReactNode[];
}

export function RestrictionsComponent({ children }: Props) {
  const [isOpen, setOpen] = useState(false);

  function handler() {
    setOpen(prev => !prev);
  }

  return (
    <>
      <PopupButton onClick={handler}>
        {children}
      </PopupButton>

      {isOpen && (
        <Modal
          minWidth={520}
          handle={handler}
          hideClose
        >
          <Header>
            <h1>제한조치 안내</h1>
          </Header>

          <Body>
            <Text className="body3">다방에 게재된 매물은 한국인터넷자율정책기구 내 부동산매물클린관리센터의</Text>
            <Text className="body3">운영규정을 따르며 제한조치 사항은 아래와 같습니다.</Text>

            <TextWrap>
              <SvgIcon name="ic_24_exclamation_mark_gray_solid" />
              <Text className="caption1"><b>&middot;</b> &nbsp; 경고 3회 7일간 매물 게재 제한</Text>
              <Text className="caption1"><b>&middot;</b> &nbsp; 전화검증을 통한 금지매물 확인 7일간 매물 게재 제한</Text>
              <Text className="caption1"><b>&middot;</b> &nbsp; 현장검증을 통한 금지매물 확인 14일간 매물 게재 제한</Text>
            </TextWrap>

            <BtnWrap>
              <LineBtn
                btnSize="xl"
                btnColor="gray"
                contentWidth="120px"
                onClick={handler}
              >
                닫기
              </LineBtn>
            </BtnWrap>
          </Body>
        </Modal>
      )}
    </>
  );
}

import styled from 'styled-components';

export const Drowndown = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 120px;
  position: relative;
  user-select: none;

  > button {
    flex: none;
    display: flex;
    align-items: center;
    padding: 8px 0 8px 16px;
    border: 0;
    color: ${({ theme }) => theme.color.gray[600]};
    ${({ theme }) => theme.font.body3.regular};
    background-color: transparent;

    > p {
      flex: none;
      width: 64px;
      text-align: right;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    > span {
      flex: none;
      margin-left: 4px;
    }

    > svg {
      flex: none;
      margin-left: 4px;
    }
  }
`;

export const DropdownMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 18px 20px;
  width: 140px;
  border: 1px solid ${({ theme }) => theme.color.gray[400]};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 501;

  > li {
    flex: none;
    width: 100%;

    > a, button {
      ${({ theme }) => theme.font.body3.regular};
      color: ${({ theme }) => theme.color.gray[600]};
      border: 0;
      padding: 0;
    }
  }

  li + li {
    margin-top: 4px;
  }
`;

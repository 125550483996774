import React, { forwardRef } from 'react';

import type { LinkProps } from '../types';

import { TextLink, Text } from './styled';

export const TextLinkUIComponent = forwardRef<HTMLAnchorElement, LinkProps>(({
  contentWidth = 'auto',
  leftIcon,
  rightIcon,
  btnColor,
  btnSize,
  fontWeight,
  children,
  ...props
}, ref) => {
  return (
    <TextLink
      ref={ref}
      contentWidth={contentWidth}
      btnColor={btnColor}
      btnSize={btnSize}
      fontWeight={fontWeight}
      {...props}
    >
      <div>
        {leftIcon}

        <Text
          hasLeftIcon={!!leftIcon}
          hasRightIcon={!!rightIcon}
        >
          {children}
        </Text>

        {rightIcon}
      </div>
    </TextLink>
  );
});

TextLinkUIComponent.displayName = 'TextLink';

export const ContainerTextLinkUIComponent = forwardRef<HTMLAnchorElement, LinkProps>(({
  contentWidth = 'auto',
  children,
  style,
  ...props
}, ref) => {
  return (
    <TextLink
      ref={ref}
      contentWidth={contentWidth}
      style={{ padding: '0 16px', ...style }}
      {...props}
    >
      {children}
    </TextLink>
  );
});

ContainerTextLinkUIComponent.displayName = 'ContainerTextLink';

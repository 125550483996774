import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoute({ children, ...rest }) {
  const { isAuth, isLoading } = useSelector(({ user }) => user);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isLoading) return null;
        if (isAuth) return children;

        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location, toast: '로그인이 필요한 서비스입니다.' },
            }}
          />
        );
      }}
    />
  );
}

export default PrivateRoute;

import { useState, useEffect } from 'react';

import useApiCall from '~/hooks/useApiCall';

import { useToast } from '~/components/toast';

interface State {
  reportList: ReportList[];
  penaltyList: PenaltyList[];
  totalStr: string;
  total: number;
  page: number;
  limit: number;
  isLoading: boolean;
  tab: 'PROCESSING_DETAILS' | 'PENALTY';
}

export interface ReportList {
  /** 매물번호 */
  roomsSeq: number;
  /** 신고 유입 유형 */
  inflowType: 'DABANG' | 'NAVER';
  /** 신고 처리 상태
   * NOMAL: 정상매물
   * FAKE_ROOM: 허위매물
  */
  reportStep: 'NOMAL' | 'FAKE_ROOM';
  /** 경고여부 */
  warning: 'Y' | 'N';
  /** 패널티 내용 */
  penalty?: string;
}

export interface PenaltyList {
  /** 참여사명 */
  cpName: string;
  /** 참여사 매물번호 */
  cpArticleNo: string;
  /** 신고일시 */
  reportDate?: string;
  /** 경고부여일시 */
  warningDate?: string;
  /** 경고부여사유 */
  reason?: string;
  /** 부여된패널티 */
  penalty?: string;
  /** 패널티시작일 */
  penaltyStartDate?: string;
  /** 패널티종료일 */
  penaltyEndDate?: string;
  /** 타입 */
  type: 'WARN' | 'PENALTY';
}

const INIT_STATE: State = {
  reportList: [],
  penaltyList: [],
  totalStr: '',
  total: 0,
  page: 1,
  limit: 10,
  isLoading: false,
  tab: 'PROCESSING_DETAILS',
};

function useReportList(initTab: 'PROCESSING_DETAILS' | 'PENALTY') {
  const [state, setState] = useState<State>({
    ...INIT_STATE,
    tab: initTab,
  });

  const { get } = useApiCall();
  const toast = useToast();

  useEffect(() => {
    setState(prev => ({ ...prev, isLoading: true }));
  }, []);

  useEffect(() => {
    if (state.isLoading) getList();
  }, [state.isLoading]);

  async function getList() {
    const isProcessing = state.tab === 'PROCESSING_DETAILS';

    const reset = !isProcessing ?
      {
        hasMore: false,
        limit: 10,
        offset: 0,
        page: 1,
        reportList: [],
        total: 0,
        totalStr: '0',
      }
      : {
        penaltyList: [],
      };

    try {
      const url = isProcessing ? '/api/v2/kiso-report/agent-treat/complete' : '/api/v2/kiso-report/penalty';
      const params = isProcessing ? { page: state.page, limit: 5 } : {};

      const res = await get(url, params);

      setState(prev => ({
        ...prev,
        ...reset,
        ...res,
        isLoading: false
      }));

    } catch(err) {
      console.log(err);
      toast.error(err?.msg ?? '서버에러가 발생했습니다.');
      setState(prev => ({ ...prev, ...reset, isLoading: false }));
    }
  }

  function changeTab(tab: 'PROCESSING_DETAILS' | 'PENALTY') {
    setState(prev => ({
      ...prev,
      page: 1,
      tab,
      isLoading: true,
    }));
  }

  function changePage(page: number) {
    setState(prev => ({
      ...prev,
      page,
      isLoading: true,
    }));
  }

  return {
    state,
    changeTab,
    changePage,
    getList,
  };
}

export default useReportList;

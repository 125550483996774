import { init } from '@rematch/core';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import common from '~/models/common';
import call from '~/models/call';
import toast from '~/models/toast';
import form from '~/models/form';
import purchase from '~/models/purchase';
import roomList from '~/models/roomList';
import user from '~/models/user';
import gongsil from '~/models/gongsil';
import messenger from '~/models/messenger';
import tempStore from '~/models/tempStore';

export const history = createBrowserHistory();
const reducers = { router: connectRouter(history) };

const store = init({
  redux: {
    reducers,
    middlewares: [routerMiddleware(history)],
  },
  models: {
    common,
    call,
    toast,
    form,
    purchase,
    roomList,
    user,
    gongsil,
    tempStore,
    messenger,
  },
});

export default store;

import React from 'react';

import type { TextGroupProps } from '../types';

import Title from '../components/title';

import { Container, Content } from './styled';

export function TextGroupUIComponent({
  contentWidth = 'auto',
  title,
  titleSize = 'base',
  hasFakeTitle = false,
  children,
  style,
}: TextGroupProps) {
  return (
    <Container
      contentWidth={contentWidth}
      style={style}
    >
      {(hasFakeTitle || title) && (
        <Title
          title={title}
          titleSize={titleSize}
          hasFakeTitle={hasFakeTitle}
        />
      )}

      <Content>
        {children}
      </Content>
    </Container>
  );
}

TextGroupUIComponent.displayName = 'TextGroup';

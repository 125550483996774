import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { LineBtn, SolidBtn } from '~/atoms2';

import { Header, Body, BtnWrap } from './styled';

export function ReceiptContainerComponent({ close }: { close: () => void; }) {
  const dispatch = useDispatch();
  const {
    hasReport,
    hasNaverReport,
    hasNaverSameAddressTradeComplete,
    waitReportUserListByBusinessId,
    waitNaverReportUserListByBusinessId,
    waitNaverSameAddressTradeCompleteUserListByBusinessId,
  } = useSelector(({ user }: any) => user);

  const history = useHistory();

  return (
    <>
      <Header>
        <h1>매물 등록 제한</h1>
      </Header>

      <Body>
        <h1>매물등록을 위해 처리가 필요한 건이 존재합니다.<br/>(동일 사업자 기준)</h1>

        {waitReportUserListByBusinessId?.length > 0 && (
          <>
            <p>- 허위매물신고 중개사 자율처리 (다방매물 기준)</p>
            {waitReportUserListByBusinessId?.map((user: string) => <p key={user} style={{ marginLeft: '10px' }}>{user}</p>) ?? null}
          </>
        )}

        {waitNaverReportUserListByBusinessId?.length > 0 && (
          <>
            <p>- 허위매물신고 중개사 자율처리 (네이버매물 기준)</p>
            {waitNaverReportUserListByBusinessId?.map((user: string) => <p key={user} style={{ marginLeft: '10px' }}>{user}</p>) ?? null}
          </>
        )}

        {waitNaverSameAddressTradeCompleteUserListByBusinessId?.length > 0 && (
          <>
            <p style={{ marginTop: '8px' }}>- 네이버 동일매물 거래완료 처리</p>
            {waitNaverSameAddressTradeCompleteUserListByBusinessId?.map((user: string) => <p key={user} style={{ marginLeft: '10px' }}>{user}</p>) ?? null}
          </>
        )}

        <BtnWrap>
          <LineBtn
            btnSize="xl"
            btnColor="gray"
            contentWidth="120px"
            onClick={close}
          >
            닫기
          </LineBtn>

          {(hasReport || hasNaverReport || hasNaverSameAddressTradeComplete) && (
            <SolidBtn
              btnSize="xl"
              btnColor="blue"
              contentWidth="120px"
              onClick={() => {
                close();
                // @ts-ignore
                dispatch.common.update({ isFloatingButtonOpen: true });
                history.replace('/dashboard');
              }}
            >
              바로가기
            </SolidBtn>
          )}
        </BtnWrap>
      </Body>
    </>
  );
}

export default {
  id: '',                   // roomId
  tab: 'notYet',
  isOther: false,
  notYet: {                 // 미답변 문의 내역
    isLoading: true,
    total: 0,
    page: 1,
    limit: 10,
    isAll: true,
    messenger: [],
    checkList: [],
  },
  completed: {              // 응답 완료 내역
    isLoading: true,
    total: 0,
    page: 1,
    limit: 5,
    messenger: [],
  },
  recommends: {             // 추천 매물
    isLoading: true,
    rooms: [],
    isExistsPublicRoom: false,
  },
  rooms: {                  // 다른 매물 추천
    isLoading: true,
    rooms: [],
    selectedRooms: [],
    total: 0,
    page: 1,
    limit: 24,
  },
  description: [],          // 푸터 문구
};

export const m2 = 3.3058;
export const companyTel = '1899-6840';
export const companyAddress = '서울시 서초구 서초대로 301 동익성봉빌딩 10층 (주)스테이션3';

export const isDev = process.env.NODE_ENV === 'development';
export const dabangDomain = process.env.REACT_APP_DABANG_URL;
export const signDevApi = '//test-dabang-contract-api.dabangapp.com';
export const signDomain = process.env.REACT_APP_BUILD === 'dev' ? 'https://test-dabang-contract-api.dabangapp.com' : process.env.REACT_APP_BUILD === 'staging' ? 'https://staging-dabang-contract-api.dabangapp.com' : 'https://api-sign.dabangapp.com';

/** 중개사툴 페이지 주소 */
const agentToolUrlDev = 'https://test-dabang-agent-tool-web.dabangapp.com';
const agentToolUrlStaging = 'https://staging-dabang-agent-tool-web.dabangapp.com';
const agentToolUrlProd = 'https://agent-tool.dabangapp.com';
export const agentToolDomain = process.env.REACT_APP_BUILD === 'dev' ? agentToolUrlDev : process.env.REACT_APP_BUILD === 'staging' ? agentToolUrlStaging : agentToolUrlProd;

export const naverMapKey = process.env.REACT_APP_NAVER_CLIENT_ID ?? 'kdt9vprl21';
export const bucket = process.env.REACT_APP_S3_BUCKET;
export const imgUpload =  window.location.protocol + '//upload-images.dabangapp.com/upload';
export const imgUploadPano = window.location.protocol + `${process.env.REACT_APP_BUILD === 'dev' ? '//test-dabang-upload-image.dabangapp.com/upload-panorama' : '//upload-images.dabangapp.com/upload-panorama'}`;
export const imgRotate =  window.location.protocol + '//upload-images.dabangapp.com/rotate';
export const signImgUpload = window.location.protocol + process.env.REACT_APP_IMG_UPLOAD_URL + 'upload';
export const signPanoUpload = window.location.protocol + process.env.REACT_APP_IMG_UPLOAD_URL + 'upload-panorama';
export const signImgRotate = window.location.protocol + process.env.REACT_APP_IMG_UPLOAD_URL + 'rotate';

export const imgUploadV3 =  window.location.protocol + `${process.env.REACT_APP_BUILD === 'dev' ? '//test-dabang-upload-image.dabangapp.com/v3/file/upload' : '//upload-images.dabangapp.com/v3/file/upload'}`;
export const fileBucket = process.env.REACT_APP_BUILD === 'dev' ? 'dabang-dev-temp-file' : 'dabang-prod-temp-file';

export const profileUrl = process.env.REACT_APP_BUILD === 'dev' ? 'https://dabang-dev-profile-image.s3.amazonaws.com/' : 'https://dabang-prod-profile-image.s3.amazonaws.com/';
export const imgUrl = `https://s3-ap-northeast-1.amazonaws.com/${bucket}/`;
export const tempImgUrl = `https://${bucket}.s3.amazonaws.com:443/`;

// 거래내역 조회
export const nicePay = 'https://www.nicepay.co.kr/cs/transInfo/cardList.do';
// 현금영수증 조회
export const homeTex = 'https://hometax.go.kr';
// 정기결제 조회
export const firstPay = 'https://www.firstpay.co.kr/jsp/payment/creditCard.jsp';
// 결제내역 조회
export const ecredit = 'https://ecredit.uplus.co.kr';

// 허위매물 제재 정책 확인
export const fakeRoomSanctionsUrl = 'http://blog.naver.com/dabangapp/220122235428';
// LH한국토지주택공사 세부 내용
export const LHInfoUrl = 'https://blog.naver.com/dabangapp/221990785312';

// 원격지원 설명서
export const remoteUrl = 'http://test-dabang-pro.dabangapp.com/#/remote';
// 이용가이드
export const guideUrl = 'http://static.dabangapp.com/pdf/dabang_pro_manual_20200810.pdf';
// 바로가기 설치
export const downloadQuickLink = 'http://static.dabangapp.com/setup.exe';
// 프로앱 다운로드 링크 (Google)
export const googlePlayAppLink = 'https://play.google.com/store/apps/details?id=kr.co.station3.dabang.pro';
// 프로앱 다운로드 링크 (Apple)
export const appStoreAppLink = 'https://apps.apple.com/kr/app/apple-store/id1215588806';

// 정기결제 약관
export const regularTermUrl = 'https://main.d3qjzrynlab66o.amplifyapp.com/terms/pro/regular-payment/terms-of-auto-payment';
// 유료서비스 이용약관
export const paidServiceUrl = 'https://main.d3qjzrynlab66o.amplifyapp.com/terms/pro/terms-of-charge';
// 공실센터 서비스 운영 정책 약관
export const gongsilTermUrl = 'https://main.d3qjzrynlab66o.amplifyapp.com/terms/pro/gongsil/terms-of-service';
// 전자계약 위수탁 약관
export const consignmehtnTermUrl = 'https://main.d3qjzrynlab66o.amplifyapp.com/terms/pro/contract/consignment';

// 다방프로 naver blog
export const proNaverBlogUrl = 'https://blog.naver.com/dabangapp';

// 다방 naver post
export const naverPostUrl = 'https://post.naver.com/station3inc';
// facebook
export const facebookUrl = 'https://www.facebook.com/dabangapp';
// instagram
export const instaUrl = 'https://www.instagram.com/dabang_app/';
// 중개사무소 검색
export const agentApplyUrl = 'https://www.gov.kr/mw/AA020InfoCappView.do?HighCtgCD=A09005&CappBizCD=15000000032&tp_seq=01';
// 전자계약 타이틀
export const signProjectName = '비대면 계약';
export const signProjectFullName = '다방싸인 비대면 계약';
export const signTitle = '다방싸인';

/**
 * ### 네이버 사이트 매물 상세 페이지 주소
 *
 * 사용법
 * - {naverSiteRoomDetail}/article/{seq}
 */
export const naverSiteRoomDetail = process.env.REACT_APP_BUILD === 'dev' ? 'https://etest-new.land.naver.com' : 'https://new.land.naver.com';

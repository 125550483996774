import styled from 'styled-components';

export const Wrap = styled.ul<{ cellWidth: string }>`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  > li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: ${({ cellWidth }) => cellWidth};

    > p.title {
      min-height: 24px;
      color: ${({ theme }) => theme.color.gray[900]};
      ${({ theme }) => theme.font.body3.bold};
      cursor: default;
    }

    * + * {
      margin-top: 20px;
    }
  }
`;

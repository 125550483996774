import { createModel } from '@rematch/core';
import { replace } from 'connected-react-router';
import Cookies from 'js-cookie';

import { makeUserInfoResponse } from '~/utils/make-data';

import state from './state';

const { confirm } = window;

const user = createModel({
  state,
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    reset() {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
  effects: dispatch => ({
    async getAuthkey(_, { call: { get } }) {
      const authKey = await get('/api/v2/user/session');

      this.update({
        authKey,
      });
    },

    async setUser(payload) {
      this.update(payload);
    },

    async getInfo(_, { call: { get } }) {
      try {
        const res = await get('/api/v2/agent/info');

        const data = makeUserInfoResponse(res);

        this.update(data);

      } catch (err) {
        // 유저정보를 받아오는데 실패함 -> 로그아웃상태
        // 비로그인 상태에서 로그인후 볼 수 있는 페이지에 있는지 체크후 리다이렉션
        this.update({ isLoading: false });
        this.checkUrl(false);
      }
    },

    changePassword() {
      this.reset();
      dispatch(replace({
        pathname: '/login',
      }));
    },

    async withdrawal(_, { call: { post } }) {
      try {
        await post('/api/v2/user/logout');

        this.reset();

        dispatch(replace({
          pathname: '/login',
        }));

      } catch (err) {
        console.log('logout', err);
      }
    },

    async logoutAsync(askConfirm = true, { call: { post } }) {
      if (askConfirm && !confirm('로그아웃 하시겠습니까?')) return;
      try {
        await post('/api/v2/user/logout');
        await this.checkUrl(true);
        Cookies.remove('authorization');
        sessionStorage.removeItem('isReadReportNotice');

      } catch (err) {
        console.log('logout', err);
      }
    },

    checkUrl(needReload = false, { router }) {
      if (window.location.pathname.indexOf('/mobile') > -1) return;
      // 로그인이 필요한 페이지에서 로그아웃하면 리다이렉션 시킨다.
      const dynamicUrl = ['/dashboard', '/room', '/form', '/gongsil', '/product', '/account', '/business-info', '/sign', '/inquiry'];

      const needReplace = dynamicUrl.reduce((result, url) => {
        if (router.location.pathname.indexOf(url) === 0) return true;
        return result;
      }, false);

      if (needReplace) {
        window.location.href = '/';

      } else if (needReload) {
        window.location.reload(true);
      }
    },

    /**
     * 연락처, 하위계정 삭제 시 agentContacts의 배열에서 해당 값을 제거
     * @param {string} id user - agentContacts의 id
     */
    deleteAgentContacts(id, { user: { agentContacts = [] } }) {
      const filterAgentContacts = agentContacts.filter(contact => contact.id !== id);

      this.update({ agentContacts: filterAgentContacts });
    }
  }),
});

export default user;

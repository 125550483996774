import { useRef, useEffect, useMemo } from 'react';
import { nanoid } from 'nanoid';

import useModalStore from '../../store';

function usePopup() {
  const _id = useRef(nanoid());
  const _idx = useRef(-1);

  const {
    modals,
    pushModal,
    popModal,
  } = useModalStore();

  const isActive = useMemo(() => {
    return modals[modals.length - 1] === _id.current;
  }, [modals]);

  useEffect(() => {
    _idx.current = modals.findIndex(id => id === _id.current);

    if (_idx.current < 0) {
      pushModal(_id.current);
    }

    return () => {
      const dom = document.getElementById(_id.current);
      if (!dom) {
        popModal(_id.current);
      }
    };
  }, [modals]);

  return {
    id: _id.current,
    idx: _idx.current,
    isActive,
  };
}

export default usePopup;

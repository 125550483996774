import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Modal from '~/components/modal';

import useNoItem from './useNoItem';

import { Header } from '../styled';
import { Wrap, Text, BtnWrap, CloseBtn } from './styled';

function NoItem({ resetType }) {
  const { isShow, clickClose, clickCloseWithExpire } = useNoItem(resetType);
  const { vested } = useSelector(({ user }) => user);

  if (!isShow) {
    return null;
  }

  return (
    <Modal minWidth={700} handle={clickClose} escToClose={false}>
      <Header>
        <h1>안내사항</h1>
      </Header>

      <Wrap>
        <Text>안녕하세요 회원님.</Text>
        <Text>중개사의 성공적인 사업 파트너 다방프로입니다.</Text>
        <Text>현재 회원님께서는 이용 중인 광고 상품이 없습니다.</Text>
        {vested ? (
          <>
            <Text>다방프로 광고 상품 이용으로, 다방의 효과적인 광고 효과를 직접 경험해 보세요.</Text>
            <Text>광고 문의: 1899-6840</Text>
            <Text>(운영시간 10:00 ~ 18:30)</Text>
          </>
        ) : (
          <Text><span>담당 영업사원 통해 문의</span> 하시면 <span>자세한 상품과 광고주 프로모션을 안내</span> 받아보실 수 있습니다.</Text>
        )}
      </Wrap>
      <BtnWrap>
        <CloseBtn onClick={clickCloseWithExpire}>3일간 보지않기</CloseBtn>
        {vested && <NavLink to="/product/purchase" onClick={clickClose}>상품결제로 이동하기</NavLink>}
      </BtnWrap>
    </Modal>
  );
}

export default NoItem;

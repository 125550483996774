import { css } from 'styled-components';

export const TOP_LEFT = 'top-left';
export const TOP_CENTER = 'top-center';
export const TOP_RIGHT = 'top-right';
export const BOTTOM_LEFT = 'bottom-left';
export const BOTTOM_CENTER = 'bottom-center';
export const BOTTOM_RIGHT = 'bottom-right';

export const borderRadius = 4;
export const padding = 8;

export const toastPosition = position => {
  switch (position) {
    case TOP_LEFT: 
      return css`
        top: 0;
        left: 0;
      `;

    case TOP_CENTER: 
      return css`
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      `;

    case TOP_RIGHT: 
      return css`
        top: 0;
        right: 0;
      `;

    case BOTTOM_LEFT: 
      return css`
        bottom: 0;
        left: 0;
      `;

    case BOTTOM_CENTER: 
      return css`
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      `;

    case BOTTOM_RIGHT: 
      return css`
        bottom: 0;
        right: 0;
      `;

    default:
      return css``;
  }
};

function getTranslate(position) {
  const pos = position.split('-');
  const relevantPlacement = pos[1] === 'center' ? pos[0] : pos[1];

  const translateMap = {
    right: 'translate3d(120%, 0, 0)',
    left: 'translate3d(-120%, 0, 0)',
    bottom: 'translate3d(0, 120%, 0)',
    top: 'translate3d(0, -120%, 0)',
  };

  return translateMap[relevantPlacement];
}

export const toastStates = position => ({
  entering: css`transform: ${getTranslate(position)}; opacity: 0;`,
  entered: css`transform: translate3d(0,0,0);`,
  exiting: css`transform: scale(0.66); opacity: 0;`,
  exited: css`transform: scale(0.66); opacity: 0;`,
});

export const colors = {
  success: '#5359fe',
  error: '#d0021b',
  warning: '#FF8B00',
  info: '#007f6a',
};

import React from 'react';

import isEmpty from '~/utils/valid/isEmpty';
import Modal from '~/components/modal';

import useNotice from './useNotice';

import { Header } from '../styled';
import { Wrap, Title, Text, BtnWrap, CloseBtn, ShowDetail } from './styled';

function NoticePopup({ resetType }) {
  const { isShow, show, clickClose, clickCloseWithExpire } = useNotice(resetType);
  
  if (!isShow) {
    return null;
  }

  return (
    <Modal minWidth={480} handle={clickClose} escToClose={false}>
      <Header>
        <h1>공지사항</h1>
      </Header>

      <Wrap>
        <Title>{show.title}</Title>
        <Text>{show.content}</Text>
      </Wrap>

      <BtnWrap>
        <CloseBtn onClick={clickCloseWithExpire}>
          {isEmpty(show?.expires) ? '다시 보지 않기' : show.expires === 1 ? '오늘 하루 보지 않기' : `${show.expires}일간 보지 않기`}
        </CloseBtn>
        {show?.url && <ShowDetail href={show.url} onClick={clickClose}>
          자세히 보기
        </ShowDetail>}
      </BtnWrap>
    </Modal>
  );
}

export default NoticePopup;

import React from 'react';

import useFooter from './useFooter';

import { paidServiceUrl, companyAddress, companyTel, naverPostUrl, facebookUrl, instaUrl } from '~/enum/config';
import scrollToTop from '~/utils/scrollToTop';

import { ReactComponent as TopArrowSvg } from './images/topArrow.svg';
import { ReactComponent as NaverSvg } from './images/naver.svg';
import { ReactComponent as FacebookSvg } from './images/facebook.svg';
import { ReactComponent as InstaSvg } from './images/insta.svg';

import {
  Footer, Wrap, LinkWrap, CompanyLink, TermLink, TopBtn,
  Info, WorkInfo, WorkTime,
  AddressInfo, Address, CopyRight,
  Social, SnsInfo, SnsLink
} from './styled';

function FooterComponent() {
  const { terms, userEmail } = useFooter();

  return (
    <Footer id="footer">
      <Wrap>
        <div>
          <LinkWrap>
            <CompanyLink>회사소개</CompanyLink>

            {terms.map(term => (
              <TermLink
                key={term.termsSeq}
                href={term.originUrl}
                isHighlight={term.termsSeq === 3}
              >
                {term.termsShortName}
              </TermLink>
            ))}
            {userEmail && (
              <a href={paidServiceUrl} target="_blank" rel="noopener noreferrer">
                유료서비스 이용약관
              </a>
            )}
            <a href="https://www.kiso.or.kr/%EC%A0%95%EB%B3%B4%EC%84%BC%ED%84%B0/%EB%B6%80%EB%8F%99%EC%82%B0%EB%A7%A4%EB%AC%BC%ED%81%B4%EB%A6%B0%EA%B4%80%EB%A6%AC%EC%84%BC%ED%84%B0-2/%EC%98%A8%EB%9D%BC%EC%9D%B8%EB%B6%80%EB%8F%99%EC%82%B0%EB%A7%A4%EB%AC%BC%EA%B4%91%EA%B3%A0%EC%9E%90%EC%9C%A8%EA%B7%9C%EC%95%BD/" target="_blank" rel="noopener noreferrer">온라인 부동산 광고 자율규약</a>
          </LinkWrap>

          <TopBtn onClick={scrollToTop}>
            <TopArrowSvg />
            <p>TOP</p>
          </TopBtn>
        </div>
      </Wrap>

      <Info>
        <div>
          <WorkInfo>
            <p>고객센터</p>
            <h1>{companyTel}</h1>

            <WorkTime>
              <p>운영시간 : 10:00 ~18:30</p>
              <p>점심시간 : 12:30 ~ 13:30</p>
              <p>토요일, 일요일, 공휴일 휴무</p>
            </WorkTime>
          </WorkInfo>

          <AddressInfo>
            <Address>
              <span>사업자 번호: 220-88-59156</span>
              <span>통신판매업신고번호: 제2013-서울 강남-20884호</span>
              <span>대표: 유형석</span>
            </Address>

            <Address>주소: {companyAddress}</Address>

            <CopyRight>Station3,Inc. All rights reserved.</CopyRight>
          </AddressInfo>

          <Social>
            <SnsInfo>
              <SnsLink href={naverPostUrl}>
                <NaverSvg />
              </SnsLink>
              <SnsLink href={facebookUrl}>
                <FacebookSvg />
              </SnsLink>
              <SnsLink href={instaUrl}>
                <InstaSvg />
              </SnsLink>
            </SnsInfo>
          </Social>
        </div>
      </Info>
    </Footer>
  );
}

export default FooterComponent;

import { useState, useEffect, useCallback } from 'react';

interface Config {
  initialPage: number;
  numberOfPages: number;
  maxButtons: number;
}

interface Props {
  activePage: number,
  config: Config;
}

function computeVisiblePieces({ activePage, config }: Props) {
  const { numberOfPages, maxButtons } = config;
  const visiblePieces = [];

  let lowerLimit = activePage;
  let upperLimit = activePage;

  visiblePieces.push({
    type: 'first',
    pageNumber: 1,
    isDisabled: activePage === 1,
  });

  visiblePieces.push({
    type: 'previous',
    pageNumber: Math.max(1, activePage - 1),
    isDisabled: activePage === 1,
  });

  for (let i = 1; i < maxButtons && i < numberOfPages;) {
    if (i < maxButtons) {
      if (lowerLimit > 1) {
        lowerLimit--;
        i++;
      }
      if (i < maxButtons && upperLimit < numberOfPages) {
        upperLimit++;
        i++;
      }
    }
  }

  for (let i = lowerLimit; i <= upperLimit; i++) {
    visiblePieces.push({ type: 'page-number', pageNumber: i });
  }

  visiblePieces.push({
    type: 'next',
    pageNumber: Math.min(numberOfPages, activePage + 1),
    isDisabled: activePage === numberOfPages,
  });

  visiblePieces.push({
    type: 'last',
    pageNumber: Math.min(numberOfPages, numberOfPages),
    isDisabled: activePage === numberOfPages,
  });

  return visiblePieces;
}

/**
 *
 * @param {Object} _config
 * - @member {number} initialPage
 * - @member {number} numberOfPages
 * - @member {number} maxButtons
 */
function usePagination(_config: Partial<Config>) {
  const config: Config = {
    initialPage: _config.initialPage ?? 1,
    numberOfPages: _config.numberOfPages ?? 1,
    maxButtons: _config.maxButtons ?? 5,
  };

  if (config.initialPage > config.numberOfPages) {
    config.initialPage = config.numberOfPages;
  }

  if (config.maxButtons > config.numberOfPages) {
    config.maxButtons = config.numberOfPages;
  }

  const [activePage, setActivePage] = useState(config.initialPage);

  const { numberOfPages } = config;

  const isFirst = activePage === 1;
  const isLast = activePage === numberOfPages;

  const hasPrevious = numberOfPages > 1 && activePage > 1;
  const hasNext = activePage < numberOfPages;

  const visiblePieces = computeVisiblePieces({ activePage, config });
  const goToPage = useCallback((pageNumber: number) => {
    setActivePage(pageNumber);
  }, []);

  useEffect(() => {
    if (config.initialPage !== activePage) {
      setActivePage(config.initialPage);
    }
  }, [config.initialPage]);

  return {
    activePage,
    isFirst,
    isLast,
    hasPrevious,
    hasNext,
    visiblePieces,
    goToPage,
  };
}

export default usePagination;

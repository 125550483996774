import React from 'react';

import { ReactComponent as PowerfulRecommendedSvg } from './images/powerful_recommended.svg';
import { ReactComponent as QuicklySellSvg } from './images/quickly_sell.svg';
import { ReactComponent as RemodelingSvg } from './images/remodeling.svg';
import { ReactComponent as PetsAllowedSvg } from './images/pets_allowed.svg';
import { ReactComponent as NewConstructionsSvg } from './images/new_constructions.svg';
import { ReactComponent as StationAreaSvg } from './images/station_area.svg';
import { ReactComponent as PopularRoomSvg } from './images/popular_room.svg';
import { ReactComponent as QuickMoveInSvg } from './images/quick_move_in.svg';
import { ReactComponent as ElementarySchoolAreaSvg } from './images/elementary_school_area.svg';
import { ReactComponent as FullOptionSvg } from './images/full_option.svg';

interface Props {
  iconFocusType: IconFocus.TIconFocusType;
  iconSize?: number;
}

function IconComponent({
  iconFocusType,
  iconSize = 24,
}: Props) {
  switch (iconFocusType) {
    case 'POWERFUL_RECOMMENDED':
      return <PowerfulRecommendedSvg width={iconSize} height={iconSize} />;

    case 'QUICKLY_SELL':
      return <QuicklySellSvg width={iconSize} height={iconSize} />;

    case 'REMODELING':
      return <RemodelingSvg width={iconSize} height={iconSize} />;

    case 'PETS_ALLOWED':
      return <PetsAllowedSvg width={iconSize} height={iconSize} />;

    case 'NEW_CONSTRUCTIONS':
      return <NewConstructionsSvg width={iconSize} height={iconSize} />;

    case 'STATION_AREA':
      return <StationAreaSvg width={iconSize} height={iconSize} />;

    case 'POPULAR_ROOM':
      return <PopularRoomSvg width={iconSize} height={iconSize} />;

    case 'QUICK_MOVE_IN':
      return <QuickMoveInSvg width={iconSize} height={iconSize} />;

    case 'ELEMENTARY_SCHOOL_AREA':
      return <ElementarySchoolAreaSvg width={iconSize} height={iconSize} />;

    case 'FULL_OPTION':
      return <FullOptionSvg width={iconSize} height={iconSize} />;

    default:
      return null;
  }
}

export default IconComponent;

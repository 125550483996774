import React from 'react';

import Modal from '~/components/modal';

import useVerifyTel from './useVerifyTel';

import { Header } from '../styled';
import { Wrap, Title, Text, BtnWrap, CloseBtn } from './styled';

function VerifyTel({ resetType }) {
  const { isShow, clickClose } = useVerifyTel(resetType);

  if (!isShow) return null;

  return (
    <Modal minWidth={480} handle={clickClose} escToClose={false}>
      <Header>
        <h1>안내사항</h1>
      </Header>

      <Wrap>
        <Title>[대표 연락처] 정보 확인이 필요합니다.</Title>
        <Text>1. 대표 연락처 설정값이 존재하지 않습니다.</Text>
        <Text>2. 설정된 대표 연락처가 등록 관청에 신고된 번호와 다릅니다.</Text>
        <Text>위 2가지 사항 확인 후 나의 계정에서 재설정 바랍니다.</Text>
      </Wrap>

      <BtnWrap>
        <CloseBtn onClick={clickClose}>확인</CloseBtn>
      </BtnWrap>
    </Modal>
  );
}

export default VerifyTel;

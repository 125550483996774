import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PublicRoute({ children, ...rest }) {
  const { isAuth, isLoading } = useSelector(({ user }) => user);

  return (
    <Route
      {...rest}
      render={() => {
        if (isLoading) return null;
        if (!isAuth) return children;
        return <Redirect to="/dashboard" />;
      }}
    />
  );
}

export default PublicRoute;

import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
  
  > h1  {
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body2.bold};
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 40px;

  > h1 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body3.bold};
  }
  
  > p {
    color: ${({ theme }) => theme.color.gray[700]};
    ${({ theme }) => theme.font.body3.regular};
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  button + button {
    margin-left: 8px;
  }
`;

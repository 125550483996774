export const ROOM_LIST_TAB = [
  {
    label: '전체',
    value: '',
    total: 0,
  },
  {
    label: '광고 진행',
    value: 'public',
    total: 0,
  },
  {
    label: '광고 종료',
    value: 'invisible',
    total: 0,
  },
  {
    label: '거래 완료',
    value: 'complete',
    total: 0,
  },
];

export const DABANG_LIST_TAB = [
  {
    label: '전체',
    value: '',
    total: 0,
  },
  {
    label: '광고 진행',
    value: 'public',
    total: 0,
  },
  {
    label: '광고 종료',
    value: 'private',
    total: 0,
  },
  {
    label: '거래 완료',
    value: 'complete',
    total: 0,
  },
];

export const NAVER_LIST_TAB = [
  {
    label: '전체',
    value: '',
    total: 0,
  },
  {
    label: '광고 진행',
    value: 'public',
    total: 0,
  },
  {
    label: '광고 종료',
    value: 'private',
    total: 0,
  },
  {
    label: '거래 완료',
    value: 'complete',
    total: 0,
  },
  {
    label: '검증 중',
    value: 'confirm_ing',
    total: 0,
  },
  {
    label: '검증 반려/실패',
    value: 'confirm_fail',
    total: 0,
  },
  {
    label: '검증 취소',
    value: 'confirm_cancel',
    total: 0,
  },
];

export const GONGSIL_LIST_TAB = [
  {
    label: '중개 요청',
    value: 'pick',
  },
  {
    label: '전체',
    value: 'all',
  },
  {
    label: '담은 공실',
    value: 'preempt',
  },
  {
    label: '종료 공실',
    value: 'complete',
  },
];

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { signProjectName } from '~/enum/config';

import MenuButton from '../menu-button';

import { Wrap } from './styled';

function signAlertMsg({ isSafeAuth, isCeoSafeAuth, useItem, agentSignStatus, noSignAuth }: any) {
  if (!isSafeAuth) {
    return `${signProjectName} 서비스 이용을 위해 본인인증을 진행해 주세요.\n* 본인인증은 [계정 관리 > 나의 계정] 진행하실 수 있습니다.`;
  }

  if (!isCeoSafeAuth) {
    return `${signProjectName} 서비스 이용을 위해 사업자 인증을 진행해 주세요.`;
  }

  if (agentSignStatus !== 'APPROVAL') {
    if (agentSignStatus === 'PROCEED') {
      return `현재 심사 대기중입니다. 승인 완료 후, ${signProjectName} 이용이 가능하니 잠시만 기다려주세요.`;
    }

    if (!useItem) {
      return `현재 이용중인 상품이 없습니다. 상품 구매 후, ${signProjectName}을 이용해주세요.`;
    }
  } else {
    if (noSignAuth) {
      return `${signProjectName} 메뉴 접근 권한이 없습니다. 권한 관련 문의는 고객센터를 통해 진행해 주세요.`;
    }
  }

  return null;
}

function ManageComponent({
  clickReportPopupHandler,
  close
}: {
  clickReportPopupHandler: (tab: '' | 'PROCESSING_DETAILS' | 'PENALTY') => void;
  close: () => void
}) {
  const {
    isMaster, isSafeAuth, isCeoSafeAuth,
    isServeUser, isServeItemUsedApt, isServeItemUsedOfficetel,
    isShowSignMenu, isSignUser, agentSignStatus, useItem, userRoleType,
    hasNaverItem,
  } = useSelector(({ user }: any) => user);

  const history = useHistory();

  const isAssistantBroker = userRoleType === 2;

  const clickLink = useCallback((to: string) => {
    if (history.location.pathname === to) return;

    history.push(to);
    close();
  }, []);

  return (
    <Wrap cellWidth="240px">
      <li>
        <p className="title">매물 관리</p>

        <MenuButton
          text="다방 광고 매물 관리"
          isDisappear={false}
          onClick={clickLink.bind(null, '/room/dabang-list')}
        />

        <MenuButton
          text="네이버 광고 매물 관리"
          isDisappear={!hasNaverItem}
          onClick={clickLink.bind(null, '/room/naver-list')}
        />

        <MenuButton
          text="아파트 써브 매물"
          isDisappear={!isServeUser}
          onClick={isServeItemUsedApt ? clickLink.bind(null, '/room/serve-list-apt') : () => alert('다방N써브 아파트 상품 구매시 해당 메뉴를 이용 하실 수 있습니다.')}
        />

        <MenuButton
          text="오피스텔 써브 매물"
          isDisappear={!isServeUser}
          onClick={isServeItemUsedOfficetel ? clickLink.bind(null, '/room/serve-list-officetel') : () => alert('다방N써브 오피스텔 상품 구매시 해당 메뉴를 이용 하실 수 있습니다.')}
        />

        <MenuButton
          text="신고처리 내역"
          isDisappear={false}
          onClick={clickReportPopupHandler.bind(null, 'PROCESSING_DETAILS')}
        />

        <MenuButton
          text="페널티 현황"
          isDisappear={false}
          onClick={clickReportPopupHandler.bind(null, 'PENALTY')}
        />
      </li>

      {/*<li>*/}
      {/*  <p className="title">현장촬영 관리</p>*/}

      {/*  /!* <MenuButton*/}
      {/*    text="접수 관리"*/}
      {/*    isDisappear={false}*/}
      {/*    onClick={clickLink.bind(null, '/sign/receipt')}*/}
      {/*  /> *!/*/}

      {/*  <MenuButton*/}
      {/*    text="광고 매물 관리"*/}
      {/*    isDisappear={false}*/}
      {/*    onClick={clickLink.bind(null, '/sign/room')}*/}
      {/*  />*/}
      {/*</li>*/}

      {!(isAssistantBroker || agentSignStatus === 'NONE' || !isShowSignMenu) && (
        <li>
          <p className="title">계약 관리</p>

          <MenuButton
            text="계약 관리"
            isDisappear={false}
            onClick={() => {
              const noSignAuth = !isShowSignMenu || (isMaster && !isSignUser && agentSignStatus === 'APPROVAL');
              const msg = signAlertMsg({ isSafeAuth, isCeoSafeAuth, useItem, agentSignStatus, noSignAuth });
              if (msg) {
                alert(msg);
              } else {
                const to = agentSignStatus !== 'APPROVAL' ? '/sign/agreement' : '/sign/contract/all';
                clickLink(to);
              }
            }}
          />
        </li>
      )}

      <li>
        <p className="title">공실센터</p>

        <MenuButton
          text="공실센터"
          isDisappear={false}
          onClick={clickLink.bind(null, '/gongsil')}
        />
      </li>

      {isMaster && (
        <li>
          <p className="title">상품 관리</p>

          <MenuButton
            text="상품 내역"
            isDisappear={false}
            onClick={clickLink.bind(null, '/product/list')}
          />

          <MenuButton
            text="상품 결제"
            isDisappear={false}
            onClick={clickLink.bind(null, '/product/purchase')}
          />

          <MenuButton
            text="결제 내역"
            isDisappear={false}
            onClick={clickLink.bind(null, '/product/payment-list')}
          />
        </li>
      )}

      <li>
        <p className="title">계정 관리</p>

        <MenuButton
          text="연락처 관리"
          isDisappear={false}
          onClick={clickLink.bind(null, '/account/contacts')}
        />

        <MenuButton
          text="하위 계정 관리"
          isDisappear={false}
          onClick={clickLink.bind(null, '/account/sub-user')}
        />

        <MenuButton
          text="문의 내역 관리"
          isDisappear={false}
          onClick={clickLink.bind(null, '/inquiry/talk')}
        />

        <MenuButton
          text="비밀번호 변경"
          isDisappear={false}
          onClick={clickLink.bind(null, '/account/user/password')}
        />
      </li>
    </Wrap>
  );
}

export default ManageComponent;

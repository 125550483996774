import { animateScroll } from 'react-scroll';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import isBefore from 'date-fns/isBefore';

import productType from '~/enum/productType';
import generateUUID from '~/utils/generateUUID';

const init = {
  get product() {
    return {
      type: '',
      name: '',
      regionClass: '',
      quantity: 0,
      periodFrom: format(new Date(), 'yyyy-MM-dd'),
      periodTo: '',
      day: '',
      bonus: 0,
      discount: 0,
      discountPrice: 0,
      discountRenew: 0,
      originPrice: 0,
      isRegularPayment: false,
      price: 0,
      total: 0,
    };
  },
};

function makePeriodTo(from, day) {
  return format(addDays(new Date(from), day - 1), 'yyyy-MM-dd');
}

export function addProduct(items, types, param) {
  if (items.length > 9) {
    console.error('더 이상 상품을 추가 할 수 없습니다.');
    return [];
  }

  if (items.length === 0) {
    animateScroll.scrollToTop({
      duration: 400,
    });
  }

  return types.map(type => {
    const product = init.product;
    product.id = generateUUID();

    switch (type) {
      // 일반
      case productType.GENERAL_ONE_TWO_THREE_ROOM.type:
        product.name = productType.GENERAL_ONE_TWO_THREE_ROOM.label;
        product.type = productType.GENERAL_ONE_TWO_THREE_ROOM.value;
        product.quantity = 10;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        break;
      case productType.GENERAL_OFFICETEL.type:
        product.name = productType.GENERAL_OFFICETEL.label;
        product.type = productType.GENERAL_OFFICETEL.value;
        product.quantity = 5;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        break;
      case productType.GENERAL_APT.type:
        product.name = productType.GENERAL_APT.label;
        product.type = productType.GENERAL_APT.value;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.quantity = 5;
        product.day = 90;
        break;
      case productType.GENERAL_ALL_IN_ONE.type:
        product.name = productType.GENERAL_ALL_IN_ONE.label;
        product.type = productType.GENERAL_ALL_IN_ONE.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.quantity = 10;
        product.day = 30;
        break;

      // 동 추천
      case productType.REGION_RECOMMEND.type:
        product.name = productType.REGION_RECOMMEND.label;
        product.type = productType.REGION_RECOMMEND.value;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        product.region = {};
        break;

      // 프리미엄
      case productType.SUBWAY.type:
        product.name = productType.SUBWAY.label;
        product.type = productType.SUBWAY.value;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        product.subway = {};
        break;
      case productType.REGION.type:
        product.name = productType.REGION.label;
        product.type = productType.REGION.value;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        product.region = {};
        break;
      case productType.UNIV.type:
        product.name = productType.UNIV.label;
        product.type = productType.UNIV.value;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        product.univ = {};
        break;

      // 단지
      case productType.OFFICETEL.type:
        product.name = productType.OFFICETEL.label;
        product.type = productType.OFFICETEL.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        product.officetel = {};
        break;
      case productType.APT.type:
        product.name = productType.APT.label;
        product.type = productType.APT.value;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        product.apt = {};
        break;

      // 플러스
      case productType.PLUS_30_DAY.type:
        product.name = productType.PLUS_30_DAY.label;
        product.type = productType.PLUS_30_DAY.value;
        product.quantity = 1;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        break;
      case productType.PLUS_1_DAY.type:
        product.name = productType.PLUS_1_DAY.label;
        product.type = productType.PLUS_1_DAY.value;
        product.quantity = 10;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        break;

      // N쿠폰
      case productType.NAVER_GENERAL_CONFIRM.type:
        product.name = productType.NAVER_GENERAL_CONFIRM.label;
        product.type = productType.NAVER_GENERAL_CONFIRM.value;
        product.quantity = 1;
        product.periodTo = makePeriodTo(product.periodFrom, 180);
        product.day = 180;
        break;
      case productType.NAVER_SITE_CONFIRM.type:
        product.name = productType.NAVER_SITE_CONFIRM.label;
        product.type = productType.NAVER_SITE_CONFIRM.value;
        product.quantity = 1;
        product.periodTo = makePeriodTo(product.periodFrom, 180);
        product.day = 180;
        break;

      // 다방싸인
      case productType.SIGN_BASIC.type:
        product.name = productType.SIGN_BASIC.label;
        product.type = productType.SIGN_BASIC.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        break;
      case productType.SIGN_PREMIUM.type:
        product.name = productType.SIGN_PREMIUM.label;
        product.type = productType.SIGN_PREMIUM.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        break;

      // 현장촬영
      case productType.SPOT_SHOOTING_SEASON.type:
        product.name = productType.SPOT_SHOOTING_SEASON.label;
        product.type = productType.SPOT_SHOOTING_SEASON.value;
        product.quantity = 10;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        break;
      case productType.SPOT_SHOOTING_ONE_TIME.type:
        product.name = productType.SPOT_SHOOTING_ONE_TIME.label;
        product.type = productType.SPOT_SHOOTING_ONE_TIME.value;
        product.quantity = 1;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        break;

      // 패키지
      case productType.PACKAGE_ONE_TWO_THREE_TEN.type:                                // 20
        product.name = productType.PACKAGE_ONE_TWO_THREE_TEN.label;
        product.type = productType.PACKAGE_ONE_TWO_THREE_TEN.value;
        product.periodTo = makePeriodTo(product.periodFrom, param ?? 90);
        product.isRegularPayment = false;
        product.day = param ?? 90;
        break;
      case productType.PACKAGE_OFFICETEL_FIVE.type:                                   // 21
        product.name = productType.PACKAGE_OFFICETEL_FIVE.label;
        product.type = productType.PACKAGE_OFFICETEL_FIVE.value;
        product.periodTo = makePeriodTo(product.periodFrom, param ?? 90);
        product.isRegularPayment = false;
        product.day = param ?? 90;
        break;
      case productType.PACKAGE_APT.type:                                              // 22
        product.name = productType.PACKAGE_APT.label;
        product.type = productType.PACKAGE_APT.value;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        break;
      case productType.PACKAGE_ONE_TWO_THREE_FIVE.type:                               // 23
        product.name = productType.PACKAGE_ONE_TWO_THREE_FIVE.label;
        product.type = productType.PACKAGE_ONE_TWO_THREE_FIVE.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        break;
      case productType.PACKAGE_OFFICETEL_TEN.type:                                    // 24
        product.name = productType.PACKAGE_OFFICETEL_TEN.label;
        product.type = productType.PACKAGE_OFFICETEL_TEN.value;
        product.periodTo = makePeriodTo(product.periodFrom, param ?? 90);
        product.isRegularPayment = false;
        product.day = param ?? 90;
        break;
      case productType.PACKAGE_MASTER.type:                                           // 25
        product.name = productType.PACKAGE_MASTER.label;
        product.type = productType.PACKAGE_MASTER.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        break;
      case productType.PACKAGE_ALL_IN_ONE_TEN.type:                                   // 26
        product.name = productType.PACKAGE_ALL_IN_ONE_TEN.label;
        product.type = productType.PACKAGE_ALL_IN_ONE_TEN.value;
        product.periodTo = makePeriodTo(product.periodFrom, param ?? 90);
        product.day = param ?? 90;
        break;
      case productType.PACKAGE_ALL_IN_ONE_FIVE.type:                                  // 27
        product.name = productType.PACKAGE_ALL_IN_ONE_FIVE.label;
        product.type = productType.PACKAGE_ALL_IN_ONE_FIVE.value;
        product.periodTo = makePeriodTo(product.periodFrom, param ?? 90);
        product.day = param ?? 90;
        break;

      case productType.ICON_FOCUS.type:                                               // 28
        product.name = productType.ICON_FOCUS.label;
        product.type = productType.ICON_FOCUS.value;
        product.quantity = 10;
        product.periodTo = makePeriodTo(product.periodFrom, 180);
        product.day = 180;
        break;

      default:
        break;
    }

    return product;
  });
}

export function renewProduct(items, callGet) {
  return items.map(async item => {
    const product = init.product;
    product.activeCount = item.activeCount;
    product.id = generateUUID();
    product.isRenew = false;

    if (!item.expired) {
      product.isRenew = true;
      product.renewId = item.id;
    }

    if (item.periodTo) {
      const future = format(addDays(new Date(item.periodTo), 1), 'yyyy-MM-dd');
      product.periodFrom = isBefore(new Date(item.periodTo), new Date()) ? new Date() : future;
    } else {
      product.periodFrom = new Date();
    }

    switch (item.type) {
      // 일반
      case productType.GENERAL_ALL_IN_ONE.type:
        product.name = productType.GENERAL_ALL_IN_ONE.label;
        product.type = productType.GENERAL_ALL_IN_ONE.value;
        product.quantity = 10;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        product.renewData = {
          periodTo: item.periodTo,
        };
        break;
      case productType.GENERAL_ONE_TWO_THREE_ROOM.type:
        product.name = productType.GENERAL_ONE_TWO_THREE_ROOM.label;
        product.type = productType.GENERAL_ONE_TWO_THREE_ROOM.value;
        product.quantity = 10;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        product.renewData = {
          periodTo: item.periodTo,
        };
        break;
      case productType.GENERAL_OFFICETEL.type:
        product.name = productType.GENERAL_OFFICETEL.label;
        product.type = productType.GENERAL_OFFICETEL.value;
        product.quantity = 5;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        product.renewData = {
          periodTo: item.periodTo,
        };
        break;
      case productType.GENERAL_APT.type:
        product.name = productType.GENERAL_APT.label;
        product.type = productType.GENERAL_APT.value;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.quantity = 5;
        product.day = 90;
        product.renewData = {
          periodTo: item.periodTo,
        };
        break;

      // 동 추천
      case productType.REGION_RECOMMEND.type:
        product.name = productType.REGION_RECOMMEND.label;
        product.type = productType.REGION_RECOMMEND.value;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        product.region = {};
        break;

      // 프리미엄
      case productType.SUBWAY.type:
        product.name = productType.SUBWAY.label;
        product.type = productType.SUBWAY.value;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        product.subway = {};
        break;
      case productType.REGION.type:
        product.name = productType.REGION.label;
        product.type = productType.REGION.value;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        product.region = {};
        break;
      case productType.UNIV.type:
        product.name = productType.UNIV.label;
        product.type = productType.UNIV.value;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        product.univ = {};
        break;

      // 단지
      case productType.OFFICETEL.type:
        product.name = productType.OFFICETEL.label;
        product.type = productType.OFFICETEL.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        product.officetel = {};
        break;
      case productType.APT.type:
        product.name = productType.APT.label;
        product.type = productType.APT.value;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        product.apt = {};
        break;

      // 플러스
      case productType.PLUS_30_DAY.type:
        product.name = productType.PLUS_30_DAY.label;
        product.type = productType.PLUS_30_DAY.value;
        product.quantity = 1;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        product.renewData = {
          periodTo: item.periodTo,
        };
        break;
      case productType.PLUS_1_DAY.type:
        product.name = productType.PLUS_1_DAY.label;
        product.type = productType.PLUS_1_DAY.value;
        product.quantity = 10;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        break;

      // N쿠폰
      case productType.NAVER_GENERAL_CONFIRM.type:
        product.name = productType.NAVER_GENERAL_CONFIRM.label;
        product.type = productType.NAVER_GENERAL_CONFIRM.value;
        product.quantity = 1;
        product.periodTo = makePeriodTo(product.periodFrom, 180);
        product.day = 180;
        break;
      case productType.NAVER_SITE_CONFIRM.type:
        product.name = productType.NAVER_SITE_CONFIRM.label;
        product.type = productType.NAVER_SITE_CONFIRM.value;
        product.quantity = 1;
        product.periodTo = makePeriodTo(product.periodFrom, 180);
        product.day = 180;
        break;

      // 다방싸인
      case productType.SIGN_BASIC.type:
        product.name = productType.SIGN_BASIC.label;
        product.type = productType.SIGN_BASIC.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        break;
      case productType.SIGN_PREMIUM.type:
        product.name = productType.SIGN_PREMIUM.label;
        product.type = productType.SIGN_PREMIUM.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        break;

      // 현장촬영
      case productType.SPOT_SHOOTING_SEASON.type:
        product.name = productType.SPOT_SHOOTING_SEASON.label;
        product.type = productType.SPOT_SHOOTING_SEASON.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        break;
      case productType.SPOT_SHOOTING_ONE_TIME.type:
        product.name = productType.SPOT_SHOOTING_ONE_TIME.label;
        product.type = productType.SPOT_SHOOTING_ONE_TIME.value;
        product.quantity = 1;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        break;

      // 패키지
      case productType.PACKAGE_ONE_TWO_THREE_TEN.type:
        product.name = productType.PACKAGE_ONE_TWO_THREE_TEN.label;
        product.type = productType.PACKAGE_ONE_TWO_THREE_TEN.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.isRegularPayment = false;
        product.day = product.isRenew ? 30 : -1;
        product.renewData = {
          periodTo: item.periodTo,
        };
        break;
      case productType.PACKAGE_OFFICETEL_FIVE.type:
        product.name = productType.PACKAGE_OFFICETEL_FIVE.label;
        product.type = productType.PACKAGE_OFFICETEL_FIVE.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.isRegularPayment = false;
        product.day = product.isRenew ? 30 : -1;
        product.renewData = {
          periodTo: item.periodTo,
        };
        break;
      case productType.PACKAGE_APT.type:
        product.name = productType.PACKAGE_APT.label;
        product.type = productType.PACKAGE_APT.value;
        product.periodTo = makePeriodTo(product.periodFrom, 90);
        product.day = 90;
        product.renewData = {
          periodTo: item.periodTo,
        };
        break;
      case productType.PACKAGE_ONE_TWO_THREE_FIVE.type:                               // 23
        product.name = productType.PACKAGE_ONE_TWO_THREE_FIVE.label;
        product.type = productType.PACKAGE_ONE_TWO_THREE_FIVE.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        product.renewData = {
          periodTo: item.periodTo,
        };
        break;
      case productType.PACKAGE_OFFICETEL_TEN.type:                                    // 24
        product.name = productType.PACKAGE_OFFICETEL_TEN.label;
        product.type = productType.PACKAGE_OFFICETEL_TEN.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.isRegularPayment = false;
        product.day = product.isRenew ? 30 : -1;
        product.renewData = {
          periodTo: item.periodTo,
        };
        break;
      case productType.PACKAGE_MASTER.type:                                           // 25
        product.name = productType.PACKAGE_MASTER.label;
        product.type = productType.PACKAGE_MASTER.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.day = 30;
        product.renewData = {
          periodTo: item.periodTo,
        };
        break;
      case productType.PACKAGE_ALL_IN_ONE_TEN.type:                                    // 26
        product.name = productType.PACKAGE_ALL_IN_ONE_TEN.label;
        product.type = productType.PACKAGE_ALL_IN_ONE_TEN.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.isRegularPayment = false;
        product.day = product.isRenew ? 30 : -1;
        product.renewData = {
          periodTo: item.periodTo,
        };
        break;
      case productType.PACKAGE_ALL_IN_ONE_FIVE.type:                                    // 27
        product.name = productType.PACKAGE_ALL_IN_ONE_FIVE.label;
        product.type = productType.PACKAGE_ALL_IN_ONE_FIVE.value;
        product.periodTo = makePeriodTo(product.periodFrom, 30);
        product.isRegularPayment = false;
        product.day = product.isRenew ? 30 : -1;
        product.renewData = {
          periodTo: item.periodTo,
        };
        break;
      case productType.ICON_FOCUS.type:                                                // 28
        product.name = productType.ICON_FOCUS.label;
        product.type = productType.ICON_FOCUS.value;
        product.quantity = 10;
        product.periodTo = makePeriodTo(product.periodFrom, 180);
        product.day = 180;
        break;

      default:
        break;
    }

    return product;
  });
}

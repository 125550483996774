import styled from 'styled-components';

import type { TTitleSize } from '../../types';

export const TitleWrap = styled.header<{
  titleSize: TTitleSize;
  hasFakeTitle: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${({ hasFakeTitle, titleSize }) => hasFakeTitle ? (titleSize === 'sm' ? '20px' : '24px') : 'auto'};
  margin-bottom: ${({ titleSize }) => titleSize === 'sm' ? '4px' :  '8px'};
`;

export const Title = styled.h1<{
  titleSize: TTitleSize;
  isDisabled: boolean;
}>`
  color: ${({ titleSize, isDisabled, theme }) => theme.color.gray[isDisabled ? 500 : titleSize === 'sm' ? 700 : 900]};
  ${({ titleSize, theme }) => titleSize === 'sm' ? theme.font.caption1.bold : theme.font.body3.bold};
`;

export const Star = styled.span`
  margin-left: 4px;
  color: ${({ theme }) => theme.color.blue[500]};
`;

// 매물 유형 항목
export const roomTypes = [
  { value: 0, name: '주택/빌라 (원투쓰리룸)' },
  { value: 3, name: '오피스텔 (도시형)' },
  { value: 4, name: '아파트' },
];

// 건물유형 항목
export const buildingTypes = [
  { value: 0, name: '단독주택' },
  { value: 1, name: '다가구주택' },
  { value: 2, name: '빌라/연립/다세대' },
  { value: 3, name: '상가주택' },
  { value: 8, name: '기타' },
  { value: 4, name: '오피스텔' },
  { value: 5, name: '도시형생활주택' },
  { value: '', name: '아파트' },
];

// 거래유형 항목
export const sellingTypes = [
  { value: 0, name: '월세' },
  { value: 1, name: '전세' },
  { value: 2, name: '매매' },
];

// 건축물용도(층별용도) 항목
export const buildingPurpose = [
  { key: 'DETACHED_HOUSE', name: '단독주택' },
  { key: 'APARTMENT_HOUSE', name: '공동주택' },
  { key: 'FIRST_NEARBY_ROAD_FACILITIES', name: '제1종근린생활시설' },
  { key: 'SECOND_NEARBY_ROAD_FACILITIES', name: '제2종근린생활시설' },
  { key: 'BUSINESS_FACILITIES', name: '업무시설' },
  { key: 'ACCOMMODATION', name: '숙박시설' },   // 오피스텔에서만 노출
  { key: 'INPUT', name: '직접입력' },
];

// 건축물 승인 항목
export const buildingApproval = [
  { value: 0, name: '사용승인일' },
  { value: 1, name: '사용검사일' },
  { value: 2, name: '준공인가일' },
  { value: 3, name: '사용승인예정일' },
];

// 난방종류 항목
export const heatTypes = [
  { key: 0, name:'중앙 난방' },
  { key: 1, name:'개별 난방' },
  { key: 2, name:'지역 난방' },
];

// 난반종류(단지) 항목
export const heatTypesComplex = [
  { key: 0, name:'중앙 난방' },
  { key: 1, name:'개별 난방' },
  { key: 2, name:'지역 난방' },
  { key: 3, name:'중앙 냉난방' },
  { key: 4, name:'지역 냉난방' },
  { key: 5, name:'개별 냉난방' },
  { key: 6, name:'기타' },
];

// 공용관리비 항목
export const maintenances = [
  { key: 3, name: '청소비' },
  { key: 7, name: '경비비' },
  { key: 8, name: '승강기유지비' },
  { key: 9, name: '수선유지비' },
  { key: 2, name: '인터넷' },
  { key: 5, name: '유선TV' },
  { key: 6, name: '기타' },
];

// 개별사용료 항목
export const personalMaintenances = [
  { key: 10, name: '난방비' },
  { key: 0, name: '전기료' },
  { key: 1, name: '수도료' },
  { key: 4, name: '가스사용료' },
];

// 방향 항목
export const directions = [
  { key: 0, name: '동' },
  { key: 1, name: '서' },
  { key: 2, name: '남' },
  { key: 3, name: '북' },
  { key: 4, name: '북동' },
  { key: 5, name: '남동' },
  { key: 6, name: '남서' },
  { key: 7, name: '북서' },
];

// 추가옵션(가전/가구) 항목
export const etcs = [
  { key: 0, name: '에어컨' },
  { key: 1, name: '세탁기' },
  { key: 2, name: '침대' },
  { key: 3, name: '책상' },
  { key: 4, name: '옷장' },
  { key: 5, name: 'TV' },
  { key: 6, name: '신발장' },
  { key: 7, name: '냉장고' },
  { key: 8, name: '가스레인지' },
  { key: 9, name: '인덕션' },
  { key: 10, name: '전자레인지' },
  { key: 11, name: '전자도어락' },
  { key: 12, name: '비데' },
];

// 추가옵션(보안시설) 항목
export const safety = [
  { key: 0, name: '비디오폰' },
  { key: 1, name: '현관보안' },
  { key: 2, name: '경비원' },
  { key: 3, name: 'CCTV' },
  { key: 4, name: '방범창' },
  { key: 5, name: '인터폰' },
  { key: 6, name: '카드키' },
  { key: 7, name: '화재경보기' },
];

// 중개 의뢰 방법
export const requestMethods = [
  { label: '의뢰인과의 유선전화로 접수', value: 0 },
  { label: '의뢰인과 직접 대면하여 접수', value: 1 },
  { label: '다방 프로 공실센터로 접수', value: 2 },
  { label: '직접입력 (2~30자)', value: 3 },
];

export const roomFloorUnits = [
  { label: '저층', value: 0 },
  { label: '중층', value: 1 },
  { label: '고층', value: 2 },
];

export const buildingCategorys = [
  { value: 'HOUSE_VILLA', name: '주택/빌라' },
  { value: 'OFFICETEL', name: '오피스텔' },
  { value: 'APT', name: '아파트(도시형)' },
];

export default {
  roomTypes,
  buildingTypes,
  sellingTypes,
  buildingPurpose,
  buildingApproval,
  heatTypes,
  heatTypesComplex,
  maintenances,
  personalMaintenances,
  directions,
  etcs,
  safety,
  requestMethods,
  roomFloorUnits,
  buildingCategorys,
};

import { createModel } from '@rematch/core';

const call = createModel({
  state: {
    get: () => {},
    post: () => {},
  },
  reducers: {
    init(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default call;

import React, { ButtonHTMLAttributes, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { TSvgIconName } from '~/@types/svg-icon';
import isMobile from '~/utils/is-mobile';
import SvgIcon from '~/components/svg-icon';
import btnStd from '~/styled/button';

import { TColor, TSize } from './type';

function hover(color: string) {
  if (isMobile()) return '';
  return css`
    &:hover {
      background-color: ${color};
    }
  `;
}

function getColor(color: TColor) {
  switch (color) {
    case 'blue':
      return css`
        background-color: ${({ theme }) => theme.color.blue['500']};
        ${({ theme }) => hover(theme.color.blue['600'])};
      `;

    case 'red':
      return css`
        background-color: ${({ theme }) => theme.color.red['500']};
        ${({ theme }) => hover(theme.color.red['600'])};
      `;

    case 'gray':
      return css`
        background-color: ${({ theme }) => theme.color.gray['900']};
        ${({ theme }) => hover(theme.color.gray['800'])};
      `;
  }

  return '';
}

function getSize(size: TSize) {
  switch (size) {
    case 'sm':
      return css`
        height: 28px;
        ${({ theme }) => theme.font.caption1.bold};
      `;

    case 'md':
      return css`
        height: 36px;
        ${({ theme }) => theme.font.body3.bold};
      `;

    case 'lg':
      return css`
        height: 44px;
        ${({ theme }) => theme.font.body3.bold};
      `;

    case 'xl':
      return css`
        height: 56px;
        ${({ theme }) => theme.font.body3.bold};
      `;
  }

  return '';
}

const SolidButton = styled.button<{ contentWidth: string; btnColor: TColor; btnSize: TSize; }>`
  ${btnStd};
  width: ${({ contentWidth }) => contentWidth};
  padding: 0 16px;
  color: ${({ theme }) => theme.color.white};
  
  > svg + span,
  > span + svg{
    margin-left: 4px;
  }
  
  ${({ btnColor }) => getColor(btnColor)};
  ${({ btnSize }) => getSize(btnSize)};

  &:disabled {
    background-color: ${({ theme }) => theme.color.gray['400']};

    > svg {
      path,
      circle,
      rect {
        fill: ${({ theme }) => theme.color.white} !important;
      }
    }
  }
`;

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  contentWidth?: string;
  iconPosition?: 'left' | 'right';
  iconName?: TSvgIconName;
  btnColor: TColor;
  btnSize: TSize;
}

const SolidButtonComponent = forwardRef<HTMLButtonElement, Props>(({
  contentWidth = 'auto',
  btnColor,
  btnSize,
  iconPosition = 'left',
  iconName,
  children,
  ...props
}, ref) => {
  return (
    <SolidButton
      {...props}
      ref={ref}
      btnColor={btnColor}
      btnSize={btnSize}
      contentWidth={contentWidth}
    >
      {iconName && iconPosition === 'left' && <SvgIcon name={iconName} />}
      <span>{children}</span>
      {iconName && iconPosition === 'right' && <SvgIcon name={iconName} />}
    </SolidButton>
  );
});

export default SolidButtonComponent;

import { useEffect, useState } from 'react';
import Cookie from 'js-cookie';

function useNoItem(resetType) {
  const [isShow, setShow] = useState(true);

  useEffect(() => {
    checkCookie();
  }, []);

  function checkCookie() {
    const cookie = Cookie.get(`noItem`);

    if (cookie) {
      setShow(false);
    }
  }

  function clickClose() {
    resetType({ isCloseNoItem: true });
  }

  function clickCloseWithExpire() {
    Cookie.set(`noItem`, 0, { expires: 3 });
    clickClose();
  }

  return { isShow, clickClose, clickCloseWithExpire };
}

export default useNoItem;
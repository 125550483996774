import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { guideUrl } from '~/enum/config';

import useUserSession from '~/hooks/useUserSession';
import useDropdown from './useDropdown';

import SvgIcon from '~/components/svg-icon';

import { Drowndown, DropdownMenuList } from './styled';

function DropdownComponent() {
  const dispatch = useDispatch();
  const userName = useSelector(({ user }: { user: any }) => user.userName);

  const { deleteUser } = useUserSession();
  const { isOpen, handler } = useDropdown();

  function logout() {
    // @ts-ignore
    dispatch.user.logoutAsync();
    deleteUser();
  }

  return (
    <Drowndown className="dropdown">
      <button onClick={handler}>
        <p>{userName}</p>
        <span>님</span>
        <SvgIcon name="ic_24_arrow_down_gray900" />
      </button>

      {isOpen && (
        <DropdownMenuList>
          <li>
            <Link to="/dashboard" onClick={handler}>대시보드</Link>
          </li>
          <li>
            <Link to="/account/user" onClick={handler}>나의 계정</Link>
          </li>
          <li>
            <a href={guideUrl} target="_blank" rel="noopener noreferrer" onClick={handler}>이용 가이드</a>
          </li>
          <li>
            <button onClick={logout}>로그아웃</button>
          </li>
        </DropdownMenuList>
      )}
    </Drowndown>
  );
}

export default DropdownComponent;

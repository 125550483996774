import isEqual from 'date-fns/isEqual';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import set from 'date-fns/set';
import subDays from 'date-fns/subDays';
import addYears from 'date-fns/addYears';

export const dateReg = /^([12]\d{3})[. ]?[- ]?(0[1-9]|1[0-2])[. ]?[- ]?(0[1-9]|[12]\d|3[01])/g;

/**
 * date가 start와 end 사이의 값인가 체크하는 함수
 * date가 start 또는 end와 동일한 날일경우도 사이값이라고 판단한다.
 * @param {Date} date -> 검사할 날짜
 * @param {Date} start -> 시작일
 * @param {Date} end -> 종료일
 */
export function isBetween(date: Date, start: Date, end: Date) {
  // isAfter(date, compare) date가 compare보다 이후인가
  // isBefore(date, compare) date가 compare보다 이전인가
  if (isSameOrAfter(date, start) && isSameOrBefore(date, end)) return true;
  return false;
}

/**
 * date가 start와 end 사이의 시간인가 체크하는 함수
 * @param {Date} date -> 검사할 날짜
 * @param {Date} start -> 시작일
 * @param {Date} end -> 종료일
 */
export function isBetweenHour(date: Date, start: Date, end: Date) {
  // isAfter(date, compare) date가 compare보다 이후인가
  // isBefore(date, compare) date가 compare보다 이전인가
  if (isAfter(date, start) && isBefore(date, end)) return true;
  return false;
}

/**
 * date 가 to 이전 날 이거나 같은 날인지 확인
 * @param {Date} date -> 검사할 날짜
 * @param {Date} to -> 범위 날짜
 */
export function isSameOrBefore(date: Date, to: Date) {
  if (isEqual(date, to) || isBefore(date, to)) return true;
  return false;
}

/**
 * date 가 to 다음 날 이거나 같은 날인지 확인
 * @param {Date} date -> 검사할 날짜
 * @param {Date} to -> 범위 날짜
 */
export function isSameOrAfter(date: Date, to: Date) {
  if (isEqual(date, to) || isAfter(date, to)) return true;
  return false;
}

type TSetOption = {
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}
export function setTime(date: Date = new Date(), changeTimes: TSetOption = {}) {
  const { hours, minutes, seconds, milliseconds } = Object.assign(
    {},
    { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 },
    changeTimes
  );

  return set(date, { hours, minutes, seconds, milliseconds });
}

/**
 * 받은 날짜(date)가 지정된 일 수 를 뺸 날짜에 포함되는지 체크하는 함수
 * @param {Date|String} date 날짜
 * @param {number} days 지정된 일 수
 */
export function isNew(date: Date | string, days: number = 0) {
  if (!date) throw Error('date를 입력해주세요.');

  const now = setTime(new Date());
  const d = setTime(date instanceof Date ? date : new Date(date));

  if (isAfter(d, subDays(now, days))) return true;
  return false;
}

/**
 * 현재로 부터 min분뒤의 날짜시간을 알려주는 함수
 * @returns {Date} 현재로부터 min분이 더해진 시간
 */
export function addMinuteFromNow(min: number) {
  const nowTime = new Date().getTime();
  const minute = min * 60 * 1000;
  const nextTime = nowTime + minute;
  return new Date(nextTime);
}

/**
 * 현재로 부터 h시간 뒤의 날짜시간을 알려주는 함수
 * @returns {Date} 현재로부터 h시간이 더해진 시간
 */
export function addHourFromNow(h: number) {
  const nowTime = new Date().getTime();
  const hour = h * 60 * 60 * 1000;
  const nextTime = nowTime + hour;
  return new Date(nextTime);
}

/**
 * 현재로 부터 d날 뒤의 날짜시간을 알려주는 함수
 * @returns {Date} 현재로부터 d일이 더해진 시간
 */
export function addYearFromNow(y: number) {
  const now = new Date();
  const nextTime = addYears(now, y);
  return new Date(nextTime);
}

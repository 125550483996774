import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
  
  > h1  {
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body2.bold};
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 40px;
`;


export const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  button + button {
    margin-left: 8px;
  }
`;

export const LoadingWrap = styled.div`
  width: 100%;
  height: 354px;
`;

export const CountChip = styled.div`
  padding: 0px 8px;
  ${({ theme }) => theme.font.body2.bold};
  color: ${({ theme }) => theme.color.gray[900]};
  background-color: ${({ theme }) => theme.color.gray[200]};
  border: 0;
  border-radius: 20px;

  > span {
    color: ${({ theme }) => theme.color.blue[500]};
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  > div.text {
    > p {
      ${({ theme }) => theme.font.body1.bold};
      color: ${({ theme }) => theme.color.gray[900]};
  
      &.red {
        color: ${({ theme }) => theme.color.red[500]};
      }
    }
  }
`;

export const TextWrap = styled.div`
  ${({ theme }) => theme.font.body3.regular};
  color: ${({ theme }) => theme.color.gray[700]};
`;

export const Box = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 24px auto;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.gray[200]};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};

  > li {
    display: flex;
    width: 100%;

    > div {
      &.th {
        display: flex;
        align-items: center;
        padding: 20px;
        width: 150px;
        color: ${({ theme }) => theme.color.gray[900]};
        ${({ theme }) => theme.font.body3.bold};
        background-color: ${({ theme }) => theme.color.gray[50]};
      }

      &.td {
        flex-grow: 1;
        display: flex;
        align-items: center;
        padding: 20px;
        width: 0;
        color: ${({ theme }) => theme.color.gray[900]};
        ${({ theme }) => theme.font.body3.regular};
      }

      &.column {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
    }

    div + div {
      border-left: 1px solid ${({ theme }) => theme.color.gray[200]};
    }
  }

  li + li {
    border-top: 1px solid ${({ theme }) => theme.color.gray[200]};
  }
`;

export const SelectWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.gray[100]};
`;

export const Notice = styled.div`
  margin-top: 8px;
  text-align: center;

  > p {
    ${({ theme }) => theme.font.body3.bold};
    color: ${({ theme }) => theme.color.blue[500]};
    text-align: center;
  }
`;

export const InfoList = styled.ul`
  margin-top: 24px;
  padding: 20px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.red[50]};
  border: 0;
  border-radius: 2px;

  > p {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.color.red[500]};
    ${({ theme }) => theme.font.caption1.bold};

    > svg {
      margin-right: 4px;
    }
  }

  > li {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: ${({ theme }) => theme.color.gray[700]};
    ${({ theme }) => theme.font.caption1.regular};
    
    > div {
      flex-grow: 1;
    }

    &:before {
      flex: none;
      content: '-';
      display: block;
      margin-right: 2px;
      color: inherit;
      ${({ theme }) => theme.font.caption1.regular};
    }
  }
`;

export const TextBtn = styled.button`
  margin-top: 4px;
  color: ${({ theme }) => theme.color.blue[500]};
  ${({ theme }) => theme.font.body3.bold};
  border: 0;
  background-color: transparent;
  text-decoration: underline;
`;

import React from 'react';

import useInquirySetting from './useInquirySetting';

import { Checkbox, LineBtn, SolidBtn, TextInput } from '~/atoms2';
import { Popup } from '~/components/modal2';

import {
  Header,
  Content, Desc,
  ListGroup, CheckGroup, RepresentNumber, TalkSettingWrap,
  BtnWrap,
} from './styled';

interface Props {
  contactMethodTypes: Common.TContactMethodType[];
  chatContactMessage?: string;
  hideCloseBtn?: boolean;
  closePopup: (needReload: boolean) => void;
}

function InquirySettingPopup({
  contactMethodTypes,
  chatContactMessage = '',
  hideCloseBtn = false,
  closePopup,
}: Props) {

  const {
    isSending, state,
    changeCheckbox, changeTalkMessage,
    isActiveSubmitBtn, clickSubmit,
  } = useInquirySetting({
    contactMethodTypes,
    chatContactMessage,
    closePopup,
  });

  return (
    <Popup
      popupWidth={520}
      closePopup={() => closePopup(false)}
      escToClose={false}
    >
      <Header>
        <h1>문의설정</h1>
      </Header>

      <Content>
        <Desc>
          <h1>
            문의 받으실 방식을 선택해주세요.
          </h1>

          <div>
            <p>해당 계정으로 등록되어 있는 매물에 일괄 적용됩니다.</p>
            <p>전화문의 연락처는 등록관청에 신고된 정보와 일치해야 합니다.</p>
          </div>
        </Desc>

        <ListGroup>
          <li>
            <CheckGroup>
              <h1>문자문의</h1>

              <p>고객의 문의 내용과 함께 연락처를 안심번호로 제공 받으실 수 있습니다.</p>

              <Checkbox
                name="SMS"
                checked={state.contactMethodTypes.includes('SMS')}
                onChange={changeCheckbox.bind(null, 'SMS')}
                disabled={isSending}
              />
            </CheckGroup>
          </li>

          <li>
            <CheckGroup>
              <h1>톡상담</h1>

              <p>고객과 1:1 채팅 상담을 진행할 수 있으며, 더 많은 연락/문의를 실시간으로 받을 수 있습니다.</p>

              <Checkbox
                name="CHAT_TALK"
                checked={state.contactMethodTypes.includes('CHAT_TALK')}
                onChange={changeCheckbox.bind(null, 'CHAT_TALK')}
                disabled={isSending}
              />
            </CheckGroup>

            {state.contactMethodTypes.includes('CHAT_TALK') && (
              <TalkSettingWrap>
                <h1>톡상담 상태메세지 설정</h1>
                <p>톡상담을 원하는 다방 회원님에게 노출됩니다.</p>
                <TextInput
                  max={20}
                  value={state.chatContactMessage}
                  onChange={changeTalkMessage}
                  placeholder="예) 오전9시 ~ 오후6시까지만 문의해주세요."
                  disabled={isSending}
                />
              </TalkSettingWrap>
            )}
          </li>

          <li>
            <CheckGroup>
              <h1>전화문의</h1>

              <RepresentNumber>
                대표/소속중개사의 경우 등록된 <span>휴대 전화번호</span>로 연결됩니다.
              </RepresentNumber>

              <Checkbox
                name="CALL"
                checked={state.contactMethodTypes.includes('CALL')}
                onChange={changeCheckbox.bind(null, 'CALL')}
                disabled={isSending}
              />
            </CheckGroup>
          </li>
        </ListGroup>
      </Content>

      <BtnWrap>
        {!hideCloseBtn && (
          <LineBtn
            contentWidth="120px"
            btnColor="gray"
            btnSize="xl"
            onClick={closePopup.bind(null, false)}
            disabled={isSending}
          >
            취소
          </LineBtn>
        )}

        <SolidBtn
          contentWidth="120px"
          btnColor="blue"
          btnSize="xl"
          onClick={clickSubmit}
          disabled={!isActiveSubmitBtn || isSending}
        >
          완료
        </SolidBtn>
      </BtnWrap>
    </Popup>
  );
}

export default InquirySettingPopup;

import { createModel } from '@rematch/core';

const toast = createModel({
  state: {
    success: () => {},
    info: () => {},
    warn: () => {},
    error: () => {},
  },
  reducers: {
    init(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export default toast;

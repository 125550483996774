import { createModel } from '@rematch/core';
import { produce } from 'immer';

import { maintenances, personalMaintenances, etcs, safety } from '~/enum/form/option';
import isZero from '~/utils/valid/isZero';
import { setMovingDate } from '~/utils/form';

import state, { createList } from './state';

const { confirm } = window;

const form = createModel({
  state: state,
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    updateRoomType(_, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    updateAllOptions(state, { name, checked }) {
      return produce(state, draft => {
        draft[name].forEach((_, i) => {
          draft[name][i].isActive = checked;
        });
      });
    },
    reset() {
      return state;
    },
  },
  effects: {
    /**
     * roomType 변경
     * @param {*} roomType
     * @param {*} param1
     */
    changeRoomType(roomType, { form }) {
      if (!confirm('매물 유형 변경 시 현재 입력중인 정보가 초기화됩니다. 매물 유형을 변경 하시겠습니까?')) return;

      const temp = {
        isUseCorrectAddress: form.buildingType === 0 && form.principalUse === 'DETACHED_HOUSE',
        isUseRoomFloorUnit: false,
        isNewConstruction: null,
      };
      if (+roomType === 0) {
        temp.buildingType = 2;

      } else if (+roomType === 3) {
        temp.buildingType = 4;

      } else if (+roomType === 4) {
        temp.buildingType = '';
      }

      // 초기화 되면 안되는 값
      // 고려할점 공실센터, 확인매물은 룸타입 수정이 불가능하다
      temp.isSendLh = form.isSendLh;
      temp.isEdit = form.isEdit;
      temp.isTemp = form.isTemp;
      temp.roomId = form.roomId;
      temp.tempRoomId = form.tempRoomId;

      this.updateRoomType({ ...temp, roomType });
    },

    /**
     * 미등기건물 여부 플래그 변경
     */
    changeNewConstruction(isNewConstruction, { form: { buildingApprovalType } }) {
      const temp = {
        movingDate: '',
      };

      if (!isNewConstruction && +buildingApprovalType === 3) {
        temp.buildingApprovalType = '';
        temp.buildingApprovalDate = '';
      }

      this.update({ ...temp, isNewConstruction });
    },

    /**
     * 전세, 월세, 매매 추가
     */
    addDeal(type, { form: { roomType } }) {
      const deal = type => ({
        type,         // 거래 타입  [ 0: 월세 / 1: 전세 / 2: 매매 / 3: 세안고 ]
        deposit: '',  // 보증금
        price: '',    // 월세
      });

      // 매매 선택
      if (type === 2) {
        const temp = {
          roomPrices: [deal(type)],
          isSale: true,
          isContract: false,
          isUseCorrectContract: false,
          shortLease: false,
          animal: null,
          loan: null,
          isTakeTenant: false,
          takeTenantPrice: {
            price: '',
            deposit: '',
          },
        };

        // 아파트 일경우
        if (+roomType === 4) {
          temp.maintenance = false;
          temp.maintenanceCost = '';
          temp.maintenanceItems = createList(maintenances);
          temp.personalMaintenance = false;
          temp.personalMaintenanceItems = createList(personalMaintenances);
          temp.parking = false;
          temp.parkingNum = '';
          temp.parkingAverage = '';
          temp.parkingCost = '';
          temp.balcony = false;
          temp.builtIn = false;
          temp.division = false;
          temp.duplex = false;
          temp.roomOptions = createList(etcs);
          temp.safeties = createList(safety);
        }

        this.update(temp);

        // 월세 || 전세 선택
      } else {
        this.update({
          roomPrices: [deal(type)],
          isTakeTenant: false,
          takeTenantPrice: {
            price: '',
            deposit: '',
          },
          isSale: false,
          isContract: false,
          isUseCorrectContract: false,
        });
      }
    },

    changePrice({ type, name, value, inputCheck }, { form: { roomPrices } }) {
      if (value.match(/e|-|\+|=| |,|\./g)) return;
      if (!/\d/.test(+value)) return;

      const nextInfo = produce(roomPrices, draft => {
        const idx = draft.findIndex(i => +i.type === +type);
        draft[idx][name] = value;
      });

      this.update({ roomPrices: nextInfo, isConfirmPricesCheck: inputCheck });
    },

    changeTakeTenant(checked) {
      const temp = {
        isTakeTenant: checked,
      };

      if (!checked) {
        temp.takeTenantPrice = {
          price: '',
          deposit: '',
        };
      }

      this.update(temp);
    },

    changeTakeTenantPrice({ name, value }, { form: { takeTenantPrice } }) {
      if (value.match(/e|-|\+|=| |,|\./g)) return;
      if (!/\d/.test(+value)) return;

      const next = produce(takeTenantPrice, draft => {
        draft[name] = value;
      });

      this.update({ takeTenantPrice: next });
    },

    /**
     * 옵션 변경 / 건축물용도(층별용도) 변경
     * @param {String} name
     * @param {String|Number} value
     * @param {Boolean} checked
     */
    changeOptions({ name, value, checked }, { form }) {
      const nextOptions = produce(form[name], draft => {
        const idx = draft.findIndex(i => i.id === +value);
        draft[idx].isActive = checked;
      });

      const temp = {
        [name]: nextOptions,
      };

      if (name === 'maintenanceItems' && !form.maintenance && checked) {
        temp['maintenance'] = true;
      }

      if (name === 'personalMaintenanceItems' && !form.personalMaintenance && checked) {
        temp['personalMaintenance'] = true;
      }

      this.update(temp);
    },

    /**
     * 파일 업로드시 초기 값 추가
     * @param {String} id 이미지 파일 uuid
     */
    uploadPhoto({ id }, { form: { photos } }) {
      const nextPhotos = produce(photos, draft => {
        draft.push({
          id,
          key: null,
          isLoading: true,
          prod: false,
        });
      });

      this.update({
        photos: nextPhotos,
        isUploading: true,
      });
    },

    /**
     * 이미지 업로드 성공
     * key에 id값을 저장한다.
     * @param {String} id 이미지 uuid
     */
    uploadSuccess({ id }, { form: { photos } }) {
      const nextPhotos = produce(photos, draft => {
        const idx = draft.findIndex(i => i.id === id);
        draft[idx].key = id;
        draft[idx].isLoading = false;
        // draft[idx].progress = 100;
      });

      this.update({ photos: nextPhotos });
    },

    /**
     * 이미지 삭제
     * @param {String} id 이미지 uuid
     */
    removePhoto({ id }, { form: { photos } }) {
      const nextPhotos = produce(photos, draft => {
        const idx = draft.findIndex(i => i.id === id);
        draft.splice(idx, 1);
      });

      this.update({ photos: nextPhotos });
    },

    setRoomInfo({ room, complex, option }, {
      user: {
        userRoleType,
      },
      form: {
        isEdit,
        isTemp,
        isOwner,
      },
    }) {
      function createList(list, actives) {
        return list.map(i => ({
          id: +i.key,
          name: i.name,
          isActive: actives?.includes(+i.key) ?? false,
        }));
      }

      function formatPhoto(list) {
        return list.map(p => {
          return {
            id: p,
            key: p,
            isLoading: false,
            prod: (isTemp || (isOwner && !isEdit)) ? false : true,
          };
        });
      }

      const roomPrices = room?.gongsilPrices ?? room?.roomPrices ?? [{
        type: 0,
        deposit: '',
        price: '',
      }];
      const roomFloor = room?.floor ?? room?.roomFloor ?? '';

      const isApt = +room?.roomType === 4;
      const isOfficetel = +room?.roomType === 3;
      const isComplex = isApt || isOfficetel;

      const temp = {
        isSendLh: room?.isSendLh ?? true,

        isConfirm: room?.isConfirm ?? false,
        isSale: !!roomPrices.find(p => +p.type === 2),
        roomId: isTemp ? '' : room?.id ?? '',

        roomType: room.roomType < 3 ? 0 : room.roomType,
        buildingType: room.roomType === 4 ? '' : room?.buildingType > 9 ? '' : room?.buildingType ?? 0,

        location: room?.location ?? [],
        randomLocation: room?.randomLocation ?? room?.location ?? [],
        jibunAddress: room?.jibunAddress ?? '',
        roadAddress: room?.roadAddress ?? '',
        buildingName: room?.complexName ?? complex?.complexName ?? '',
        dong: room?.dong ?? '',
        ho: room?.ho ?? '',
        isNoinfoDong: room?.isNoinfoDong ?? false,
        roomPrices,
        shortLease: room?.shortLease ?? false,
        isTakeTenant: room?.isTakeTenant ?? false,
        takeTenantPrice: {
          price: room?.takeTenantPrice?.price ?? '',
          deposit: room?.takeTenantPrice?.deposit ?? '',
        },
        beforeRoomPrices: room?.roomPrices,
        beforeTakeTenantPrice: room?.takeTenantPrice,

        isUseCorrectAddress: room?.buildingType === 0 && room.principalUseType === 'DETACHED_HOUSE' ? isEdit ? room?.isUseCorrectAddress ?? true : true : false,
        isNewConstruction: room?.isNewConstruction ?? false,

        principalUseType: room?.principalUseType ?? '',
        principalUse: room?.principalUse ?? '',
        buildingApprovalType: room?.buildingApprovalType ?? '',
        buildingApprovalDate: room?.buildingApprovalDate ?? '',

        buildingFloor: room?.buildingFloor ?? (complex?.complexHighestFloor < 1 ? '' : complex?.complexHighestFloor ?? ''),
        roomFloor: complex?.complexHighestFloor ? (complex?.complexHighestFloor < roomFloor ? '' : roomFloor) : roomFloor,
        roomFloorUnit: room?.roomFloorUnit ?? '',
        isUseRoomFloorUnit: room?.isUseRoomFloorUnit ?? false,

        heatingType: room?.heatingType ?? complex?.heatType ?? '',
        movingDate: setMovingDate(room?.movingDate) ?? '',
        direction: room?.direction ?? '',
        isMovingDateNegotiation: room?.isMovingDateNegotiation ?? false,

        bedsNum: room?.bedsNum ? room.bedsNum < 3 ? room.bedsNum : 'write' : '',
        bedsNumCount: room?.bedsNum ? room.bedsNum < 3 ? '' : room.bedsNum : '',
        bathNum: room?.bathNum ? room.bathNum < 3 ? room.bathNum : 'write' : '',
        bathNumCount: room?.bathNum ? room.bathNum < 3 ? '' : room.bathNum : '',

        maintenance: room?.maintenance ?? true,
        maintenanceCost: room?.maintenanceCost ?? '',
        maintenanceItems: createList(maintenances, room?.maintenanceItems ?? []),
        maintenanceOption: '',

        personalMaintenance: room?.personalMaintenance ?? true,
        personalMaintenanceItems: createList(personalMaintenances, room?.personalMaintenanceItems ?? []),

        parking: room?.parking ?? (isComplex && isOwner),
        parkingNum: room?.parking ? room?.parkingNum ?? complex?.parkingNum ?? '' : '',
        parkingAverage: room?.parking ? room?.parkingAverage ?? complex?.parkingAverage ?? '' : '',
        parkingCost: room?.parking ?  room?.parkingCost ?? '' : '',

        isContract: room?.isContract ?? false,

        isParkingCostFree: room?.isParkingCostFree,

        animal: null,
        elevator: room?.elevator ?? false,
        balcony: room?.balcony ?? false,
        builtIn: room?.builtIn ?? false,
        division: room?.division ?? false,
        duplex: room?.duplex ?? false,
        loan: null,

        roomOptions: createList(etcs, room?.roomOptions ?? []),
        safeties: createList(safety, room?.safeties ?? []),

        photos: formatPhoto(room?.photos ?? []),
        pano: room?.pano ?? null,

        title: room?.title ?? '',
        memo: room?.memo ?? '',
        privateMemo: isEdit ? room?.privateMemo ?? '' : isOwner ? room?.message ?? '' : room?.privateMemo ?? '',

        isConfirmRequest: isOwner ? false : room?.isConfirmRequest ?? false,
        ownerName: isOwner ? '' : room?.ownerName ?? '',
        ownerPhone: isOwner ? '' : room?.ownerPhone ?? '',
        isAgreeConfirm: false,

        requestMethod: isOwner && !isEdit ? 2 : room?.requestMethod ?? '',
        requestMethodMessage: room?.requestMethod === 3 ? room?.requestMethodMessage ?? '' : '',

        deleted: complex?.deleted ?? false,
      };


      // 매물 재등록시 소유자 정보 입력한 경우 해당값 넣어줌
      if (option?.from === 'reopen' && option?.refresh) {
        temp.isConfirmRequest = option.isConfirmRequest ?? false;
        temp.isConfirm = option.isConfirmRequest;

        if (option.isConfirmRequest) {
          temp.ownerName = option.ownerName ?? '';
          temp.ownerPhone = option.ownerPhone.split('-');
        }
      }

      // 공용관리비가 없으니까 나머지 초기화
      if (!temp.maintenance) {
        temp.maintenanceCost = '';
        temp.maintenanceItems = createList(maintenances, []);
      }
      // 개별사용료가 없으니까 나머지 초기화
      if (!temp.personalMaintenance) {
        temp.personalMaintenanceItems = createList(personalMaintenances, []);
      }

      // 매매일 경우 초기화
      if (temp.isSale) {
        temp.shortLease = false;
        temp.animal = null;
        temp.loan = null;
      }

      // 단지
      if (isComplex) {
        temp.maxFloor = complex?.complexHighestFloor < 1 ? 50 : complex?.complexHighestFloor ?? 50;
        temp.complexSeq = complex?.complexSeq ?? '';
        temp.spaceSeq = room?.complexSpaceSeq ?? room?.spaceSeq ?? '';
        temp.elevator = room?.elevator ?? false;

        // 아파트 매매일경우 초기화
        if (room.roomType === 4) {
          if (temp.isSale) {
            temp.parking = false;
            temp.parkingNum = '';
            temp.parkingAverage = '';
            temp.parkingCost = '';
            temp.maintenance = false;
            temp.maintenanceCost = '';
            temp.maintenanceItems = createList(maintenances);
            temp.personalMaintenance = false;
            temp.personalMaintenanceItems = createList(personalMaintenances);
            temp.roomOptions = createList(etcs);
            temp.safeties = createList(safety);
          }

          temp.balcony = false;
          temp.builtIn = false;
          temp.division = false;
          temp.duplex = false;
        }

      } else {
        temp.roomSize = room?.exclusiveSpace ? (isZero(room.exclusiveSpace) ? '' : room.exclusiveSpace) : room?.roomSize ?? '';

        const contractSize = room?.contractSpace ? (isZero(room.contractSpace) ? '' : room.contractSpace) : room?.contractSize ?? '';
        const provisionSize = room?.supplySpace ? (isZero(room.supplySpace) ? '' : room.supplySpace) : room?.provisionSize ?? '';

        if (!!provisionSize || !!contractSize) {
          temp.contractSize = contractSize;
          temp.provisionSize = provisionSize;
          temp.isUseSupplySpace = true;

        } else {
          temp.contractSize = '';
          temp.provisionSize = '';
          temp.isUseSupplySpace = false;
        }
      }

      if (userRoleType === 2) {
        temp.isContract = false;
      }

      this.update(temp);
    },
  }
});

export default form;

import React from 'react';

import { LineBtn, SolidBtn } from '~/atoms2';
import SvgIcon from '~/components/svg-icon';

import { Header, Body, Text, TextWrap, BtnWrap } from './styled';

export function PenaltyContainerComponent({
  close,
  changeOpenType
}: {
  close: () => void;
  changeOpenType: () => void
}) {
  return (
    <>
      <Header>
        <h1>매물 등록 제한</h1>
      </Header>

      <Body>
        <Text className="body3-bold">매물 등록 제한</Text>

        <Text className="body3" style={{ marginTop: '16px' }}>부동산매물클린관리센터 금지 매물 게재  조치 위반으로 매물 등록이 제한됩니다.</Text>
        <Text className="body3">페널티 기간 및 사유에 대한 자세한 확인은  부동산매물클린관리센터 페널티 현황에서 </Text>
        <Text className="body3">확인하시기 바랍니다.</Text>

        <TextWrap>
          <SvgIcon name="ic_24_exclamation_mark_gray_solid" />
          <Text className="caption1">제재 조치에 대한 이의신청은 부동산매물클린관리센터(1600-7186)로</Text>
          <Text className="caption1">문의하시기 바랍니다.</Text>
        </TextWrap>

        <BtnWrap>
          <LineBtn
            btnSize="xl"
            btnColor="gray"
            contentWidth="120px"
            onClick={close}
          >
            닫기
          </LineBtn>

          <SolidBtn
            btnSize="xl"
            btnColor="blue"
            contentWidth="120px"
            onClick={changeOpenType}
          >
            페널티 확인
          </SolidBtn>
        </BtnWrap>
      </Body>
    </>
  );
}

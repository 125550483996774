export default {
  // 임시 매물 리스트 로딩
  sending: true,
  // 임시 매물 리스트 로딩
  loading: true,
  // 임시 매물 리스트
  rooms: [],
  // 페이지
  page: 1,
  // 제한 개수
  limit: 10,
  // 총 매물 개수
  total: 0,

  // 선택된 임시 매물
  selectRooms: [],
  
  // 매물 이동 팝업 플래그
  open: false,
};

import React, { createContext, useContext, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import { produce } from 'immer';
import generateUUID from '~/utils/generateUUID';

import ToastController from './Controller';

import { TOP_RIGHT } from './common';
import { ToastHub } from './styled';

const ToastContext = createContext();

export default function ToastProvider({
  autoDismiss, autoDismissTimeout, position, children
}) {
  const _isMount = useRef(false);
  const _toasts = useRef([]);
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    _isMount.current = true;
    return () => {
      _isMount.current = false;
    };
  }, []);

  function has(id) {
    if (!toasts.length) return false;
    return Boolean(toasts.filter(t => t.id === id).length);
  }

  function add(content, { type }) {
    if (!_isMount.current) return;

    const id = generateUUID();

    if (has(id)) return;

    const nextToasts = produce(_toasts.current, draft => {
      const newToast = { content, id, type };
      draft.push(newToast);
    });

    _toasts.current = nextToasts;
    setToasts(nextToasts);
  }

  function remove(id) {
    return () => {
      if (!_isMount.current) return;

      if (!has(id)) return;

      const nextToasts = produce(_toasts.current, draft => {
        const idx = draft.findIndex(t => t.id === id);
        draft.splice(idx, 1);
      });

      _toasts.current = nextToasts;
      setToasts(nextToasts);
    };
  }

  const toastsFreeze = Object.freeze(toasts);
  const hasToasts = Boolean(toasts);

  return (
    <ToastContext.Provider value={{ add, toasts }}>
      {children}

      {createPortal(
        <ToastHub hasToasts={hasToasts} position={position}>
          <TransitionGroup component={null}>
            {toastsFreeze.map(({ type, content, id }) => (
              <Transition key={id} appear={true} mountOnEnter={true} timeout={220} unmountOnExit={true}>
                {transitionState => (
                  <ToastController
                    key={id}
                    type={type}
                    autoDismiss={autoDismiss}
                    autoDismissTimeout={autoDismissTimeout}
                    onDismiss={remove(id)}
                    position={position}
                    transitionState={transitionState}
                  >
                    {content}
                  </ToastController>
                )}
              </Transition>
            ))}
          </TransitionGroup>
        </ToastHub>,
        document.body
      )}
    </ToastContext.Provider>
  );
}

ToastProvider.defaultProps = {
  autoDismiss: true,
  autoDismissTimeout: 5000,
  position: TOP_RIGHT,
};

export const useToast = () => {
  const ctx = useContext(ToastContext);

  if (!ctx) {
    // throw Error('The `useToast` hook must be called from a descendent of the `ToastProvider`.');
    console.log('The `useToast` hook must be called from a descendent of the `ToastProvider`.');
  }

  const { add, toasts } = ctx;

  function isEmpty(text) {
    if (text === '' || text === null || text === undefined) return true;
    return false;
  }

  const success = useCallback(content => {
    if (!ctx) return content && alert(content);
    return isEmpty(content) ? null : add(content, { type: 'success' });
  }, [toasts]);

  const info = useCallback(content => {
    if (!ctx) return content && alert(content);
    return isEmpty(content) ? null : add(content, { type: 'info' });
  }, [toasts]);

  const warning = useCallback(content => {
    if (!ctx) return content && alert(content);
    return isEmpty(content) ? null : add(content, { type: 'warning' });
  }, [toasts]);

  const error = useCallback(content => {
    if (!ctx) return content && alert(content);
    return isEmpty(content) ? null : add(content, { type: 'error' });
  }, [toasts]);

  return {
    success,
    info,
    warning,
    error,
  };
};

import { useRef, useLayoutEffect, useEffect, useCallback } from 'react';

function useModal({ id, handle, escToClose }) {
  const _elem = useRef(document.createElement('div'));
  _elem.current.id = id ? id : 'modal';

  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';

    return () => (document.body.style.overflow = originalStyle);
  }, []);

  const onKeyDown = useCallback(e => {
    if (e.code === 'Escape' && handle && escToClose) {
      handle();
      document.removeEventListener('keydown', onKeyDown);
    }
  }, [handle, escToClose]);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false);
    document.body.appendChild(_elem.current);

    return () => {
      document.removeEventListener('keydown', onKeyDown);

      // 지우려는 모달이 존재하는지 우선 체크
      if (document.body.contains(_elem.current)) {
        document.body.removeChild(_elem.current);
      }
    };
  }, []);

  return _elem;
}

export default useModal;

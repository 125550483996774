import { createModel } from '@rematch/core';
import { produce } from 'immer';

import state from './state';

const tempStore = createModel({
  state: state,
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
    reset() {
      return state;
    }
  },

  effects: dispatch => ({
    // 전체 선택
    selectAll(checked, { tempStore: { rooms } }) {
      if (checked) {
        const roomIds = rooms.map(item => item.id);
        this.update({ selectRooms: roomIds });

      } else {
        this.update({ selectRooms: [] });
      }
    },
    
    // 단일 선택
    selectRoom(value, { tempStore: { selectRooms } }) {
      const idx = selectRooms.findIndex(id => id === value);
      
      const next = produce(selectRooms, draft => {
        if (idx > -1) {
          draft.splice(idx, 1);
        } else {
          draft.push(value);
        }
      });

      this.update({ selectRooms: next });
    },
  }),
});

export default tempStore;

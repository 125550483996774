import isEmpty from './valid/isEmpty';

/**
 * @description 중개사법 개정으로 "날짜 협의"를 제거하므로 체크가 필요하다.
 * 조건: "즉시 입주" || 날짜 형태가 아닐경우 -> '' 값으로 대치
 * @param {String} date 
 */
export function setMovingDate(date = '') {
  if (isEmpty(date)) return '';

  const dateReg = /^([12]\d{3})[. ]?[- ]?(0[1-9]|1[0-2])[. ]?[- ]?(0[1-9]|[12]\d|3[01])/g;

  if (date.replace(/ /g, '') === '즉시입주') {
    return '즉시 입주';

  } else if (dateReg.test(date)) {
    return date.replace(dateReg, '$1.$2.$3') ?? '';

  } else {
    return '';
  }
}

/**
 * 월세 보증금 가격 체크
 * 10만원 이상 50억 이하
 * @param {Number} price 
 */
export function checkRentDeposit(price) {
  if (isEmpty(price)) return '월세 보증금을 입력해주세요. (10만원 이상 ~ 50억원 이하)';
  if (price < 10 ) return '월세 보증금을 확인해주세요. (10만원 이상 ~ 50억원 이하)';
  if (price > 500000) return '월세 보증금을 확인해주세요. (10만원 이상 ~ 50억원 이하)';
  return false;
}

/**
 * 월세 가격 체크
 * 1만원 이상 2000만원 이하
 * @param {Number} price 
 */
export function checkRentPrice(price) {
  if (isEmpty(price)) return '월세 가격을 입력해주세요. (1만원 이상 ~ 2000만원 이하)';
  if (price < 1) return '월세 가격을 확인해주세요. (1만원 이상 ~ 2000만원 이하)';
  if (price > 2000) return '월세 가격을 확인해주세요. (1만원 이상 ~ 2000만원 이하)';
  return false;
}

/**
 * 전세 가격 체크
 * 100만원 이상 50억 이하
 * @param {Number} price 
 */
export function checkYearlyDeposit(price) {
  if (isEmpty(price)) return '전세금을 입력해주세요. (100만원 이상 ~ 50억원 이하)';
  if (price < 100) return '전세금을 확인해주세요. (100만원 이상 ~ 50억원 이하)';
  if (price > 500000) return '전세금을 확인해주세요. (100만원 이상 ~ 50억원 이하)';
  return false;
}

/**
 * 매매 가격 체크
 * 100만원 이상 1000억 이하
 * @param {Number} price 
 */
export function checkSalePrice(price) {
  if (isEmpty(price)) return '매매금을 입력해주세요. (100만원 이상 ~ 1000억원 이하)';
  if (price < 100) return '매매금을 확인해주세요. (100만원 이상 ~ 1000억원 이하)';
  if (price > 10000000) return '매매금을 확인해주세요. (100만원 이상 ~ 1000억원 이하)';
  return false;
}

/**
 * 월세 보증금 가격 체크
 * 10만원 이상 50억 이하
 * @param {Number} price 
 */
export function checkTenantDeposit(price, isRent) {
  if (isRent) {
    if (isEmpty(price)) return `기월세금을 입력해주세요. (10만원 이상 ~ 50억원 이하)`;
    if (price < 10 ) return `기월세금을 확인하세요. (10만원 이상 ~ 50억원 이하)`;
    if (price > 500000) return `기월세금을 확인하세요. (10만원 이상 ~ 50억원 이하)`;

  } else {
    if (isEmpty(price)) return `기전세금을 입력해주세요. (100만원 이상 ~ 50억원 이하)`;
    if (price < 100 ) return `기전세금을 확인하세요. (100만원 이상 ~ 50억원 이하)`;
    if (price > 500000) return `기전세금을 확인하세요. (100만원 이상 ~ 50억원 이하)`;
  }

  return false;
}

/**
 * 월세 가격 체크
 * 1만원 이상 2000만원 이하
 * @param {Number} price 
 */
export function checkTenantPrice(price) {
  if (isEmpty(price)) return `기월세금을 입력해주세요. (1만원 이상 ~ 2000만원 이하)`;
  if (price < 1) return `기월세금을 확인해주세요. (1만원 이상 ~ 2000만원 이하)`;
  if (price > 2000) return `기월세금을 확인해주세요. (1만원 이상 ~ 2000만원 이하)`;
  return false;
}

/**
 * 가격정보값중 price 가 '' || null 일 경우 0으로 초기화 한다.
 * @param {Array} list 가격정보
 */
export function convertPrice(list) {
  if (list.length <= 0) return [];

  return list.reduce((r, item) => {
    if (+item.type === 0) {
      if (!isEmpty(item.deposit) && !isEmpty(item.price)) {
        r.push({
          type: item.type,
          deposit: item.deposit,
          price: isEmpty(item.price) ? 0 : item.price,
        });
      }

    } else {
      if (!isEmpty(item.deposit)) {
        r.push({
          type: item.type,
          deposit: item.deposit,
          price: isEmpty(item.price) ? 0 : item.price,
        });
      }
    }
    
    return r;
  }, []);
}

export default {
  checkRentDeposit,
  checkRentPrice,
  checkYearlyDeposit,
  checkSalePrice,
  checkTenantDeposit,
  checkTenantPrice,
  convertPrice,
};
import { createGlobalStyle } from 'styled-components';
import rootStyle from '~/common/rootStyle';
import reset from 'styled-reset';

export default createGlobalStyle`
  ${reset};
  ${rootStyle};

  * {
    box-sizing: border-box;
  }

  html,
  body {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overscroll-behavior-y: contain;
  }
  
  body {
    flex-grow: 1;
    font-size: 14px;
    font-weight: 400;

    -ms-overflow-style: none; // IE에서 스크롤바 감춤

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: transparent;

    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
      opacity: 0;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  #root {
    width: 100%;
    height: 100%;
  }

  #content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 1200px;
    min-height: 100%;
    position: relative;
  }

  #modal {
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    overscroll-behavior-y: contain;
  }
  
  #fdpayWin {
    overflow: hidden;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  mark {
    color: ${({ theme }) => theme.color.blue[500]};
    background-color: transparent;
  }
`;

import React, { useState } from 'react';

import Modal from '~/components/modal';
import { ReportListContainer } from 'web~/components/popup/report';

import { Intro, Manage } from '../menu-list';

import { Wrap, MenuButton, MenuNav } from './styled';

interface Props {
  type: 'intro' | 'manage';
  title: string;
  isActive: boolean;
}

function ContainerComponent({ type, title, isActive }: Props) {
  const [isShow, setShow] = useState(false);
  const [isOpenReportList, setOpenReportList] = useState<'' | 'PROCESSING_DETAILS' | 'PENALTY'>('');

  function open() {
    setShow(true);
  }

  function close() {
    setShow(false);
  }

  function clickReportPopupHandler(tab: '' | 'PROCESSING_DETAILS' | 'PENALTY') {
    setOpenReportList(tab);

    if (tab === '') {
      close();
    }
  }

  return (
    <>
      <Wrap onMouseEnter={open} onMouseLeave={close}>
        <MenuButton isActive={isActive || isShow}>{title}</MenuButton>

        {isShow && (
          <MenuNav>
            <div>
              {type === 'intro' ? (
                <Intro close={close} />
              ) : type === 'manage' ? (
                <Manage clickReportPopupHandler={clickReportPopupHandler} close={close} />
              ) : null}
            </div>
          </MenuNav>
        )}
      </Wrap>

      {isOpenReportList && (
        <Modal
          minWidth={840}
          handle={() => {}}
          hideClose
          escToClose={false}
        >
          <ReportListContainer
            initTab={isOpenReportList}
            close={clickReportPopupHandler.bind(null, '')}
          />
        </Modal>
      )}
    </>
  );
}

export default ContainerComponent;

import React from 'react';
import { createPortal } from 'react-dom';
import useModalStore from './store';

import { Container, Overlay } from './styled';

export function PopupPortal() {
  const {
    modals,
  } = useModalStore();

  return createPortal(
    <Container
      id="modal-container"
      $isShow={modals.length > 0}
    >
      <Overlay $isShow={modals.length > 0} />
    </Container>,
    document.body,
  );
}

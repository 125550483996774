import styled from 'styled-components';

import selectStd from '~/components/styled/select';
import descStd from '~/components/styled/desc';

export const Wrap = styled.div`
  padding: 20px 40px 50px;
  border-top: 1px solid ${({ theme }) => theme.veryLightGreyLine.getColor()};
`;

export const FormContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  > h2 {
    flex: none;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  > div {
    flex: none;
    display: flex;
  }
`;

export const Select = styled.select`
  ${selectStd};
  flex-grow: 1;
  width: 100%;
  height: 48px;
`;

export const Desc = styled.div`
  ${descStd};
  margin-top: 20px;

  > p {
    color: ${({ theme }) => theme.grey43.getColor()};
    line-height: 24px;
  }
`;

export const BtnWrap = styled.div`
  width: 100%;
  height: 56px;
`;

export const SubmitBtn = styled.button`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.white.getColor()};
  font-size: 16px;
  font-weight: 700;
  border: 0;
  background-color: ${({ theme }) => theme.proMainColor.getColor()};

  &:disabled {
    color: ${({ theme }) => theme.grey8f.getColor()};
    background-color: ${({ theme }) => theme.lightGreyBg.getColor()};
  }
`;
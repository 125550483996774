import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
  
  > h1  {
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body2.bold};
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 40px;
`;


export const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  button + button {
    margin-left: 8px;
  }
`;

export const Text = styled.p`
  &.body3 {
    ${({ theme }) => theme.font.body3.regular};
    color: ${({ theme }) => theme.color.gray[700]};
  }

  &.caption1 {
    display: flex;
    align-items: center;
    ${({ theme }) => theme.font.caption1.regular};
    color: ${({ theme }) => theme.color.gray[700]};

    > b {
      font-size: 20px;
    }

    & + p {
      margin-top: 8px;
    }
  }
`;

export const TextWrap = styled.div`
  width: 100%;
  margin: 16px auto 0;
  padding: 8px 8px 8px 40px;
  background-color: ${({ theme }) => theme.color.gray[50]};
  position: relative;

  > svg {
    position: absolute;
    top: 8px;
    left: 8px;
  }
`;

export const PopupButton = styled.div`
  background: transparent;
  border: 0;
`;

import { css } from 'styled-components';

export default css`
  display: flex;
  margin-left: -4px;
  color: ${({ theme, isRed }) => (isRed ? theme.noticeRed.getColor() : theme.grey8f.getColor())};
  
  > p {
    font-size: 14px;
    line-height: 30px;

    &:first-child {
      flex: none;
      margin-right: 4px;
      font-size: 15px;
    }
  }
`;

import { css } from 'styled-components';

export default css`
  width: 100%;

  > div {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
  }
`;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';
import qs from '~/utils/query-string';

import useApiCall from '~/hooks/useApiCall';

ReactGA.initialize([
  { trackingId: 'G-R8ZXMZQSVF' },
  { trackingId: 'UA-81351317-1' },
]);

function useCheck() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { get } = useApiCall();

  useEffect(() => {
    const query = qs.parse(location.search, { parseBooleans: true });
    const params = JSON.parse(query?.data ?? null);

    if (params?.link === 'ROOM_MANAGE') {
      history.replace(`/room/dabang-list/?keyword=${params?.data?.keyword}`);
    }

    if (params?.link === 'GONGSILCENTER_LIST') {
      history.replace(`/gongsil/list/pick`);
    }
  }, [location]);

  useEffect(() => {
    // 과거 코드 처리를 위해 추가
    if (window.location.hash) {
      if (window.location.hash.indexOf('#/agree-view') > -1) {
        const search = window.location.hash.replace('#/agree-view', '');
        const { seq } = qs.parse(search);
        window.location.href = `/terms/${seq}`;
      }
    }

    const path = location.pathname;

    ReactGA.set({ page: path });
    ReactGA.send({ hitType: 'pageview', page: path });
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    checkAuth();
  }, [location.pathname]);

  async function checkAuth() {
    try {
      const result = await get('/api/v2/user/login/check');

      if (result) {
        dispatch.user.getInfo();
      } else {
        dispatch.user.reset();
      }

    } catch (err) {
      console.log(err);
    }
  }
}

export default useCheck;

import styled, { css } from 'styled-components';

export const PopupButton = styled.div`
  background: transparent;
  border: 0;
  cursor: pointer;

  span {
    display: flex;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};
  
  > h1  {
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body2.bold};
  }
`;

export const TabWrap = styled.div`
  display: flex;
  margin-bottom: 32px;
  border: 1px solid ${({ theme }) => theme.color.gray[300]};;
`;

export const Tab = styled.div<{ isActive: boolean }>`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding: 20px 0;
  text-align: center;
  cursor: pointer;

  ${({ theme, isActive }) => isActive ? css`
    color: ${theme.color.white};
    ${theme.font.body2.bold};
    background-color: ${theme.color.gray[900]};
  ` : css`
    color: ${theme.color.gray[900]};
    ${theme.font.body2.regular};
    background-color: ${theme.color.white};
  `};
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.color.gray[900]};
`;

export const ListScrollBody = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
`;

export const ListHeader = styled.li`
  display: flex;
  width: 100%;
  height: 64px;
  ${({ theme }) => theme.font.body3.bold};
  color: ${({ theme }) => theme.color.gray[900]};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
`;

export const ListRow = styled.li`
  display: flex;
  width: 100%;
  min-height: 64px;
  ${({ theme }) => theme.font.body3.regular};
  color: ${({ theme }) => theme.color.gray[900]};
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[300]};
`;

export const ListText = styled.p`
  display: flex;
  align-items: center;
  padding: 8px 20px;
  min-height: 64px;
  overflow: hidden;
  position: relative;

  &.dabang-seq {
    width: 160px;
  }

  &.dabang-inflowType {
    width: 100px;
  }

  &.dabang-result {
    width: 120px;
  }

  &.dabnag-warn {
    width: 120px;
  }

  &.dabang-penalty {
    width: 256px;
  }

  &.kiso-seq {
    width: 120px;
  }

  &.kiso-cp {
    display: flex;
    align-items: center;
    width: 342px;

    > span {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  &.kiso-time {
    width: 114px;
  }

  &.kiso-penalty {
    width: 200px;
  }

  &.blue {
    color: ${({ theme }) => theme.color.blue[500]};
  }

  &.red {
    color: ${({ theme }) => theme.color.red[500]};
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 40px;
`;

export const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  button + button {
    margin-left: 8px;
  }
`;

export const TextWrap = styled.div`
  display: flex;
`;

export const Text = styled.p`
  ${({ theme }) => theme.font.body3.bold};
  color: ${({ theme }) => theme.color.gray[900]};

  &.right {
    margin-left: auto;
  }

  > span {
    color: ${({ theme }) => theme.color.blue[500]};
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 416px;
  color: ${({ theme }) => theme.color.gray[900]};
  ${({ theme }) => theme.font.body3.regular};

  > svg {
    margin-bottom: 16px;
  }
`;

export const InfoList = styled.ul`
  margin-top: 16px;
  padding: 20px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.gray[100]};
  border: 0;
  border-radius: 2px;

  > p {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body3.bold};
  }

  > li {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    color: ${({ theme }) => theme.color.gray[700]};
    ${({ theme }) => theme.font.caption1.regular};
    
    > div {
      flex-grow: 1;
    }

    &:before {
      flex: none;
      content: '-';
      display: block;
      margin-right: 2px;
      color: inherit;
      ${({ theme }) => theme.font.caption1.regular};
    }

    & + & {
      margin-top: 2px;
    }
  }
`;

import React, { useRef, useState, useCallback, useEffect } from 'react';

import Toast from './Element';

function Timer(callback, delay) {
  let timerId;
  let start = delay;
  let remaining = delay;

  this.clear = () => {
    clearTimeout(timerId);
  };

  this.pause = () => {
    clearTimeout(timerId);
    remaining -= Date.now() - start;
  };

  this.resume = () => {
    start = Date.now();
    clearTimeout(timerId);
    timerId = setTimeout(callback, remaining);
  };

  this.resume();
}

function ToastController({
  autoDismiss, autoDismissTimeout, onDismiss,
  type, position, transitionState, children,
}) {
  const _timeout = useRef(null);
  const [isRunning, setRunning] = useState(Boolean(autoDismiss));

  const startTimer = useCallback(() => {
    if (!autoDismiss) return;
    setRunning(true);
    _timeout.current = new Timer(onDismiss, autoDismissTimeout);
  }, []);

  const clearTimer = useCallback(() => {
    if (_timeout.current) _timeout.current.clear();
  }, []);

  useEffect(() => {
    startTimer();

    return () => {
      clearTimer();
    };
  }, []);

  const onMouseEnter = useCallback(() => {
    if (!autoDismiss) return;
    setRunning(false);
    if (_timeout.current) _timeout.current.pause();
  }, [autoDismiss]);

  const onMouseLeave = useCallback(() => {
    if (!autoDismiss) return;
    setRunning(true);
    if (_timeout.current) _timeout.current.resume();
  }, [autoDismiss]);

  return (
    <Toast
      isRunning={isRunning}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      autoDismiss={autoDismiss}
      autoDismissTimeout={autoDismissTimeout}
      type={type}
      position={position}
      transitionState={transitionState}
      onDismiss={onDismiss}>
      {children}
    </Toast>
  );
}

export default ToastController;
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import { enableES5, enableMapSet } from 'immer';

import store, { history } from '~/store';
import theme from '~/theme';
import { PopupPortal } from '~/components/modal2';
import ToastProvider from '~/components/toast';

import Routes from './routes';

enableES5();
enableMapSet();

render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <ToastProvider autoDismissTimeout={10000}>
          <PopupPortal />
          <Routes />
        </ToastProvider>
      </ConnectedRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);


import styled from 'styled-components';

export const ImageLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  display: flex;
  cursor: ${({ href }) => href ? 'pointer' : 'default'};
`;

export const Image = styled.img`
  width: 480px;
  object-fit: contain;
`;

export const BtnWrap = styled.div`
  display: flex;
  width: 100%;
  height: 56px;

  > button,
  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.white.getColor()};
    font-size: 16px;
    font-weight: 700;

    &:first-child {
      background-color: ${({ theme }) => theme.grey8f.getColor()};
    }
    &:last-child {
      background-color: ${({ theme }) => theme.proMainColor.getColor()};
    }
  }
`;

export const CloseBtn = styled.button`
  border: 0;
`;

export const ShowDetail = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  display: flex;
  align-items: center;
  justify-content: center;
`;

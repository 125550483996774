import React, { useEffect, useRef, useState } from 'react';

import { padding, BOTTOM_CENTER } from './common';

import { ReactComponent as IconSvg } from './images/icon.svg';
import { ReactComponent as CloseSvg } from './images/close.svg';

import { ToastElement, IconWrap, CountDown, Content, CloseBtn } from './styled';

const ToastModule = ({
  children,
  isRunning,
  autoDismiss,
  autoDismissTimeout,
  onMouseEnter,
  onMouseLeave,
  type,
  onDismiss,
  position,
  transitionState,
}) => {
  const [height, setHeight] = useState('auto');
  const elementRef = useRef(null);

  useEffect(() => {
    if (transitionState === 'entered') {
      const el = elementRef.current;
      if (position === BOTTOM_CENTER) {
        setHeight(el.offsetHeight + 16);

      } else {
        setHeight(el.offsetHeight + padding);
      }
    } else if (transitionState === 'exiting') {
      setHeight(0);
    }
  }, [transitionState]);

  function clickContent() {
    if (position !== BOTTOM_CENTER) return;
    onDismiss();
  }

  return (
    <ToastElement
      ref={elementRef}
      height={height}
      type={type}
      position={position}
      transitionState={transitionState}>

      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>

        <IconWrap type={type} position={position}>
          <CountDown opacity={autoDismiss ? 1 : 0} timeout={autoDismissTimeout} isRunning={isRunning} />
          {position !== BOTTOM_CENTER && <IconSvg />}
        </IconWrap>
  
        <Content type={type} position={position} onClick={clickContent}>
          {children}
        </Content>
  
        {position !== BOTTOM_CENTER && <CloseBtn onClick={onDismiss}>
          <CloseSvg />
        </CloseBtn>}

      </div>

    </ToastElement>
  );
};

export default ToastModule;
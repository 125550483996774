import React, { useState, useCallback } from 'react';

import { agentToolDomain } from '~/enum/config';

import useApiCall from '~/hooks/useApiCall';
import { useToast } from '~/components/toast';

import SolidBtn from '~/atoms/button/solid-button';

import { Wrap } from './styled';

function AgentToolComponent() {
  const [isSending, setSending] = useState(false);

  const { post } = useApiCall();
  const toast = useToast();

  const clickLink = useCallback(async (to: string) => {
    if (isSending) return;

    try {
      setSending(true);

      // 회원인지 체크
      const isMember = await post('/api/v2/user/agent-tool-check-signup');

      if (!isMember) {
        // 회원가입
        await post('/api/v2/user/agent-tool-signup');
      }

      // 중개사툴 액세스토큰 발급
      const token = await post(`/api/v2/user/agent-tool-accessible-session`);

      if (token) {
        window.open(`${agentToolDomain}${to}?att=${token}`, '_blank');

      } else {
        toast.error('토큰발급에 실패하였습니다.');
      }

      setSending(false);

    } catch (err) {
      setSending(false);
      toast.error(err?.msg ?? '토큰발급에 실패하였습니다.');
    }

  }, [isSending]);

  return (
    <Wrap>
      <svg width="46" height="24" viewBox="0 0 46 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="44" height="22" rx="11" fill="#222222"/>
        <path d="M8.83398 17V7.10156H12.7715C14.9521 7.10156 16.0596 8.1543 16.0527 9.64453C16.0596 10.8066 15.2939 11.5381 14.2754 11.7637V11.8594C15.3896 11.9141 16.4424 12.8027 16.4492 14.2656C16.4424 15.8379 15.2666 17 13.0312 17H8.83398ZM10.8848 15.3047H12.6211C13.8105 15.3115 14.3232 14.8193 14.3164 14.0742C14.3232 13.2402 13.6875 12.6523 12.6758 12.6523H10.8848V15.3047ZM10.8848 11.2441H12.4707C13.332 11.251 13.9814 10.7656 13.9746 9.97266C13.9814 9.25488 13.4482 8.76953 12.5117 8.76953H10.8848V11.2441ZM21.0703 17.1504C18.7939 17.1436 17.3994 15.6738 17.4062 13.3223C17.3994 11.0322 18.8145 9.48047 20.9883 9.48047C22.9365 9.48047 24.4746 10.7109 24.4746 13.2402V13.8145H19.4023C19.4023 14.9355 20.0654 15.626 21.1113 15.6328C21.8018 15.626 22.2939 15.3252 22.4922 14.8809H24.4199C24.1328 16.2549 22.9023 17.1436 21.0703 17.1504ZM19.4023 12.543H22.5605C22.5537 11.6406 21.9316 10.9912 21.0293 10.998C20.0996 10.9912 19.4434 11.6885 19.4023 12.543ZM29.6562 9.57617V11.0938H28.2617V14.7168C28.2549 15.3115 28.5488 15.4551 28.959 15.4688C29.1641 15.4688 29.5195 15.4482 29.7656 15.4277V17.0273C29.5469 17.0615 29.1846 17.1025 28.6855 17.0957C27.2227 17.1025 26.2246 16.4463 26.2383 15.0039V11.0938H25.2129V9.57617H26.2383V7.79883H28.2617V9.57617H29.6562ZM30.5723 14.9219C30.5791 13.2676 31.9053 12.7275 33.334 12.6387C33.8672 12.6045 34.8652 12.5566 35.1797 12.543V11.9414C35.1729 11.3193 34.749 10.9639 33.9902 10.957C33.2998 10.9639 32.8691 11.2715 32.7598 11.7773H30.832C30.9551 10.4922 32.0762 9.48047 34.0449 9.48047C35.665 9.48047 37.1963 10.2051 37.1895 11.9961V17H35.2754V15.9746H35.2207C34.8584 16.6582 34.1475 17.1436 33.0469 17.1367C31.625 17.1436 30.5791 16.3916 30.5723 14.9219ZM32.5137 14.8672C32.5137 15.4346 32.9717 15.7354 33.6211 15.7422C34.5439 15.7354 35.1865 15.1338 35.1797 14.3477V13.8076C34.8721 13.8213 34.0039 13.8691 33.6621 13.8965C32.958 13.958 32.5137 14.293 32.5137 14.8672Z" fill="white"/>
        <rect x="1" y="1" width="44" height="22" rx="11" stroke="white" strokeWidth="2"/>
      </svg>

      <SolidBtn
        contentWidth="81px"
        btnColor="blue"
        btnSize="md"
        onClick={clickLink.bind(null, '/my/account')}
      >
        업무관리
      </SolidBtn>
    </Wrap>
  );
}

export default AgentToolComponent;

const intro = [
  '/intro',
  '/service',
];

const manage = [
  '/room',
  '/form',
  '/sign',
  '/gongsil',
  '/product',
  '/account',
  '/inquiry',
];

export default { intro, manage };

import styled from 'styled-components';

import layout from '~/components/styled/layout';

export const Wrap = styled.div`
  height: 100%;
  padding: 0 24px;
`;

export const MenuButton = styled.button<{ isActive: boolean }>`
  height: 100%;
  margin: 0;
  padding: 0;
  ${({ theme }) => theme.font.body2.bold};
  color: ${({ isActive, theme }) => isActive ? theme.color.blue[500] : theme.color.gray[900]};
  border: 0;

  &:hover {
    color: ${({ theme }) => theme.color.blue[500]};
  }
`;

export const MenuNav = styled.nav`
  ${layout};
  padding: 30px 0 80px;
  border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 500;

  > div {
    display: flex;
  }
`;

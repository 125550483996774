import React from 'react';

import useDragThumb from './useDragThumb';

import isMobile from '~/utils/is-mobile';

import { ScrollTrackY, ScrollTrackX, ScrollThumbY, ScrollThumbX } from './styled';

interface Props {
  containerId?: string;
  /**
   * 배경이 어두우면 (ex: 모달) : dark
   * 배경이 밝으면 (ex: 일반 페이지 배경) : light
   */
  screenMode?: 'dark' | 'light';
  scrollbarSize?: number;
}

/**
 * @param {string} containerId
 * @param {light|dark} screenMode
 * - 배경이 어두우면 (ex: 모달) : dark
 * - 배경이 밝으면 (ex: 일반 페이지 배경) : light
 * @param scrollbarSize 스크롤바 두께 px
 */
function ScrollbarComponent({
  containerId,
  screenMode = 'light',
  scrollbarSize = 6,
}: Props) {
  const { _xScroll, _yScroll, mouseDown } = useDragThumb({ containerId });

  if (isMobile()) return null;

  return (
    <>
      <ScrollTrackY
        hasId={!!containerId}
        scrollbarSize={scrollbarSize}
      >
        <ScrollThumbY
          ref={_yScroll}
          screenMode={screenMode}
          scrollbarSize={scrollbarSize}
          onMouseDown={mouseDown.bind(null, 'y')}
        />
      </ScrollTrackY>

      <ScrollTrackX
        hasId={!!containerId}
        scrollbarSize={scrollbarSize}
      >
        <ScrollThumbX
          ref={_xScroll}
          screenMode={screenMode}
          scrollbarSize={scrollbarSize}
          onMouseDown={mouseDown.bind(null, 'x')}
        />
      </ScrollTrackX>
    </>
  );
}

export default ScrollbarComponent;

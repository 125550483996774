/**
 * text가 0인지 여부
 * String 빈칸('') 은 숫자로 변환시 0이 된다.
 * @param {String|Number} text 
 */
function isZero(text) {
  if (text === '') return false;
  if (+text === 0) return true;
  return false;
}

export default isZero;

import { useRef, useEffect, useMemo, MouseEvent as ReactMouseEvent } from 'react';
import { ResizeObserver } from '@juggle/resize-observer';

import isMobile from '~/utils/is-mobile';

interface Props {
  containerId?: string;
}

function useDragThumb({
  containerId,
}: Props) {
  const _yScroll = useRef<HTMLDivElement>(null);
  const _xScroll = useRef<HTMLDivElement>(null);

  const _isDragging = useRef<boolean>(false);
  const _position = useRef<'x' | 'y' | null>(null);
  const _shift = useRef<number>(0);

  const _running = useRef<boolean>(false);

  const _ro = useRef<ResizeObserver>(new ResizeObserver(() => {
    recalculate();
  }));

  const isRoot = useMemo(() => {
    if (!containerId) return true;
    if (containerId === 'content') return true;
    return false;
  }, [containerId]);

  const rootId = useMemo(() => containerId ?? '', [containerId]);

  useEffect(() => {
    initObserver();

    return () => {
      if (!isMobile()) {
        _ro.current?.disconnect();
      }
    };
  }, [window.location, containerId]);

  useEffect(() => {
    initEvent();

    return () => {
      if (!isMobile()) {
        if (!isRoot) {
          document.getElementById(rootId)?.removeEventListener('scroll', scrollEvent);

        } else {
          document.removeEventListener('scroll', scrollEvent);
          window.removeEventListener('resize', resize);
        }
      }
    };
  }, [containerId]);

  function initObserver() {
    if (!isMobile()) {
      if (!isRoot) {
        if (document.getElementById(rootId)) {
          _ro.current.observe(document.getElementById(rootId) as HTMLDivElement);

        } else {
          setTimeout(() => {
            initObserver();
          }, 100);
        }

      } else {
        _ro.current.observe(document.getElementById('content') as HTMLDivElement);
      }
    }
  }

  function initEvent() {
    if (!isMobile()) {
      if (!isRoot) {
        if (document.getElementById(rootId)) {
          document.getElementById(rootId)!.addEventListener('scroll', scrollEvent, false);

        } else {
          setTimeout(() => {
            initEvent();
          }, 100);
        }

      } else {
        document.addEventListener('scroll', scrollEvent, false);
        window.addEventListener('resize', resize, false);
      }
    }
  }

  function scrollEvent() {
    const root = containerId ? document.getElementById(containerId) : document.documentElement;

    if (!root) return;

    const scrollTop = root.scrollTop;
    const scrollLeft = root.scrollLeft;

    const dom = document.getElementById(containerId ? containerId : 'content') as HTMLDivElement;

    if (dom) {
      const clientWidth = containerId ? dom.scrollWidth : dom.clientWidth;
      const clientHeight = containerId ? dom.scrollHeight : dom.clientHeight;
      const innerWidth = containerId ? dom.offsetWidth : window.innerWidth;
      const innerHeight = containerId ? dom.offsetHeight : window.innerHeight;

      const thumbHeight = (innerHeight / clientHeight) * 100;
      const thumbWidth = (innerWidth / clientWidth) * 100;

      if (_yScroll.current) {
        if (thumbHeight >= 100) _yScroll.current.style.display = 'none';
        else _yScroll.current.style.display = 'block';

        _yScroll.current.style.height = `${thumbHeight}%`;
        _yScroll.current.style.top = `${(scrollTop / clientHeight) * 100}%`;
      }

      if (_xScroll.current) {
        if (thumbWidth >= 100) _xScroll.current.style.display = 'none';
        else _xScroll.current.style.display = 'block';

        _xScroll.current.style.width = `${thumbWidth}%`;
        _xScroll.current.style.left = `${(scrollLeft / clientWidth) * 100}%`;
      }
    }
  }

  function resize() {
    const dom = document.getElementById('content') as HTMLDivElement;
    if (dom.getBoundingClientRect().width > 1200) return;

    if (!_running.current) {
      _running.current = true;

      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(runCallbacks);
      } else {
        setTimeout(runCallbacks, 66);
      }
    }
  }

  function runCallbacks() {
    recalculate();
    _running.current = false;
  }

  function recalculate() {
    scrollEvent();
  }

  function mouseDown(pos: 'x' | 'y', e: ReactMouseEvent<HTMLDivElement>) {
    e.preventDefault();
    document.addEventListener('mousemove', mouseMove, false);
    document.addEventListener('mouseup', mouseUp);

    _position.current = pos;
    _isDragging.current = true;

    if (pos === 'y' && _yScroll.current) {
      _shift.current = e.clientY - _yScroll.current.getBoundingClientRect().top;

    } else if (pos === 'x' && _xScroll.current) {
      _shift.current = e.clientX - _xScroll.current.getBoundingClientRect().left;
    }
  }

  function mouseUp() {
    document.removeEventListener('mousemove', mouseMove);
    document.removeEventListener('mouseup', mouseUp);

    _position.current = null;
    _isDragging.current = false;
  }

  function mouseMove(e: MouseEvent) {
    if (!_isDragging.current) return;

    if (_position.current === 'x' && _xScroll.current) {
      const rect = _xScroll.current.getBoundingClientRect();

      if (!isRoot) {
        const dom = document.getElementById(rootId) as HTMLDivElement;
        const innerWidth = containerId ? dom.offsetWidth : window.innerWidth;
        const maxXWidth = innerWidth - rect.width;
        const moveScrollX = e.clientX - _shift.current - rect.left; // 스크롤 Y축 이동 px

        if (maxXWidth < moveScrollX) return;

        const clientWidth = containerId ? dom.scrollWidth : dom.clientWidth;
        const perPx = innerWidth * (dom.scrollLeft / clientWidth);
        const scrollTop = dom.scrollTop;

        dom.scrollTo(clientWidth * ((moveScrollX + perPx) / innerWidth), scrollTop);

      } else {
        const scrollTop = document.documentElement.scrollTop;
        const moveScrollX = e.pageX - _shift.current - rect.left; // 스크롤 Y축 이동 px
        window.scrollTo(moveScrollX, scrollTop);
      }

    } else if (_position.current === 'y' && _yScroll.current) {
      const rect = _yScroll.current.getBoundingClientRect();

      if (!isRoot) {
        const dom = document.getElementById(rootId) as HTMLDivElement;
        const innerHeight = dom.offsetHeight;
        const maxYHeight = innerHeight - rect.height;
        const moveScrollY = e.clientY - _shift.current - rect.top; // 스크롤 Y축 이동 px

        if (maxYHeight < moveScrollY) return;

        const clientHeight = dom.scrollHeight;
        const perPx = innerHeight * (dom.scrollTop / clientHeight);
        const scrollLeft = dom.scrollLeft;

        dom.scrollTo(scrollLeft, clientHeight * ((moveScrollY + perPx) / innerHeight));

      } else {
        const scrollLeft = document.documentElement.scrollLeft;
        const moveScrollY = e.pageY - _shift.current - rect.top; // 스크롤 Y축 이동 px
        window.scrollTo(scrollLeft, moveScrollY);
      }
    }
  }

  return { _xScroll, _yScroll, mouseDown };
}

export default useDragThumb;

import { useState, useEffect } from 'react';
import useApiCall from '~/hooks/useApiCall';
import { useToast } from '~/components/toast';

function useVerifyTel(resetType) {
  const [isShow, setShow] = useState(false);
  const { get } = useApiCall();
  const toast = useToast();

  useEffect(() => {
    checkVerifyTel();
  }, []);

  async function checkVerifyTel() {
    try {
      const { isVerify } = await get(`/api/v2/agent/verify-tel`);
      
      if (!isVerify) {
        setShow(true);
      } else {
        clickClose();
      }

    } catch(err) {
      console.log(err);
      toast.error(err?.msg ?? '서버 에러가 발생했습니다.');
    }
  }

  function clickClose() {
    resetType({ isCloseVerifyTel: true });
  }

  return { isShow, clickClose };
}

export default useVerifyTel;
import { maintenances, personalMaintenances, etcs, safety } from '~/enum/form/option';

export function createList(list) {
  return list.map(i => ({
    id: +i.key,
    name: i.name,
    isActive: false,
  }));
}

export default {
  // 내부적으로 사용하는 값
  isConfirm: false,                               // 확인매물 인지 여부 (수정시 사용)
  isEdit: false,                                  // 수정중인지 상태 여부
  isTemp: false,                                  // 임시 저장 리스트에서 불러왔는지 여부
  isSale: false,                                  // 매매 여부
  isRenew: false,                                 // 매물 수정/재등록시 신규 채번 여부(ex: 수정 후 재등록, 문의 있는 매물 수정), https://station-3.atlassian.net/wiki/spaces/DabnagPro/pages/2079031427

  dontKnowEnterDate: false,                       // 준공년도 모름 여부
  maxFloor: 50,                                   // 최대 층수 기본값

  bedsNumCount: '',                               // 방수 직접 입력
  bathNumCount: '',                               // 욕실수 직접 입력

  isSending: false,                               // 매물등록 중 인가
  isUploading: false,                             // 이미지 업로드 중 인가

  roomId: '',                                     // 임시저장매물/일반매물 수정할때 보내야 하는 값
  tempRoomId: '',                                 // 임시저장한 매물을 매물등록할때 보내야 하는 값

  // 약관동의 페이지
  isSendLh: true,                                 // LH 노출 동의 여부

  // 매물 유형
  roomType: 0,                                    // 0: 주택/빌라(원투쓰리룸),  3: 오피스텔,  4: 아파트
  buildingType: 2,

  // 매물 주소
  location: [],
  randomLocation: [],
  jibunAddress: '',
  roadAddress: '',
  buildingName: '',                               // 빌딩명
  dong: '',
  ho: '',
  isNoinfoDong: false,                            // 동정보 존재 여부

  complexSeq: '',                                 // 단지 고유값
  spaceSeq: '',                                   // 단지 평형 선택값
  regionCode: '',                                 // 단지 사용승인일 필수값
  pnuCode: '',                                    // 비단지 사용승인일 필수값

  isUseCorrectAddress: true,                      // 정확한 위치 표현 여부
  isNewConstruction: null,                        // 미등기 건물 여부

  // 거래 정보
  roomPrices: [{
    type: 0,
    deposit: '',
    price: '',
  }],                                             // 가격정보
  shortLease: false,                              // 단기가능 여부
  movingDate: '',                                 // 입주 가능일 ?
  loan: null,                                     // 전세자금대출
  isTakeTenant: false,                            // 세안고 여부
  isMovingDateNegotiation: false,                 // 입주가능일 협의가능
  takeTenantPrice: {
    price: '',
    deposit: '',
  },                                              // 세안고 가격정보 (없을경우 무조건 null로 해야한다.)
  isConfirmPricesCheck: false,                    // 확인 매물 가격변동 상태값
  beforeRoomPrices :[],                           // 수정 안된 기존 전세/월세/매매 가격정보
  beforeTakeTenantPrice :[],                      // 수정 안된 기존 기전세금/ 기월세금

  // 건물 정보
  approvalLoadiong: false,                        // 사용승인일 로딩
  principalUseType: '',                           // 건축물용도(층별용도)
  principalUse: '',
  buildingApprovalType: '',
  buildingApprovalDate: '',
  building_approval_date: '',                     // 사용승인일

  // 추가 정보
  roomSize: '',                                   // 전용면적 (기본으로 다 사용)      // exclusiveSpace
  provisionSize: '',                              // 공급면적 (오피스텔 아닐경우 사용)  // supplySpace
  contractSize: '',                               // 계약면적 (오피스텔일경우 사용)    // contractSpace

  isUseSupplySpace: false,                        // 공급면적 입력을 원할 경우 체크

  buildingFloor: '',                              // 건물층수
  roomFloor: '',                                  // 해당층수,
  roomFloorUnit: '',                              // 저층/중층/고층
  isUseRoomFloorUnit: false,                      // 저/중/고 표시 여부

  bedsNum: '',                                    // 방수
  bathNum: '',                                    // 욕실수
  heatingType: '',                                // 난방 종류
  direction: '',                                  // 방향

  animal: null,                                   // 반려동물
  elevator: false,                                // 엘리베이터
  balcony: false,                                 // 발코니
  builtIn: false,                                 // 빌트인
  division: false,                                // 1.5룸
  duplex: false,                                  // 복층

  parking: false,                                 // 주차비
  parkingNum: '',                                 // 총 가능 주차 대 수
  parkingAverage: '',                             // 세대 당 주차 대 수
  parkingCost: '',                                // 주차비 비용
  isParkingCostFree: true,                        // 무료 주차 여부

  maintenance: true,                              // 공용관리비
  maintenanceCost: '',                            // 공용관리비 비용
  maintenanceItems: createList(maintenances),     // 공용관리비 항목

  personalMaintenance: true,                                      // 개별사용료
  personalMaintenanceItems: createList(personalMaintenances),     // 개별사용료 항목

  isContract: false,                              // 비디면계약 여부
  isUseCorrectContract: false,                    // 비대면계약 여부 어뷰징 관련 동의

  // 부가 정보
  roomOptions: createList(etcs),                  // 옵션항목
  safeties: createList(safety),                   // 보안/안전

  // 상세 설명
  photos: [],                                     // 일반사진
  pano: null,                                     // 360 이미지
  title: '',                                      // 제목
  memo: '',                                       // 내용
  privateMemo: '',                                // 비공개 메모

  // 소유자 인증
  isConfirmRequest: false,                        // 확인매물 요청 여부
  ownerName: '',                                  // 소유자 이름
  ownerPhone: '',                                 // 소유자 연락처
  isAgreeConfirm: false,                          // 확인매물 제3자 동의 여부

  // 플러스 상품 추가
  isQuick: false,                                 // 플러스 상품 사용여부
  quickType: '',                                  // 플러스 상품 타입
  quickUseQuantity: 0,                            // 플러스 상품 사용 갯수 (1일권)

  // //확인매물 상품 추가
  hasProduct: {
    hasConfirmProduct: false,                     //확인매물 상품 소유 여부
    hasQuick1DayProduct: false,                   //플러스 상품 소유 여부(1Day)
    hasQuick30DayProduct: false,                  //플러스 상품 소유 여부(30Day)
    isConfirmTerms: false,                        //확인매물 약관 동의 여부

    confirm1TimeItemCount: 0,                     //확인매물 상품 갯수
    confirm1TimeUnUseCount: 0,                    //확인매물 상품 미사용 갯수
    confirm1TimeUseCount: 0,                      //확인매물 상품 사용 갯수

    quick1DayItemCount: 0,                        //플러스 상품 1일권 상품 갯수
    quick1DayUnUseCount: 0,                       //플러플러스 상품 1일권 상품 미사용 갯수
    quick1DayUseCount: 0,                         //플러스 상품 1일권 상품 사용 갯수

    quick30DayItemCount: 0,                       //플러스 상품 정기권 상품 갯수
    quick30DayUnUseCount: 0,                      //플러스 상품 정기권 상품 미사용 갯수
    quick30DayUseCount: 0,                        //플러스 상품 정기권 상품 사용 갯수
  },

  // 공실센터
  isOwner: false,                                 // 공실센터 매물일경우 true
  gongsilPreemptSeq: '',                          // 공실 번호

  // 기타 등록 정보
  requestMethod: '',                              // 중개 의뢰 방법
  requestMethodMessage: '',                       // 중개 의뢰 방법 직접 입력내용

  hasMsgQuestion: false,                          // 확인 매물 메신저(간편문의) 데이터 유/무
  deleted: false,                                 // 단지 삭제 여부
};

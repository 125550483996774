import { createModel } from '@rematch/core';

import state from './state';

const messenger = createModel({
  state: state,
  reducers: {
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },

    resetPublicRooms(state) {
      return {
        ...state,
        rooms: {
          isLoading: true,
          selectedRooms: [],
          total: 0,
          page: 1,
          limit: 24,
        },
      };
    },

    reset() {
      return state;
    }
  },

  effects: dispatch => ({
    updateObj({ name, value }, { messenger }) {
      this.update({
        [name]: {
          ...messenger[name],
          ...value,
        }
      });
    },

    updateCheckAll(checked, { messenger: { notYet } }) {
      const checkList = checked ? [] : [...notYet.checkList];

      this.update({
        notYet: {
          ...notYet,
          checkList,
          isAll: checked,
        }
      });
    },
  }),
});

export default messenger;

import { css } from 'styled-components';

import arrowImg from './images/arrow.svg';

export default css`
  padding: 0 10px;
  color: ${({ value, theme }) => value !== '' ? theme.grey43.getColor() : theme.grey8f.getColor()};
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.greyD7.getColor()};
  border-radius: 0;
  background: ${({ theme }) => theme.white.getColor()} url(${arrowImg}) right 10px center no-repeat;
  background-size: 18px;
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;

  &::-ms-expand {
    // IE10 까지만 화살표 제거됨...
    display: none;
  }

  &:disabled {
    background-color: rgb(249, 249, 249);
  }
`;

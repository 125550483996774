import styled, { css } from 'styled-components';

export const PaginWrap = styled.div<{ marginTop?: string, marginBottom?: string }>`
  flex: none;
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const PageList = styled.ul`
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  
  > li.pageNum + li.pageNum {
    margin-left: 4px;
  }
`;

const Btn = css`
  width: 28px;
  height: 28px;
  padding-top: 1px;
  border: 1px solid ${({ theme }) => theme.color.gray[400]};
  border-radius: 2px;

  &:disabled {
    cursor: default;
  }
`;

export const PrevBtn = styled.button`
  ${Btn};
  margin-right: 4px;
`;

export const NextBtn = styled.button`
  ${Btn};
  margin-left: 4px;
`;

export const NumBtn = styled.button<{ isActive: boolean }>`
  padding: 0 8px;
  height: 28px;
  font-size: 16px;
  border: 1px solid ${({ isActive, theme }) => isActive ? theme.color.gray[900] : 'transparent'};
  border-radius: 2px;
  color: ${({ isActive, theme }) => isActive ? theme.color.gray[900] : theme.color.gray[600]};

  &:hover {
    color:${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.gray[900]};
  }
  
  &:disabled {
    cursor: default;
  }
`;

import { useState, useEffect } from 'react';

function useDropdown() {
  const [isOpen, setOpen] =  useState(false);

  useEffect(() => {
    document.addEventListener('click', clickOutside, false);

    return () => {
      document.removeEventListener('click', clickOutside, false);
    };
  }, []);

  function clickOutside(e: MouseEvent) {
    const dropdownElement = document.querySelector('.dropdown');

    if (e.target && !dropdownElement?.contains(e.target as Node)) {
      setOpen(false);
    }
  }

  function handler() {
    setOpen(prev => !prev);
  }

  return {
    isOpen,
    handler,
  };
}

export default useDropdown;

export default [
  {
    type: 'POWERFUL_RECOMMENDED',
    name: '강추',
  },
  {
    type: 'QUICKLY_SELL',
    name: '급매',
  },
  {
    type: 'REMODELING',
    name: '리모델링',
  },
  {
    type: 'PETS_ALLOWED',
    name: '펫동반',
  },
  {
    type: 'NEW_CONSTRUCTIONS',
    name: '신축',
  },
  {
    type: 'STATION_AREA',
    name: '역세권',
  },
  {
    type: 'POPULAR_ROOM',
    name: '인기',
  },
  {
    type: 'QUICK_MOVE_IN',
    name: '빠른 입주',
  },
  {
    type: 'ELEMENTARY_SCHOOL_AREA',
    name: '초품아',
  },
  {
    type: 'FULL_OPTION',
    name: '풀옵션',
  },
] as IconFocus.IconFocusItem[];

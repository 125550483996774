import React from 'react';

import { TSvgIconName } from '~/@types/svg-icon';

import SvgIcon from '~/components/svg-icon';

import { MenuButton } from './styled';

interface Props {
  text: string;
  isDisappear?: boolean;
  iconName?: TSvgIconName | null;
  onClick?: () => void;
}

function MenuButtonComponent({
  text,
  isDisappear = false,
  iconName = null,
  onClick,
}: Props) {
  if (isDisappear) return null;

  return (
    <MenuButton onClick={onClick}>
      {text}
      {iconName && <SvgIcon name={iconName} />}
    </MenuButton>
  );
}

export default MenuButtonComponent;

import { useEffect, useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import useApiCall from '~/hooks/useApiCall';

import { useToast } from '~/components/toast';

export const REASON_TYPE = {
  'PRICE': '가격',
  'CMPL': '거래완료',
  'AUCT': '경매매물',
  'ETC': '기타',
  'IMPO': '매도자 사칭',
  '-': '-',
};

export const BUILDING_USE_TYPE = {
  'DETACHED_HOUSE': '단독주택',
  'APARTMENT_HOUSE': '공동주택',
  'FIRST_NEARBY_ROAD_FACILITIES': '제1종근린생활시설',
  'SECOND_NEARBY_ROAD_FACILITIES': '제2종근린생활시설',
  'BUSINESS_FACILITIES': '업무시설',
  'ACCOMMODATION': '숙박시설',
  '-': '-',
};

interface State {
  /** 매물주소 */
  address: string;
  /**
   * 건축물 주용도
   * DETACHED_HOUSE: 단독주택
   * APARTMENT_HOUSE: 공동주택
   * FIRST_NEARBY_ROAD_FACILITIES: 제1종근린생활시설
   * SECOND_NEARBY_ROAD_FACILITIES: 제2종근린생활시설
   * BUSINESS_FACILITIES: 업무시설
   * ACCOMMODATION: 숙박시설
   * INPUT: 직접입력
   * */
  buildingUseType: 'DETACHED_HOUSE' | 'APARTMENT_HOUSE' | 'FIRST_NEARBY_ROAD_FACILITIES' | 'SECOND_NEARBY_ROAD_FACILITIES' | 'BUSINESS_FACILITIES' | 'ACCOMMODATION' | 'INPUT' | null;
  /** 건축물 주용도가 직접입력(INPUT)인 경우 사용 */
  buildingUseStr?: string;
  /** 보증금 */
  deposit: string;
  /** 월세 */
  price: string;
  /** 거래유형 */
  leaseType: 'MONTHLY_RENT' | 'LEASE' | 'SALE' | null;
  /** 신고항목 목록
   * PRICE: 가격
   * CMPL: 거래완료
   * AUCT: 경매매물
   * ETC: 기타
   * IMPO: 매도자 사칭
   */
  reasonType: 'PRICE' | 'CMPL' | 'AUCT' | 'ETC' | 'IMPO' | null;
  /** 사유 */
  reasonDescription: string;
  /** 신고번호 */
  reportSeq: number | null;
  /** 신고ID */
  reportId: string | null;
  /** 매물번호 */
  roomsSeq: number | null;
  /** 네이버 매물번호 */
  naverRoomNum?: number | null;
  /** 신고 유입 유형 */
  inflowType: 'DABANG' | 'NAVER' | null;
  /** 신고시간 */
  savedTime: string;
  /** 연락처 */
  formatAgentContact: string;
}

const INIT = {
  address: '',
  buildingUseType: null,
  deposit: '',
  leaseType: null,
  price: '',
  reasonType: null,
  reasonDescription: '',
  reportSeq: null,
  reportId: null,
  roomsSeq: null,
  inflowType: null,
  savedTime: '',
  formatAgentContact: ''
};

function useReportProcessing({
  inflowType,
  callback,
  handler,
}: {
  inflowType: 'DABANG' | 'NAVER';
  callback: () => void;
  handler?: () => void;
}) {
  const [isLoading, setLoading] = useState(false);
  const [isSending, setSending] = useState(false);
  const [state, setState] = useState<State>(INIT);
  const [answer, setAnswer] = useState<string>('');

  const dispatch = useDispatch();

  const { get, post } = useApiCall();

  const toast = useToast();

  useEffect(() => {
    getReportDetail();
  }, []);

  async function getReportDetail() {
    setLoading(true);

    try {
      const res = await get('/api/v2/kiso-report/agent-treat/detail', { inflowType });
      setState(res);

    } catch(err) {
      console.log(err);
      toast.error(err?.msg ?? '서버에러가 발생했습니다.');
    }

    setLoading(false);
  }

  function changeAnswer(e: ChangeEvent<HTMLSelectElement>) {
    const { value } = e.currentTarget;

    setAnswer(value);
  }

  async function submit() {
    if (isSending) return;
    if (!answer) return toast.error('허위매물 여부를 선택해주세요.');
    if (!window.confirm(`${answer === 'NOMAL' ? '정상매물을' :  answer === 'FALSE' ? '광고종료 (허위매물)를' : '거래완료를'} 선택 하셨습니다.
전송 하시겠습니까?`)) return;

    setSending(true);

    try {
      const res = await post(`/api/v2/new-room/fake-report/${state.reportId}/agent-treat`, { answer });

      if (res?.kisoNetworkStatus === 'OK') {
        /* @ts-ignore */
        dispatch.user.update(res);
        toast.success(answer === 'NOMAL' ? '정상매물로 전송하셨습니다.' : answer === 'FALSE' ? '허위매물로 전송하셨습니다.' : '거래완료로 전송하셨습니다.');

        if ((inflowType === 'DABANG' && res?.hasReport) || (inflowType === 'NAVER' && res?.hasNaverReport)) {
          getReportDetail();
          setAnswer('');

        } else {
          handler && handler();
          callback && callback();
        }

      } else {
        if (res?.kisoNetworkStatus === 'SERVER_ERROR') toast.error('키소 서버 통신 오류 입니다.');
        if (res?.kisoNetworkStatus === 'CLIENT_ERROR') toast.error('키소 서버 클라이언트 요청 오류 입니다.');
      }

    } catch(err) {
      console.log(err);
      toast.error(err?.msg ?? '서버에러가 발생했습니다.');
    }

    setSending(false);
  }

  return {
    isLoading,
    isSending,
    state,
    answer,
    getReportDetail,
    changeAnswer,
    submit,
  };
}

export default useReportProcessing;

import styled, { css, keyframes } from 'styled-components';
import hex2rgba from '~/utils/hex2rgba';
import { toastPosition, toastStates, colors, padding, BOTTOM_CENTER } from './common';

const borderRadius = 4;

export const ToastHub = styled.div`
  max-height: 100%;
  padding: ${padding}px;
  overflow: hidden;
  position: fixed;
  box-sizing: border-box;
  pointer-events: ${({ hasToasts }) => hasToasts ? null : 'none'};
  z-index: 9000;

  ${({ position }) => toastPosition(position)};
  
  ${({ position }) => position === BOTTOM_CENTER && css`
    width: 100%;
  `};
`;

export const ToastElement = styled.div`
  height: ${({ height }) => height}px;
  transition: height 120ms 100ms;

  > div {
    display: flex;
    width: 400px;
    margin-bottom: ${padding}px;
    border-radius: ${borderRadius}px;
    overflow: hidden;
    background-color: #fff;
    transition: transform 220ms cubic-bezier(0.2, 0, 0, 1), opacity 220ms;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.175);
    ${({ position, transitionState }) => toastStates(position)[transitionState]};
  }

  ${({ position }) => position === BOTTOM_CENTER && css`
    width: 100%;

    > div {
      width: 88%;
      margin-left: auto;
      margin-right: auto;
    }
  `};
`;

export const IconWrap = styled.div`
  flex: none;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 12px ${({ position }) => position === BOTTOM_CENTER ? 5 : padding}px;
  text-align: center;
  border-top-left-radius: ${borderRadius}px;
  border-bottom-left-radius: ${borderRadius}px;
  background-color: ${({ type }) => hex2rgba(colors[type], 0.9)};
  position: relative;
  overflow: hidden;

  > svg {
    z-index: 2;
  }
`;

const shrinkKeyframes = keyframes`
  from { height: 100%; }
  to { height: 0%; }
`;

export const CountDown = styled.div`
  width: 100%;
  background-color: rgba(0,0,0,0.15);
  opacity: ${({ opacity }) => opacity};
  animation: ${shrinkKeyframes} ${({ timeout }) => timeout}ms linear;
  animation-play-state: ${({ isRunning }) => isRunning ? 'running' : 'paused'};
  position: absolute;
  bottom: 0;
  height: 0;
  left: 0;
  z-index: 1;
`;

export const Content = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  min-height: 40px;
  padding: ${({ position }) => position === BOTTOM_CENTER ? 12 : padding}px ${padding * 1.5}px;
  color: ${({ type }) => colors[type]};
  font-size: 15px;
  line-height: 24px;
  white-space: pre-wrap;
  word-break: break-word;
  background-color: ${({ type }) => hex2rgba(colors[type], 0.15)};

  & + button {
    background-color: ${({ type }) => hex2rgba(colors[type], 0.15)};
  }
`;

export const CloseBtn = styled.button`
  flex: none;
  width: 38px;
  padding: 0;
  border: 0;
  background-color: transparent;
  transition: opacity 150ms;
  position: relative;

  > svg {
    opacity: 0.45;
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
  }

  &:hover {
    > svg {
      opacity: 0.9;
    }
  }
`;
import { AgentInfo } from '~/@types/agent-info';

export function makeUserInfoResponse(res: AgentInfo.ResponseAgentInfo) {
  return {
    ...res.agent,
    ...res.user,
    ...res.item,
    ...res.sign,

    chat: {
      responseLevel: res?.chat?.responseLevel ?? '',
    },

    // null 처리
    isSubscribe: res.user.isSubscribe ?? false,

    isSafeAuth: res.user.isSafeAuth,

    isCeoSafeAuth: res.agent.isSafeAuth,
    agentGreetings: res.agent.agentGreetings ?? '',

    useConfirmCount: res.confirm.useCount,

    useOwnerCount: res.owner.useCount,
    maxOwnerCount: res.owner.maxCount,
    usePickCount: res.owner.usePickCount,
    isOwnerTerms: res.owner.isOwnerTerms,
    addressSigungu: res.owner.addressSigungu,

    receptionist: res.receptionist,

    isAuth: true,
    isLoading: false,
    isMaster: res.agent.agentEmail === res.user.userEmail,
  };
}

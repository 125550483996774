import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Cookie from 'js-cookie';

import ga from '~/utils/ga';
import isEmpty from '~/utils/valid/isEmpty';
import useApiCall from '~/hooks/useApiCall';
import { useToast } from '~/components/toast';

function useImageNotice(resetType) {
  const { authKey } = useSelector(({ user }) => user);
  const [list, setList] = useState([]);
  const [show, setShow] = useState({
    id: 1,
    title: '',
    content: '',
    expires: 1,
    url: '',
  });
  const { get } = useApiCall();
  const toast = useToast();
  const isShow = useMemo(() => list.length > 0, [list]);

  useEffect(() => {
    if (authKey) getNotice();
  }, [authKey]);

  async function getNotice() {
    try {
      const res = await get('/api/v2/events/active', { platform: 'pro_web_enter' });

      const newRes = res.filter(item => !Cookie.get(`imageNotice_${item.seq}`));

      newRes.map(item => {
        if (item.isLoginRequired && item.url) {
          item.url += `?auth_key=${authKey}`;
        }
        return item;
      });
      
      if (newRes.length > 0) {
        setList(newRes ?? []);
        setShow(newRes[0]);
      } else {
        resetType({ isCloseImageNotice: true });
      }
      
    } catch(err) {
      const msg = err?.msg ?? '서버에러가 발생하였습니다.';
      toast.error(msg);
    }
  }

  function clickImage(e) {
    if (!show.url) {
      e.preventDefault();
      return;
    }

    ga('배너', '이미지팝업', null, { banner_title: show.title });

    e.currentTarget.click();

    clickClose();
  }

  function clickClose() {
    const remainList = list.filter(i => i.seq !== show.seq);
    setList(remainList);

    if (remainList.length > 0) {
      setShow(remainList[0]); 
    } else {
      resetType({ isCloseImageNotice: true });
    }
  }

  function clickCloseWithExpire() {
    const expires = isEmpty(show?.expires) ? 1 : show.expires;
    Cookie.set(`imageNotice_${show.seq}`, show.seq, { expires });
    clickClose();
  }

  return { isShow, show, clickClose, clickCloseWithExpire, clickImage };
}

export default useImageNotice;
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import layout from '~/components/styled/layout';

export const Footer = styled.footer`
  flex: none;
  display: flex;
  flex-direction: column;
  height: 298px;
  margin-top: auto;
  background-color: #000;
`;

export const Wrap = styled.div`
  ${layout};
  flex: none;
  height: 80px;
  border-bottom: 1px solid #292929;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const LinkWrap = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-top: 5px;

  > a,
  > p {
    height: 14px;
    color: ${({ theme }) => theme.grey8f.getColor()};
    padding: 0 20px;
    line-height: 14px;

    &:first-child {
      padding-left: 0;
    }

    & + a,
    & + p {
      border-left: 1px solid rgba(216, 216, 216, 0.33);
    }
  }
`;

export const CompanyLink = styled.a.attrs(({ to }) => ({
  href: `http://www.station3.co.kr${to ?? ''}`,
  target: '_blank',
  rel: 'noopener noreferrer',
}))`

`;

export const TermLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  ${({ isHighlight }) => isHighlight && css`
    color: ${({ theme }) => theme.white.getColor()} !important;
    font-weight: 700;
  `}
`;

export const TopBtn = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
  padding-bottom: 10px;
  border: 0;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  background-color: transparent;

  > p {
    margin-top: 9px;
    color: ${({ theme }) => theme.grey8f.getColor()};
    font-size: 16px;
    line-height: 14px;
  }
`;

export const Info = styled.div`
  ${layout};
  
  padding: 35px 0;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const WorkInfo = styled.div`
  flex: none;
  width: 227px;

  > p,
  > h1 {
    color: ${({ theme }) => theme.white.getColor()};
  }

  > p {
    font-size: 14px;
    line-height: 14px;
  }

  > h1 {
    height: 26px;
    margin-top: 12px;
    font-size: 30px;
    line-height: 26px;
  }
`;

export const WorkTime = styled.div`
  margin-top: 15px;

  > p {
    color: ${({ theme }) => theme.grey8f.getColor()};
    font-size: 14px;
    line-height: 22px;
  }
`;

export const AddressInfo = styled.div`
  flex-grow: 1;
  margin-top: 21px;
`;

export const Address = styled.p`
  color: ${({ theme }) => theme.grey8f.getColor()};
  font-size: 14px;
  line-height: 14px;

  > span + span {
    margin-left: 15px;
  }

  & + & {
    margin-top: 15px;
  }
`;

export const CopyRight = styled.p`
  margin-top: 43px;
  color: ${({ theme }) => theme.grey8f.getColor()};
  font-size: 14px;
  line-height: 22px;
`;

export const Social = styled.div`
  flex: none;
  margin-top: auto;
`;

export const SnsInfo = styled.div`
  flex: none;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 102px;
  margin-left: auto;
`;

export const SnsLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  flex: none;
  width: 24px;
  height: 24px;
`;

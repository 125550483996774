import ReactGA from 'react-ga4';

/**
 * Google Anlytics
 */
function ga(event_category: string, action: string, event_label?: string, props: any = {}) {
  ReactGA.gtag('event', action, {
    event_category,
    event_label,
    ...props,
  });
}

export default ga;

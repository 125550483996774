import styled from 'styled-components';

export const MonthGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 28px);
  column-gap: 6px;
  row-gap: 4px;
  list-style: none;
  
  > li {
    width: 100%;
    height: 100%;
  }
`;

interface DateInfo {
  isActive: boolean;
}

export const MonthBtn = styled.button<DateInfo>`
  width: 100%;
  height: 100%;
  padding: 0;
  color: ${({ isActive, theme }) => isActive ? theme.color.white : theme.color.gray[900]};
  ${({ isActive, theme }) => theme.font.body3[isActive ? 'bold' : 'regular']};
  border: 1px solid ${({ isActive, theme }) => isActive ? theme.color.blue[500] : 'transparent'};
  border-radius: 2px;
  background-color: ${({ isActive, theme }) => isActive ? theme.color.blue[500] : 'transparent'};
  cursor: pointer;

  @media (hover: hover) and (pointer: fine) {
    &:not(:disabled):hover {
      border-color: ${({ isActive, theme }) => isActive ? theme.color.blue[500] : theme.color.gray[400]};
    }
  }
  
  &:disabled {
    color: ${({ theme }) => theme.color.gray[400]};
    ${({ theme }) => theme.font.body3.regular};
    border: 1px solid transparent;
    background-color: transparent;
    cursor: default;
  }
`;
/**
 * text가 빈칸("") 또는 null 인지 여부
 * @param {any} text 검증할 값
 */
function isEmpty(text) {
  if (text === '' || text === null || text === undefined) return true;
  if (JSON.stringify(text) === '{}' || JSON.stringify(text) === '[]') return true;

  return false;
}

export default isEmpty;

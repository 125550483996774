import { css } from 'styled-components';

const rootStyle = css`
  :root,
  *,
  body {
    font-family: "Pretendard Variable", -apple-system, system-ui, sans-serif;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
  }
  
  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  textarea {
    border-radius: 0;
    appearance: none;
    outline: none;
    user-select: text;
    
    &::-webkit-input-placeholder {
      color: ${({ theme }) => theme.color.gray[600]};
    }
    &:-ms-input-placeholder {
      color: ${({ theme }) => theme.color.gray[600]};
    }
    &::placeholder {
      color: ${({ theme }) => theme.color.gray[600]};
    }
    
    &:disabled {
      &::-webkit-input-placeholder {
        color: ${({ theme }) => theme.color.gray[500]};
      }
      &:-ms-input-placeholder {
        color: ${({ theme }) => theme.color.gray[500]};
      }
      &::placeholder {
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }
  }

  input {
    border-radius: 0;
    appearance: none;
    outline: none;
    user-select: text;

    &[type="number"]::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
    
    &[type="number"]::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    &:focus {
      outline: none;
    }
    &:active {
      outline: none;
    }

    &::-ms-clear{
      display: none;
    }
    
    &::-webkit-input-placeholder {
      color: ${({ theme }) => theme.color.gray[600]};
    }
    &:-ms-input-placeholder {
      color: ${({ theme }) => theme.color.gray[600]};
    }
    &::placeholder {
      color: ${({ theme }) => theme.color.gray[600]};
    }

    &:disabled {
      &::-webkit-input-placeholder {
        color: ${({ theme }) => theme.color.gray[500]};
      }
      &:-ms-input-placeholder {
        color: ${({ theme }) => theme.color.gray[500]};
      }
      &::placeholder {
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }
  }

  button {
    margin: 0;
    padding: 0;
    outline: none;
    border-radius: 0;
    background-color: #fff;
    cursor: pointer;

    outline: none;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;

    > span {
      position: relative;
    }

    &:focus {
      outline: none;
    }
    &:active {
      outline: none;
    }
  }

  select {
    outline: none;
  }

  h1, h2, h3, h4, h5, h6, strong, b {
    font-weight: 700;
  }
`;

export default rootStyle;

import styled, { css } from 'styled-components';

import type { TFontName, TFontWeight } from '../types';

import off from './images/ic_24_radio_enabled.svg';
import on from './images/ic_24_radio_selected.svg';
import disabled from './images/ic_24_radio_disabled.svg';
import onWithDisabled from './images/ic_24_radio_selected_disabled.svg';

export const Label = styled.label<{
  paddingTop: string;
  paddingBottom: string;
}>`
  flex: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
`;

export const IconFocusLabel = styled.label<{ $isChecked: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;

  ${({ $isChecked }) => $isChecked ? css`
    background-color: ${({ theme }) => theme.color.blue[50]};
    border: 1px solid ${({ theme }) => theme.color.blue[500]};
  ` : css`
    background-color: ${({ theme }) => theme.color.gray[200]};
    border: 1px solid ${({ theme }) => theme.color.gray[200]};
  `}

  > svg {
    margin-right: 4px;
  }

  > p {
    ${({ theme }) => theme.font.body2.bold};
    color: ${({ theme, $isChecked }) => $isChecked ? theme.color.blue[500] : theme.color.gray[900]};
  }

  > input {
    margin-left: auto;
  }
`;

export const Radio = styled.input`
  flex: none;
  width: 24px;
  height: 24px;
  margin: 0;
  border: 0;
  border-radius: 50%;
  background: url(${off}) center center no-repeat;
  background-size: cover;
  appearance: none;
  cursor: pointer;
  
  &:checked {
    background-image: url(${on});
  }

  &:disabled {
    background-image: url(${disabled});
    cursor: default;
  }

  &:checked:disabled {
    background-image: url(${onWithDisabled});
  }
`;

type TFontInfo = [TFontName, TFontWeight];

export const TextContent = styled.p<{
  textPosition: 'left' | 'right';
  fontInfo?: TFontInfo;
  isDisabled?: boolean;
}>`
  ${({ textPosition }) => textPosition === 'left' ? 'padding-right: 4px' : 'padding-left: 4px'};
  color: ${({ isDisabled, theme }) => isDisabled ? theme.color.gray[500] : theme.color.gray[900]};
  ${({ fontInfo, theme }) => fontInfo ? theme.font[fontInfo[0]][fontInfo[1]] : theme.font.body3.regular};
  user-select: none;
  cursor: ${({ isDisabled }) => isDisabled ? 'default' : 'pointer'};
`;

import { useSelector } from 'react-redux';

import type { AgentInfo } from '~/@types/agent-info';

/**
 * 다방 매물 관리, 네이버 매물 관리, 문의 내역의 유저 필터에서 공통으로 사용되는 hook
 * 공통으로 사용하는 util 또는 customHook 등 에서 사용하면 안된다. (api를 사용하는데 redux가 연결이 되어있지 않거나 store에 user 가 없는 케이스 존재)
 * 웹에서만 사용해야합니다.
 */
function useUserSession() {
  const { userEmail = '', userIdx, isMaster, agentContacts } = useSelector(({ user }: any) => user);

  /**
   * 세션 스토리지에 저장 된 사용자 계정(email) 반환
   * 대표 계정인 경우 세션 스토리지에 키값(selected-user)이 없을 때 전체를 의미하는 빈 공백을 반환하고 소속공인중개사인 경우 본인 계정을 반환
   *
   * @returns {string} 사용자 필터 계정(email) 반환
   */
  function getUser() {
    // 대표 계정인 경우 세션 스토리지에서 값을 가져오고 소속공인중개사인 경우 본인 계정을 저장
    const selectedUser = isMaster ? sessionStorage.getItem(`selected-user-${userIdx}`) ?? '' : userEmail;
    // 중개사 연락처 리스트에 세션 스토리지의 저장된 사용자 계정이 포함되어 있는지 확인
    const isInclude = agentContacts.some((u: AgentInfo.AgentContact) => u.id === selectedUser);

    return isMaster && !isInclude ? '' : selectedUser;
  }

  /**
   * 사용자 필터 선택 후 세션 스토리지에 저장
   *
   * @param {string} user 계정(email)
   */
  function updateUser(user: string = '') {
    // 대표 계정이 아닌경우 사용자 필터를 사용할 수 없음
    if (!isMaster) return;

    sessionStorage.setItem(`selected-user-${userIdx}`, user);
  }

  /**
   * 회원탈퇴 및 로그아웃시 세션 스토리지의 selected-user-{userIdx}를 제거한다.
   */
  function deleteUser() {
    sessionStorage.removeItem(`selected-user-${userIdx}`);
  }

  return {
    getUser,
    updateUser,
    deleteUser,
  };
}

export default useUserSession;

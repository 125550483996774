import color from './color';
import { css } from 'styled-components';

const themeColor = {
  gray: {
    50: '#FCFCFC',
    100: '#FAFAFA',
    200: '#F5F5F5',
    300: '#EDEDED',
    400: '#DFDFDF',
    500: '#CCCCCC',
    600: '#979797',
    700: '#656565',
    800: '#434343',
    900: '#222222',
  },
  yellow: {
    50: '#FCF5E3',
    100: '#FCEDC9',
    200: '#FCE7B0',
    300: '#FDE097',
    400: '#FDD164',
    500: '#FFB600',
    600: '#D79900',
    700: '#B37F00',
    800: '#7F5A00',
    900: '#4C3600',
  },
  blue: {
    50: '#EEF8FF',
    100: '#BAE0FF',
    200: '#89CEFF',
    300: '#00A9FF',
    400: '#008AFF',
    500: '#326CF9',
    600: '#1E41D0',
    700: '#152D92',
    800: '#0E2067',
    900: '#0C1A53',
  },
  red: {
    50: '#FFF4F4',
    100: '#FDD8DB',
    200: '#FA9DA4',
    300: '#F87680',
    400: '#F63C4A',
    500: '#E20724',
    600: '#A9051A',
    700: '#A10000',
    800: '#810000',
    900: '#600000',
  },
  green: {
    50: '#F3FAF8',
    100: '#DDF2EC',
    200: '#BBE5DA',
    300: '#76CAB5',
    400: '#5FC1A9',
    500: '#1CA885',
    600: '#16866A',
    700: '#10644F',
    800: '#0B4335',
    900: '#05211A',
  },
  violet: {
    50: '#F5F3FE',
    100: '#E2DEFE',
    200: '#BBB3FE',
    300: '#8B7CFD',
    400: '#6451FD',
    500: '#3E26FD',
    600: '#3817CA',
    700: '#2A1197',
    800: '#1C0B65',
    900: '#14084B',
  },
  pink: {
    50: '#FFF2F6',
    100: '#FFC7D7',
    200: '#FF3478',
    300: '#FF85AC',
    400: '#FF5A8E',
    500: '#FF3478',
    600: '#FE005D',
    700: '#E90059',
    800: '#D70057',
    900: '#C20055',
  },
  white: '#ffffff',
};

const font = {
  h1: {
    regular: css`
      font-size: 46px;
      line-height: 70px;
      letter-spacing: -1px;
      font-weight: 400;
    `,
    medium: css`
     font-size: 46px;
     line-height: 70px;
     letter-spacing: -1px;
     font-weight: 500;
    `,
    bold: css`
      font-size: 46px;
      line-height: 70px;
      letter-spacing: -1px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `
  },
  h2: {
    regular: css`
      font-size: 38px;
      line-height: 60px;
      letter-spacing: -1px;
      font-weight: 400;
    `,
    medium: css`
      font-size: 38px;
      line-height: 60px;
      letter-spacing: -1px;
      font-weight: 500;
    `,
    bold: css`
      font-size: 38px;
      line-height: 60px;
      letter-spacing: -1px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `
  },
  h3: {
    regular: css`
      font-size: 30px;
      line-height: 48px;
      letter-spacing: -0.5px;
      font-weight: 400;
    `,
    medium: css`
      font-size: 30px;
      line-height: 48px;
      letter-spacing: -0.5px;
      font-weight: 500;
    `,
    bold: css`
      font-size: 30px;
      line-height: 48px;
      letter-spacing: -0.5px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `
  },
  h4: {
    regular: css`
      font-size: 24px;
      line-height: 38px;
      letter-spacing: -0.5px;
      font-weight: 400;
    `,
    medium: css`
      font-size: 24px;
      line-height: 38px;
      letter-spacing: -0.5px;
      font-weight: 500;
    `,
    bold: css`
      font-size: 24px;
      line-height: 38px;
      letter-spacing: -0.5px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `
  },
  subtitle1: {
    regular: css`
      font-size: 22px;
      line-height: 38px;
      letter-spacing: -0.5px;
      font-weight: 400;
    `,
    medium: css`
      font-size: 22px;
      line-height: 38px;
      letter-spacing: -0.5px;
      font-weight: 500;
    `,
    bold: css`
      font-size: 22px;
      line-height: 38px;
      letter-spacing: -0.5px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `
  },
  subtitle2: {
    regular: css`
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.5px;
      font-weight: 400;
    `,
    medium: css`
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.5px;
      font-weight: 500;
    `,
    bold: css`
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.5px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `
  },
  body1: {
    regular: css`
      font-size: 18px;
      line-height: 30px;
      font-weight: 400;
    `,
    medium: css`
      font-size: 18px;
      line-height: 30px;
      font-weight: 500;
    `,
    bold: css`
      font-size: 18px;
      line-height: 30px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `
  },
  body2: {
    regular: css`
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
    `,
    medium: css`
      font-size: 16px;
      line-height: 26px;
      font-weight: 500;
    `,
    bold: css`
      font-size: 16px;
      line-height: 26px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `
  },
  body3: {
    regular: css`
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
    `,
    medium: css`
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
    `,
    bold: css`
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `
  },
  caption1: {
    regular: css`
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
    `,
    medium: css`
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
    `,
    bold: css`
      font-size: 12px;
      line-height: 20px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `
  },

  caption2:{
    regular: css`
      font-size: 10px;
      line-height: 16px;
      font-weight: 400;
    `,
    medium: css`
      font-size: 10px;
      line-height: 16px;
      font-weight: 500;
    `,
    bold: css`
      font-size: 10px;
      line-height: 16px;
      font-weight: 700;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `
  }
};

export default {
  color: themeColor,
  font,

  proMainColor: new color().setRGB(83, 89, 254),            //#5359fe

  pointYellow: new color().setRGB(255, 235, 0),             //#ffeb00

  scarlet: new color().setRGB(208, 2, 27),                  //#d0021b
  noticeRed: new color().setRGB(242, 22, 58),               //#f2163a

  proPink4: new color().setRGB(255, 86, 250),               //#ff56fa

  graphBarLavender: new color().setRGB(135, 139, 255),      //#878bff
  graphBarPink: new color().setRGB(225, 149, 252),          //#e195fc
  graphLavender: new color().setRGB(135, 139, 255),         //#878bff
  graphSkyBlue: new color().setRGB(120, 183, 255),          //#78b7ff

  productListSky: new color().setRGB(152, 180, 255),        //#98b4ff

  black: new color().setRGB(0, 0, 0),                       //#000000
  grey22: new color().setRGB(34, 34, 34),                   //#222222
  grey43: new color().setRGB(67, 67, 67),                   //#434343
  grey8f: new color().setRGB(143, 143, 143),                //#8f8f8f
  greyD7: new color().setRGB(215, 215, 215),                //#d7d7d7
  greyD8: new color().setRGB(216, 216, 216),                //#d8d8d8
  greyF2: new color().setRGB(242, 242, 242),                //#f2f2f2
  white: new color().setRGB(255, 255, 255),                 //#ffffff

  veryLightGreyLine: new color().setRGB(236, 236, 236),     //#ececec
  paleGreyOutline: new color().setRGB(221, 221, 221),       //#dddddd

  paleGreyBg: new color().setRGB(243, 246, 251),            //#f3f6fb
  lightGreyBg: new color().setRGB(246, 246, 246),           //#f6f6f6
};
